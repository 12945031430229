import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as appReducer } from './reducer';
import { createLogger } from 'redux-logger';

import thunk from 'redux-thunk';
// import rootReducer from './reducers/rootReducer';

const reducers = combineReducers({
    app: appReducer
});

export type IAppState = ReturnType<typeof reducers>;

// export const store = createStore(reducers);

export const store = createStore(reducers, composeWithDevTools(applyMiddleware(thunk, createLogger())));