
import { BaseService } from "./base.service";
import { EnvironmentContextService } from "../environment-context.service";

import { LoginMessage } from "../../models/messages/security/login.message";
import { RequestOtpResponseMessage } from "../../models/messages/security/request-opt-response.message";
import { TokenResponseMessage } from "../../models/messages/security/token-response.message";
import { ValidateOtpMessage } from "../../models/messages/security/validate-otp.message";

class SecurityService extends BaseService {

    constructor(contextServie: EnvironmentContextService) {
        super(contextServie);
        this.baseApiUrl += '/security';
    }

    public async authenticate(message: LoginMessage): Promise<RequestOtpResponseMessage> {
        return this.handleResponse(() => this.axios.post(`${this.baseApiUrl}/authenticate`, message));
    }

    public async signIn(message: ValidateOtpMessage): Promise<TokenResponseMessage> {
        return this.handleResponse(()=> this.axios.post(`${this.baseApiUrl}/token`, message));
    }

    public signOut(): void {
        this.contextService.removeAccesToken();
    }
}

export { SecurityService };