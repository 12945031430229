import React, { useState, useEffect, useContext } from "react";

import { SfdcApi } from "./sfdcApi";
import getCookie from "../shared/utilities/get-cookie";
import setCookie from "../shared/utilities/set-cookie";
import { useDispatch } from 'react-redux';
import { SetUrlData } from '../actions';
import { EnvironmentContextService } from "../services/environment-context.service";


const oauthConfig = {
  loginUrl: process.env.REACT_APP_SFDC_LOGIN_URL,
  clientId: process.env.REACT_APP_SFDC_CLIENT_ID,
  redirectUri: process.env.REACT_APP_SFDC_REDIRECT_URI,
};

export interface IKOL_Profile {
  Id?: string;
  Logo__c?: string;
  info__c?: string;
  Company_Color__c?: string;
  View__c?: string;
  Header_Background_Image__c?: string;
  Client_Name__c?: string;
}
interface SfdcHook {
  kolProfile?: IKOL_Profile;
  user?: any;
  api?: SfdcApi;
  connGlobal?: any;
  loginStatus?: any;
  permissionId?: number;
  setIsLoading?: (loading: boolean) => void;
  firstTimeLogin?: boolean;
  setFirstTimeLogin?: any;
  UserOrGroupId?: any;
  division?: any;
  FFS__c?: any;
  globalData?: any;
  globalFocus?: any;
  globalFocus2?: any;
  username?: any;
  newdivision?: any;
  companycheck?: any;
}

export const sfdcContext = React.createContext<SfdcHook>({});

export function ProvideSfdc({ children }) {
  const sfdc = useProvideSfdc();
  return <sfdcContext.Provider value={sfdc}>{children}</sfdcContext.Provider>;
}

export function useSfdc() {
  return useContext(sfdcContext);
}


function useProvideSfdc() {
  const [kolProfile, setKolProfile] = useState<IKOL_Profile>(
    {} as IKOL_Profile
  );
  const [user, setUser] = useState<any>(null);
  const [api, setApi] = useState<any>(null);
  const [connGlobal, setConnGlobal] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [permissionId, setPermissionId] = useState<number>(3);

  const [newdivision, setNewdivision] = useState<string>("");
  const [username, setUsername] = useState<string>("");

  const [division, setDivision] = React.useState<any>([]);
  const [globalData, setglobalData] = React.useState<any>([]);
  const [globalFocus, setglobalFocus] = React.useState<any>([]);
  const [globalFocus2, setglobalFocus2] = React.useState<any>([]);
  const [FFS__c, setFFS__c] = React.useState<any>(true);

  const [companycheck, setCompanycheck] = useState<string>("");

  const [firstTimeLogin, setFirstTimeLogin] = useState<boolean>(false);
  const [UserOrGroupId, setUserOrGroupId] = useState<any>(null);

  const loginStatus = async (isLogIn: boolean) => {
    if (isLogIn) {
      setCookie("jsForceToken", "access_token");
    }
    else {
      setCookie("jsForceToken", "");
    }

    window.location.reload();


    // if (isLogIn) {
    //   setIsLoading(true);
    //   jsforce.browser.login(oauthConfig);
    // } else {
    //   setIsLoading(false);
    //   const { SFDC_INSTANCE_URL } = (window as any) || {};

    //   try {
    //     await jsforce.browser.logout();
    //     setCookie("jsForceToken", "");
    //   } catch (err) { }

    //   if (SFDC_INSTANCE_URL) {
    //     window.location.href = `${SFDC_INSTANCE_URL}/secur/logout.jsp`;
    //     return;
    //   }

    //   window.location.reload();
    // }
  };

  useEffect(() => {
    const applyConnection = async function () {

      // const conn = await getConn();
      var cookie = getCookie("jsForceToken");
      if (cookie == "") return;

      const api = new SfdcApi();
      setApi(api);
      //Set logged in user
      const environmentService: EnvironmentContextService = new EnvironmentContextService();
      const userId = environmentService.getUserId();
      const user = await api.getUser(userId);
      console.log("user1--->", user);
      const user_id = user?.records[0].Id as string;
      console.log("user2--->", user_id);
      // setUserOrGroupId(user_id.slice(0, -3));
      // console.log('set group id-->',user_id.slice(0, -3));
      setUserOrGroupId(user_id);

      // jansseon 0052g000003Org2AAC
      // pcyc fullcopy 0058B000000sAqGQAU

      // jbitripleadmin  fullcopy  0053Z00000LOFbRQAX
      // jbillcommerical fullcopy  0053Z00000LOFbMQAX

      //jbitripleadmin development 0052g000003Org2AAC

      //triplemedical fullcopy 0053Z00000LOG86QAH

      //pfmedical 0053Z00000LOFbHQAX

      //llmedical 0053Z00000LOG7IQAX

      // ffs 00523000003uSybAAE

      //       NFFS  "00523000003uSyqAAE"

      // admin "00523000003uSyvAAE"
      // mmpadmin 0053Z00000LOG7cQAH

      console.log("User id", user);
      setUser(user);
      const kolProfile: any = await api.getKOLPorfile();
      console.log("kolProfile--->", kolProfile);
      setKolProfile(kolProfile);
      Promise.all([
        // api?.getGlobal(user_id),
        // api?.getGlobalName(user_id),
        // api?.getGlobalDivision(user_id),
        // api?.getGlobalFocus(user_id),
        // api?.getGlobalFocus2(user_id),
        api?.getGlobal(user_id),
        api?.getGlobalName(user_id),
        api?.getGlobalDivision(user_id),
        api?.getGlobalFocus(user_id),
        api?.getGlobalFocus2(user_id),
        //api?.getMasterId("Leukemia-Lymphoma"),
        //api?.getFocusId("a8J19000000U4yrEAC"),
        //api?.getTopicId("a8L19000000A8Y4EAK"),
        //api?.testingapi(),
      ]).then(
        (data: any) => {
          let role = 0;
          console.log("getGlobalgetGlobalgetGlobal--->", data[0], (data[0]?.records?.[0].FFS__c == "FFS Admin") ? "" : ((data[0]?.records?.[0].FFS__c == null) ? "" : ((data[0]?.records?.[0].FFS__c == "No FFS") ? false : true)));
          //console.log("getMasterId --->", data[4]);
          //console.log("getFocusId --->", data[5]);
          //console.log("getTopicId --->", data[6]);
          if (data[0]?.records) {
            if (data[0]?.records?.[0]?.Read_Only__c == true) {
              role = 3;
            } else {
              role = 1;
              // Reacd
            }

            console.log("Role", role);
            console.log("Division__c", data[0]?.records?.[0]?.Division__c);
            setPermissionId(role);
            setDivision(data[0]?.records?.[0]?.Division__c);
            console.log()
            setFFS__c((data[0]?.records?.[0].FFS__c == "FFS Admin") ? "" : ((data[0]?.records?.[0].FFS__c == null) ? "" : ((data[0]?.records?.[0].FFS__c == "No FFS") ? false : true)));

            // let records = [
            //   {
            //     Active__c: false,
            //     Brand__c: [
            //       { brand: "MM Portfolio", color: "custom_info" },
            //       //{ brand: "Leukemia-Lymphoma", color: "custom_green" },
            //       //{ brand: "Prostate Franchise", color: "custom_blue" },
            //       //{ brand: "Fourth", color: "custom_green" },
            //     ],
            //     Company__c: "JBI",
            //     Division__c: "Admin",
            //     Employee__c: "0052g000003Org2AAC",
            //     Employee__r: {
            //       CommunityNickname: "jbitripleadmin@blulava.com.fullcopy",
            //       Email: "jbitripleadmin@blulava.com.fullcopy",
            //       Name: "JBI Triple Admin",
            //       Username: "jbitripleadmin@blulava.com.fullcopy",
            //       attributes: {
            //         type: "User",
            //         url:
            //           "/services/data/v42.0/sobjects/User/0052g000003Org2AAC",
            //       },
            //     },
            //     KOL_Profile_Portal__c: "a343Z000003XdRsQAK",
            //     Leadership_Attendee__c: false,
            //     Read_Only__c: false,
            //     attributes: {
            //       type: "Employee__c",
            //       url:
            //         "/services/data/v42.0/sobjects/Employee__c/a5c3Z0000000LUrQAM",
            //     },
            //   },
            // ];

            setglobalData(data[0]?.records);
            setCompanycheck(data[0]?.records?.[0]?.Company__c);
          }
          if (data[1]) {
            setUsername(data[1]);
          }

          if (data[2]?.records) {
            console.log("getGlobalDivision", data[2]?.records?.[0].Division__c);
            setNewdivision(data[2]?.records?.[0].Division__c);
          }


          if (data[3]?.records) {
            console.log("getGlobalFocus", data[3]?.records);
            setglobalFocus(data[3]?.records);
          }

          if (data[4]?.records) {
            console.log("getGlobalFocus2", data[4]?.records);
            setglobalFocus2(data[4]?.records);
          }


        },
        (error) => { }
      );

      // let role = 0;
      // if (user?.['Client_Team__c']?.includes('Super User')) {
      //   role = 1;
      // } else if (user?.['Client_Team__c']?.includes('Regular User')) {
      //   role = 2;
      // } else if (user?.['Client_Team__c']?.includes('Read Only User')) {
      //   role = 3;
      // } else {
      //   role = 1;
      // }
      // setPermissionId(role);
    };

    applyConnection();

    return () => {

    };
  }, []);

  // Return the user object and auth methods
  return {
    kolProfile,
    user,
    api,
    loginStatus,
    setIsLoading,
    isLoading,
    permissionId,
    firstTimeLogin,
    setFirstTimeLogin,
    UserOrGroupId,
    division,
    FFS__c,
    newdivision,
    username,
    globalData,
    globalFocus,
    globalFocus2,
    companycheck,
    connGlobal
  };
}
