import React from "react";

const OAuth2Callback = () => {
  return (
    <>
      <h1>Logging in...</h1>
    </>
  );
};

export default OAuth2Callback;
