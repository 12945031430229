import React from 'react'
import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import { IAlertProps } from '../interfaces/alert.modal';

const useStyles = makeStyles((theme) => ({
    info: {
        alignItems: 'center',
        borderColor: theme.palette.primary.main,
        "& .MuiAlert-message": {
            color: theme.palette.primary.main,
        },
    },
    error: {
        alignItems: 'center',
        borderColor: theme.palette.error.main,
        "& .MuiSvgIcon-root": {
            fill: theme.palette.error.main,
        },
        "& .MuiAlert-message": {
            color: theme.palette.error.main,
        },
    },
    success: {
        alignItems: 'center',
        borderColor: theme.palette.success.main,
        "& .MuiSvgIcon-root": {
            fill: theme.palette.success.main,
        },
        "& .MuiAlert-message": {
            color: theme.palette.success.main,
        },
    },
    warning: {
        alignItems: 'center',
        borderColor: theme.palette.primary.main,
        "& .MuiAlert-message": {
            color: theme.palette.primary.main,
        },
    }
}));

export const CustomAlert = ({ apiStatus, severity, message }: IAlertProps) => {
    const classes = useStyles();

    const getAlert = (apiStatus) => {
        switch (apiStatus) {
            case 0: return <Alert severity="info" variant="outlined" className={classes.warning}>{'No Records Found!'}</Alert>
            case 1: return <Box textAlign="center" p={5}> <CircularProgress thickness={5} size={100} /> </Box>
            case 2: return;
            case 3: return <Alert severity="error" variant="outlined" className={classes.error}>{'Internal Error, Please Contact Support!!!'}</Alert>
            case 4: return <Alert severity="success" variant="outlined" className={classes.success}>{'Updated Successfully!!!'}</Alert>
            default: return <Alert severity={severity} variant="outlined" className={classes[severity ? severity : 'info']}>{message}</Alert>
        }
    }

    return (<Box display="flex" alignItems="center" justifyContent="center" px={1} py={3}> { getAlert(apiStatus)} </Box>)
}