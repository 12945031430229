import jsforce from "jsforce";

import {
  IAddProfile,
  INote,
  IFlatProfile,
} from "../components/interfaces/kol-profile.modal";
import {
  ITag,
  IEngagement,
  IMasterEngagement,
} from "../components/interfaces/engagment.modal";

import { BRAND1, BRAND2, BRAND3, BRANDdemo } from "../shared/utilities/filter-const";
import axios from "axios";
import moment from "moment";
import profile from "../shared/utilities/kol-const-profile";
import { EnvironmentContextService } from "../services/environment-context.service";

class SfdcApi {
  private KOL_Profile_Portal__pc: string;
  private RecordTypeId: string;
  private Account__c: string;
  private KOL_Profile_Id: string;
  private KOL_Portal__c: string;
  private KOL_Api_Url: string;
  private KOL_Api_Base_Url: string;
  private KOL_Portal_Name: string;
  private KOL_UserID: string;
  public constructor() {
    this.KOL_Profile_Portal__pc =
      process.env.REACT_APP_KOL_PROFILE_PORTAL_ID ?? ``;
    this.KOL_Profile_Id = process.env.REACT_APP_KOL_PROFILE_ID ?? ``;
    this.RecordTypeId = "012j0000000pbkF";
    //this.Account__c = "0018B00000AiBGF";
    this.Account__c = process.env.REACT_APP_ACCOUNT__c ?? ``;
    this.KOL_Portal__c = process.env.REACT_APP_KOL_PORTAL_ID ?? ``;
    this.KOL_Api_Base_Url = process.env.REACT_APP_KOL_API_URL ?? '';
    this.KOL_Api_Url = `${this.KOL_Api_Base_Url}/kol` ?? '';
    this.KOL_Portal_Name = process.env.REACT_APP_KOL_PORTAL_NAME ?? '';
    const environmentService: EnvironmentContextService = new EnvironmentContextService();
    const userId = environmentService.getUserId();
    this.KOL_UserID = userId ?? '';
  }

  public async getUser(userId: string) {

    try {
      //let userId = 'cempqeAkbBlt9emiSM';  //ageorge@customhouseinc.com.fullcopy USER  
      //let userId = '0053I000002dNKkQAM';  //ageorge@customhouseinc.com.fullcopy USER
      //let userId = '0053Z00000LOFbRQAX'; // jbitripleadmin@blulava.com.fullcopy
      const response = await axios.get(`${this.KOL_Api_Base_Url}/user/getUser/${this.KOL_UserID}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching User:", error);
      throw error;
    }
  }

  // public async getRoleBasedPermissions(userId: string) {
  //   return this.conn.query(`
  //       SELECT
  //         PermissionSetId, AssigneeId
  //       FROM
  //         PermissionSetAssignment
  //       WHERE
  //         AssigneeID = '${userId}'
  //       `);
  // }

  public async getKOLPorfile() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLPorfile/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching kol Profile Portal Message:", error);
      throw error;
    }
  }

  public async getAccounts() {
    const query = {
      "RecordType.Name": "Client",
    };
    const fields = [
      "Id",
      "LastModifiedDate",
      "Name",
      "Phone",
      "PersonMobilePhone",
      "BillingAddress",
      "PersonEmail",
      "PersonMailingAddress",
      "RecordType.Name",
      "IsPersonAccount",
      "Profile_Image_Large__c",
      "Profile_Image__c",
      "Profile_Thumbnail__c",
    ];
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getAccounts`);
      return response.data;
    } catch (error) {
      console.error("Error fetching Accounts:", error);
      throw error;
    }
    // return this.conn
    //   .sobject("Account")
    //   .find(query, fields)
    //   .sort("LastModifiedDate", "DESC")
    //   .limit(100);
  }

  public async getKols(sortOrder: "ASC" | "DESC" = "ASC") {

    let RecordTypeName = "Key Opinion Leader";
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKols/${RecordTypeName}/${sortOrder}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching get Kols:", error);
      throw error;
    }
  }

  // public async fetchProfilePortalMessage(formSelect?: any) {
  //   console.log('from fetchProfilePortalMessage');

  //   return this.conn.query(`
  //         SELECT
  //           Default_Classes__c,
  //           KOL_Profile_Portal__c,
  //           Message__c,
  //           Message_Type__c,
  //           Sort_Order__c,
  //           Valid_Date__c
  //         FROM KOL_Profile_Portal_Message__c 
  //         WHERE KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
  //         ${
  //           formSelect
  //             ? "AND Master_Engagement__r.Congress__c = '" +
  //               formSelect.slice(0, -3) +
  //               "'"
  //             : ""
  //         }
  //   `);
  // }

  public async fetchProfilePortalMessage() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchProfilePortalMessage/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching Profile Portal Message:", error);
      throw error;
    }
  }

  //

  // Select Engagement_Type__c, KOLAccount__c, Attendee_Role__c, Status__c, Master_Engagement__r.Leadership_Attendee__c, Master_Engagement__r.Client_Attendee__c, Master_Engagement__r.Start_Date_Time__c, Master_Engagement__r.End_Date_Time__c, Master_Engagement__r.Disease_Focus__c, Master_Engagement__r.Brand_Focus__c, Master_Engagement__r.Congress__c, Master_Engagement__r.Topic__c, Master_Engagement__r.Engagement_Type__c, Master_Engagement__r.Status__c, Master_Engagement__r.Format__c, Master_Engagement__r.Meal__c, Master_Engagement__r.Location_Type__c, Master_Engagement__r.Location__c, Master_Engagement__r.Notes_Details__c from KOL_Engagement__c where KOL_Profile_Portal__c = 'a348B0000004TSl'

  public async fetchHeaderNew(UserorGroupId: string) {
    // return this.conn.query(`
    //     Select Group.Name from GroupMember Where UserOrGroupId = '${UserorGroupId}'
    // `);
  }

  public async fetchHeader(UserorGroupId: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchHeader/${UserorGroupId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching Header:", error);
      throw error;
    }
    // return this.conn.query(`
    //     SELECT
    //       Name
    //       FROM Group 
    //       WHERE Id IN (SELECT GroupId from GroupMember where UserOrGroupId = '${UserorGroupId}')
    // `);
  }

  public async fetchCongress() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCongress?profilePortal=${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching congress:", error);
      throw error;
    }
  }

  public async fetchAllInsitution(
    newdivision?: string,
    username?: string,
    FFS__cValue?: string
  ) {
    try {

      const response = await axios.get(`${this.KOL_Api_Url}/fetchAllInsitution?KOL_Portal_Name=${this.KOL_Portal_Name}&FFS__cValue=${FFS__cValue ?? ''}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchAllInsitution", error);
      throw error;
    }
  }

  public async fetchInsitutionDetail(id, FFS__cValue?: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchInsitutionDetail/${id}/${this.KOL_Portal_Name}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchCommercialEngagementsHome", error);
      throw error;
    }
    //  console.log("id-->", id);
    //     return this.conn.query(`

    //       Select Name, (Select Master_Engagement__r.Name, Engagement_Type__c, Master_Engagement__r.Start_Date_Time__c,
    // Master_Engagement__r.Location__c, Master_Engagement__r.Brand_Master__r.Name, Master_Engagement__r.Brand_Master__r.Color__c from KOL_Engagements__r  ${FFS__cValue} Order by Master_Engagement__r.Start_Date_Time__c desc)
    // From Account
    // Where KOL_Profile_Portal__pr.Name = 'Janssen KOL Portal' and Primary_Title_Affiliation__pc = '${id}'
    // and Show_Institution_on_KOL_Dashboard__pc = true
    // And Id IN (Select Expert_Account__c from Expert__c where KOL_Profile_Portal__r.Name = 'Janssen KOL Portal' and Active__c = true)
    // Order by Name asc
    //     `);
  }

  /*
     select Name, (select Id, Name from KOL_Engagements__r), (Select Id, Institution__r.name from Institution__r) from Account where Id = '${this.KOL_Profile_Portal__pc}'*/
  // public async fetchAllInsitution() {
  //   return this.conn.query(`
  //     Select Institution__r.Name, count(KOL__c) KOLs from Association__c where KOL__c IN (Select KOLAccount__c from KOL_Engagement__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}') Group By Institution__r.Name

  //   `);
  // }

  // public async fetchInsitutionDetail(id) {
  //   console.log('id-->',id);
  //   return this.conn.query(`
  //     Select Name, (select Id, Master_Engagement__r.Name, Engagement_Type__c, Master_Engagement__r.Start_Date_Time__c, Master_Engagement__r.Location__c from KOL_Engagements__r) from Account where Id IN (Select KOL__c from Association__c Where Institution__r.Name = '${id}') and Id IN (Select Expert_Account__c from Expert__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}')

  //   `);
  // }

  public async fetchTotalEngagementsHome(formSelect?: any, brandFocus?: any[], FFS__cValue?: string): Promise<any> {
    try {
      const params = new URLSearchParams();

      if (formSelect !== undefined && formSelect !== "") {
        params.append('congressId', formSelect);
      }

      params.append('profilePortal', this.KOL_Profile_Portal__pc);

      (brandFocus ?? []).forEach(focus => params.append('brandFocus', focus.value));

      if (FFS__cValue !== undefined && FFS__cValue !== "") {
        params.append('ffs', FFS__cValue);
      }

      const response = await axios.get(`${this.KOL_Api_Url}/fetchTotalEngagementsHome`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error fetching total engagements home:", error);
      throw error;
    }
  };

  public async fetchCommercialEngagementsHome(
    profileId?: string,
    formSelect?: any,
    UserOrGroupId?: boolean,
    queryParams1?: string,
    FFS__cValue?: string
  ) {

    try {
      const params = new URLSearchParams();

      if (formSelect !== undefined && formSelect !== "") {
        params.append('congressId', formSelect);
      }

      const response = await axios.get(`${this.KOL_Api_Url}/fetchCommercialEngagementsHome/${this.KOL_Profile_Portal__pc}`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error fetchCommercialEngagementsHome", error);
      throw error;
    }
  }

  // public async fetchMedicalEngagementsHome(
  //   profileId?: string,
  //   formSelect?: any,
  //   UserOrGroupId?: boolean,
  //   queryParams1?: string,
  //   FFS__cValue?:string
  // ) {
  //     return this.conn.query(`
  //       SELECT Brand_Master__r.Name, COUNT(id) engs, CALENDAR_QUARTER(Engagement_Date__c), CALENDAR_YEAR(Engagement_Date__c)

  //       FROM Master_Enagement__c 

  //       where KOL_Profile_Portal__c = '${
  //         this.KOL_Profile_Portal__pc
  //       }' and Engagement_Type__c = 'Medical'
  //       ${FFS__cValue}
  //       ${
  //         formSelect
  //           ? "AND Congress__c = '" + formSelect.slice(0, -3) + "'"
  //           : ""
  //       }
  //       ${queryParams1 != "" ? "and (" + queryParams1 + ")" : ""} 
  //       GROUP BY Brand_Master__r.Name, CALENDAR_QUARTER(Engagement_Date__c), CALENDAR_YEAR(Engagement_Date__c) 

  //       ORDER BY Brand_Master__r.Name
  //       `);
  // }

  public async fetchMedicalEngagementsHome(congressId?: any, brandFocus?: string, ffSc?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchMedicalEngagementsHome?brandFocus=${brandFocus}&ffSc=${ffSc}&congressId=${congressId ?? ''}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching medical engagements:", error);
      throw error;
    }
  }

  public async getMostActive(
    UserOrGroupId?: boolean,
    queryParams?: string,
    queryBrand?: string,
    FFS__cValue?: string
  ) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getMostActive/${this.KOL_Profile_Portal__pc}/${BRAND1}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getMostActive2", error);
      throw error;
    }
    // if (queryBrand == BRAND1) {

    //     return this.conn.query(`

    //       Select Id, Name, (Select Id from KOL_Engagements__r 
    //       where Master_Engagement__r.Brand_Master__r.Name = '${BRAND1}' ${
    //                 queryParams != "" ? "and (" + queryParams + ")" : ""
    //               } ${FFS__cValue} ), 
    //       (Select Brand_Master__r.Name, Advocacy_Label_1__c, Advocacy_Score_1__c, 
    //       Advocacy_Label_2__c, Advocacy_Score_2__c, OverallScore__c, Impact_Score__c, KnowledgeIndex__c, EngagementIndex__c from KOL_Brands__r 
    //       where Brand_Master__r.Name IN ('${BRAND1}')) From Account Where Id IN 
    //       (Select Expert_Account__c from Expert__c where KOL_Profile_Portal__c = '${
    //                 this.KOL_Profile_Portal__pc
    //               }') 
    //       and Id IN (Select KOL__c from KOL_Brand__c where Brand_Master__r.Name IN ('${BRAND1}')) 
    //     `);

    // } 

    // return this.conn.query(`

    //   Select Id, Name, (Select Id from KOL_Engagements__r 
    //   where Master_Engagement__r.Brand_Master__r.Name = '${BRAND1}' ${
    //               "" != "" ? "and (" + "" + ")" : ""
    //             } ${FFS__cValue}), 
    //   (Select Brand_Master__r.Name, Advocacy_Label_1__c, Advocacy_Score_1__c, 
    //   Advocacy_Label_2__c, Advocacy_Score_2__c, OverallScore__c, Impact_Score__c, KnowledgeIndex__c, EngagementIndex__c from KOL_Brands__r 
    //   where Brand_Master__r.Name IN ('${BRAND1}')) From Account Where Id IN 
    //   (Select Expert_Account__c from Expert__c where KOL_Profile_Portal__c = '${
    //               this.KOL_Profile_Portal__pc
    //             }') 
    //   and Id IN (Select KOL__c from KOL_Brand__c where Brand_Master__r.Name IN ('${BRAND1}')) 
    // `);
  }



  public async getMostActive1(
    UserOrGroupId?: boolean,
    queryParams?: string,
    queryBrand?: string,
    FFS__cValue?: string
  ) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getMostActive/${this.KOL_Profile_Portal__pc}/${BRAND1}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getMostActive2", error);
      throw error;
    }
  }

  public async getMostActive2(
    UserOrGroupId?: boolean,
    queryParams?: string,
    queryBrand?: string,
    FFS__cValue?: string
  ) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getMostActive/${this.KOL_Profile_Portal__pc}/${BRAND3}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getMostActive2", error);
      throw error;
    }

    //     if (queryBrand == BRAND3) {
    //     return this.conn.query(`
    //           Select Id, Name, (Select Id from KOL_Engagements__r 
    //     where Master_Engagement__r.Brand_Master__r.Name = '${BRAND3}' ${
    //       queryParams != "" ? "and (" + queryParams + ")" : ""
    //     } ${FFS__cValue}), 
    //     (Select Brand_Master__r.Name, Advocacy_Label_1__c, Advocacy_Score_1__c, 
    //     Advocacy_Label_2__c, Advocacy_Score_2__c, OverallScore__c, Impact_Score__c, KnowledgeIndex__c, EngagementIndex__c from KOL_Brands__r 
    //     where Brand_Master__r.Name IN ('${BRAND3}')) From Account Where Id IN 
    //     (Select Expert_Account__c from Expert__c where KOL_Profile_Portal__c = '${
    //       this.KOL_Profile_Portal__pc
    //     }') 
    //     and Id IN (Select KOL__c from KOL_Brand__c where Brand_Master__r.Name IN ('${BRAND3}'))
    //           `);
    // }
    //   return this.conn.query(`
    //           Select Id, Name, (Select Id from KOL_Engagements__r 
    //     where Master_Engagement__r.Brand_Master__r.Name = '${BRAND3}' ${
    //     "" != "" ? "and (" + "" + ")" : ""
    //   } ${FFS__cValue}), 
    //     (Select Brand_Master__r.Name, Advocacy_Label_1__c, Advocacy_Score_1__c, 
    //     Advocacy_Label_2__c, Advocacy_Score_2__c, OverallScore__c, Impact_Score__c, KnowledgeIndex__c, EngagementIndex__c from KOL_Brands__r 
    //     where Brand_Master__r.Name IN ('${BRAND3}')) From Account Where Id IN 
    //     (Select Expert_Account__c from Expert__c where KOL_Profile_Portal__c = '${
    //       this.KOL_Profile_Portal__pc
    //     }') 
    //     and Id IN (Select KOL__c from KOL_Brand__c where Brand_Master__r.Name IN ('${BRAND3}'))
    //   `);
  }

  public async getLeastActive() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getLeastActive`);
      return response.data;
    } catch (error) {
      console.error("Error fetching LeastActive:", error);
      throw error;
    }
    // return this.conn.query(`
    //       SELECT 
    //         KOLAccount__r.Id,
    //         KOLAccount__r.Profile_Thumbnail__c, 
    //         KOLAccount__r.Name, 
    //         count(id) CntMeetings,
    //         KOLAccount__r.Tier__pc, 
    //         KOLAccount__r.Degree__pc 
    //       FROM 
    //         KOL_Engagement__c 
    //       WHERE 
    //         Master_Engagement__r.KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
    //         AND Attendee_Type__c != 'Employee' 
    //         AND Status__c='Attended'
    //         Group by KOLAccount__r.id , KOLAccount__r.Tier__pc ,KOLAccount__r.Profile_Thumbnail__c, KOLAccount__r.Name, KOLAccount__r.Degree__pc 
    //         ORDER BY count(id) ASC LIMIT 5
    //   `);
  }

  //KOL OVERVIEW COMPONENT

  public async getKOLoverview() {
    // const query = {
    //   KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
    // };
    // const fields = ["count(id)"];
    // return this.conn.sobject("Expert__c").find(query, fields);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLoverview`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLoverview:", error);
      throw error;
    }
  }

  public async getKOLoverviewNew(
    queryParams1: string[],
    newdivision: string,
    username: string
  ) {
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getKOLoverviewNew/${this.KOL_Profile_Portal__pc}`,
        queryParams1
      );
      return response.data;
    } catch (error) {
      console.error("Error getKOLoverviewNew", error);
      throw error;
    }

    // console.log("queryParams1fromsfdc", queryParams1);
    // return this.conn.query(`
    //   Select Count(id) from KOL_Brand__c where KOL__c IN 
    //   (Select Expert_Account__c from Expert__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
    //   and Active__c = true) and (${queryParams1})
    // `);
  }

  //   public async getKOLoverviewNew(queryParams: string): Promise<string> {
  //     try {
  //         const response = await axios.get(`${this.KOL_Api_Url}/getKOLoverviewNew?queryParams=${queryParams}&?profilePortal=${this.KOL_Profile_Portal__pc}`);
  //         return response.data;
  //     } catch (error) {
  //         console.error("Error fetching global name:", error);
  //         throw error;
  //     }
  // };

  public async getKOLtarget() {
    // return this.conn.query(`
    //   SELECT count(id)
    //   from Expert__c
    //   where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' and Expert_Account__r.Write_In_KOL__pc = false
    //     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLtarget`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLtarget", error);
      throw error;
    }
  }

  public async getKOLtargetNew(
    queryParams1: string,
    newdivision: string,
    username: string
  ) {
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getKOLtargetNew?KOL_Profile_Portal__pc=${this.KOL_Profile_Portal__pc}`,
        queryParams1
      );
      return response.data;
    } catch (error) {
      console.error("Error getKOLtargetNew", error);
      throw error;
    }
  }

  public async getLeadersByExpert(expertType: string) {
    // check docs for != for tier...
    const query = {
      KOL_Profile_Portal__pc: this.KOL_Profile_Portal__pc,
      Expert_Type__pc: `${expertType}`,
    };
    const fields = ["count(Name)"];
    // return this.conn.sobject("Account").find(query, fields);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getLeadersByExpert`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getLeadersByExpert:", error);
      throw error;
    }
  }

  public async getTotalAcedemic() {
    // return this.conn.query(`
    //     Select count(id), kol_classification__c
    //     from Account
    //     where KOL_Profile_Portal__pc = '${this.KOL_Profile_Portal__pc}'
    //     group by kol_classification__c
    //     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getTotalAcedemic`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getTotalAcedemic:", error);
      throw error;
    }    
  }

  public async getTotalAcedemicNew(
    queryParams1: string[],
    newdivision: string,
    username: string
  ) {
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getTotalAcedemicNew/${this.KOL_Profile_Portal__pc}`,
        queryParams1
      );
      return response.data;
    } catch (error) {
      console.error("Error TotalAcedemicNew", error);
      throw error;
    }
  }

  public async getInstitutionsTotalEngment() {
    // return this.conn.query(`
    //     SELECT COUNT(Id)
    //     FROM KOL_Engagement__c
    //     where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' AND Status__c = 'Attended' and Attendee_Type__c != 'Employee'
    //             `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getInstitutionsTotalEngment`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getInstitutionsTotalEngment:", error);
      throw error;
    }    
  }

  public async getInstitutionsTotalEngmentNew(
    queryParams: string,
    congressFilter: string,
    year: string,
    UserOrGroupId: any,
    newdivision: string,
    username: string
  ) {


    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getInstitutionsTotalEngmentNew?KOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}&congress=${Object.keys(congressFilter).length !== 0 ? congressFilter : ''}&year=${year}`,
        queryParams
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching getInstitutionsTotalInstitutionsNew:", error);
      throw error;
    }
  }

  public async getInstitutionsTotalInstitutions() {
//     return this.conn.query(`
//       SELECT COUNT(Id)
// from Association__c

// Where KOL__c IN (Select Expert_Account__c from Expert__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}')
//                 `);
try {
  const response = await axios.get(`${this.KOL_Api_Url}/getInstitutionsTotalInstitutions`);
  return response.data;
} catch (error) {
  console.error("Error fetching getInstitutionsTotalInstitutions:", error);
  throw error;
}
  }

  public async getInstitutionsTotalInstitutionsNew(
    queryParams: string,
    newdivision: string,
    username: string
  ) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getInstitutionsTotalInstitutionsNew?brands=${queryParams}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getInstitutionsTotalInstitutionsNew:", error);
      throw error;
    }
  }

  public async getInstitutionsAffiliated() {
    // return this.conn.query(`
    //     SELECT count(id)
    //     from Expert__c
    //     where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
    //             `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getInstitutionsAffiliated`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getInstitutionsAffiliated:", error);
      throw error;
    }
  }

  public async meetings1to1() {
    // check docs for != for tier...
    const query = {
      KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
      Engagement_Type__c: "1:1 Meeting",
    };
    const fields = [
      "Attendee_Type__c",
      "Engagement_Date__c",
      "Id",
      "KOLAccount__r.Name",
    ];
    // return this.conn
    //   .sobject("KOL_Engagement__c")
    //   .find(query, fields)
    //   .sort("LastModifiedDate", "ASC")
    //   .limit(20);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/meetings1to1`);
      return response.data;
    } catch (error) {
      console.error("Error fetching meetings1to1:", error);
      throw error;
    }    
  }

  // ALL CHART QUERYS GO BELOW

  //PIE CHART Q


  public async getKolEngagementReach(
    queryParams: string,
    UserOrGroupId: any,
    queryParams1: string,
    FFS__cValue?: string
  ) {
    const body = {
      brandNames: queryParams,
      focusNames: queryParams1,
      KOL_Profile_Portal__c_Name: this.KOL_Portal_Name
    }

    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getKolEngagementReach?KOL_Portal_Name=${this.KOL_Portal_Name}`,
        body
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching getKolEngagementReach:", error);
      throw error;
    }
    // check docs for != for tier...
    // `
    //     Select KOL_Brand__r.Advocacy_Score_1__c, count(id) from KOL_Engagement__c 
    //     where Master_Engagement__r.KOL_Profile_Portal__r.Name = 'Janssen KOL Portal' 
    //     ${FFS__cValue}
    //     and 
    //     (${queryParams}) 
    //     ${queryParams1 != "" ? "and (" + queryParams1 + ")" : ""}
    //     group by KOL_Brand__r.Advocacy_Score_1__c order by KOL_Brand__r.Advocacy_Score_1__c asc 
    // `
    // )
  }

  public async getKOLbarChat() {
    // check docs for != for tier...
//     return this.conn.query(`
//     SELECT 
//       Engagement_Type__c,
//       KOLAccount__r.Tier__pc,
//       count(Id) Engagements 
//       FROM KOL_Engagement__c 
//       WHERE Master_Engagement__r.KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
//       AND Status__c = 'Attended'
//       AND Attendee_Type__c != 'Employee'
//       GROUP BY Engagement_Type__c, 
//       KOLAccount__r.Tier__pc
// `);
try {
  const response = await axios.get(`${this.KOL_Api_Url}/getKOLbarChat`);
  return response.data;
} catch (error) {
  console.error("Error fetching getKOLbarChat:", error);
  throw error;
}
  }

  public async getByEngagementType(type: string) {
    // return this.conn.query(`
    //     SELECT 
    //       Engagement_Type__c,
    //       KOLAccount__r.Tier__pc,
    //       count(Id) Engagements 
    //       FROM KOL_Engagement__c 
    //       WHERE Master_Engagement__r.KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
    //       AND Engagement_Type__c = '${type}'
    //       AND Status__c = 'Attended'
    //       AND Attendee_Type__c != 'Employee'
    //       GROUP BY Engagement_Type__c, 
    //       KOLAccount__r.Tier__pc
    // `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getByEngagementType`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getByEngagementType:", error);
      throw error;
    }    
  }

  public async getByEngagement(
    brandsFilters: string[],
    UserOrGroupId?: boolean,
    brandsFocus?: string[],
    FFS__cValue?: boolean
  ) {
    if (brandsFilters.length !== 0) {
      try {
        const response = await axios.get(`${this.KOL_Api_Url}/getByEngagement?KOL_Portal_Name=${this.KOL_Portal_Name}&brandsFilters=${brandsFilters}&brandsFocus=${brandsFocus}&ffsc=${FFS__cValue}`);
        return response.data;
      } catch (error) {
        console.error("Error fetching global focus:", error);
        throw error;
      }
      /*
      return this.conn.query(`
    Select Master_Engagement__r.Type__c, KOL_Brand__r.Advocacy_Score_1__c, 
    count(id) from KOL_Engagement__c where Master_Engagement__r.KOL_Profile_Portal__r.Name = 'Janssen KOL Portal'
    ${FFS__cValue}
    and (${queryParams})
     ${queryParams1 != "" ? "and (" + queryParams1 + ")" : ""}
    group by Master_Engagement__r.Type__c, KOL_Brand__r.Advocacy_Score_1__c order by KOL_Brand__r.Advocacy_Score_1__c asc

  `);
  */
    }
  }

  public async getDialogEngagementTrack(type: string, tier: number) {
    // return this.conn.query(`
    //       SELECT 
    //         Engagement_Type__c, Master_Engagement__r.Name, Master_Engagement__r.Id,
    //         Master_Engagement__r.Engagement_Date__c, Master_Engagement__r.Type__c
    //       FROM KOL_Engagement__c 
    //       WHERE 
    //         Master_Engagement__r.KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
    //         AND Engagement_Type__c = '${type}'
    //         AND Status__c = 'Attended' 
    //         AND Attendee_Type__c != 'Employee' 
    //         AND KOLAccount__r.Tier__pc = '${tier}'
    // `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getDialogEngagementTrack`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getDialogEngagementTrack:", error);
      throw error;
    }
  }

  public async get1to1MeetingsPrevoius() {
    // check docs for != for tier...
    // return this.conn.query(`
    //   SELECT Attendee_Type__c, Master_Engagement__r.Engagement_Date__c,
    //    Master_Engagement__r.Start_Date_Time__c, Id, KOLAccount__r.Name,
    //     Master_Engagement__r.Id FROM KOL_Engagement__c WHERE Master_Engagement__r.KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
    //      AND (Master_Engagement__r.Type__c = '1:1 Meetings') 
    //     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/get1to1MeetingsPrevoius`);
      return response.data;
    } catch (error) {
      console.error("Error fetching get1to1MeetingsPrevoius:", error);
      throw error;
    }
  }

  public async get1to1MeetingsUpcoming(year: string,
    brandNames?: string[],
    brandFocus?: any[],
    FFS__cValue?: any) {

    const params = new URLSearchParams();

    params.append('year', year);
    params.append('portalName', this.KOL_Portal_Name);
    params.append('createdById', this.KOL_UserID)  
    if (FFS__cValue !== undefined && FFS__cValue !== "") {
      params.append('ffs', FFS__cValue);
    }

    (brandNames ?? []).forEach(name => params.append('brandNames', name));
    (brandFocus ?? []).forEach(focus => params.append('brandFocus', focus.value));

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/get1to1MeetingsUpcoming`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  /*
  public async get1to1Meetings2(attendeeType: string) {
    // check docs for != for tier...
    return this.conn.query(`
      SELECT 
        Attendee_Type__c,
        Master_Engagement__r.Engagement_Date__c,
        Master_Engagement__r.Start_Date_Time__c,
        Id,
        KOLAccount__r.Name, 
        Master_Engagement__r.Id 
          FROM KOL_Engagement__c 
        WHERE Master_Engagement__r.KOL_Profile_Portal__c = '${
          this.KOL_Profile_Portal__pc
        }' 
        AND (Engagement_Type__c = '1:1 Meeting' OR Engagement_Type__c = '1:1 Engagement') 
        ${attendeeType && "AND Attendee_Type__c ='" + attendeeType + "'"}
        AND Engagement_Date__c IN (NEXT_90_DAYS,LAST_90_DAYS)
        ORDER BY Engagement_Date__c ASC NULLS LAST LIMIT 5
        `);
  }
  */

  public async get1to1Meetings(attendeeType: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/get1to1Meetings?attendeeType=${attendeeType}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching get1to1Meetings3:", error);
      throw error;
    }
  }

  public async getEvents(
    year: string,
    UserOrGroupId?: boolean,
    queryParams?: string,
    queryParams1?: string
  ) {
    // //new get events query since I was given the wrong one...
    // return this.conn.query(`
    //   SELECT Master_Engagement__r.Brand_Master__r.Name,Master_Engagement__r.Brand_Master__r.Color__c, Attendee_Type__c, Master_Engagement__r.Engagement_Date__c, Master_Engagement__r.Name,
    //   Master_Engagement__r.Start_Date_Time__c, Id, KOLAccount__r.Name, Master_Engagement__r.Id 
    //   FROM KOL_Engagement__c WHERE Master_Engagement__r.KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc
    //   }' 
    //   AND 
    //   CALENDAR_YEAR(Master_Engagement__r.Engagement_Date__c) = ${year}  
    //   ${queryParams != ""
    //     ? "AND (" + queryParams + ")"
    //     : "AND (Master_Engagement__r.Brand_Master__r.Name = '1')"
    //   }
    //    ${queryParams1 != "" ? "and (" + queryParams1 + ")" : ""}
    //   ORDER BY Master_Engagement__r.Start_Date_Time__c desc
    //             `);

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getEvents`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getEvents:", error);
      throw error;
    }    
  }

  public async getPreviousEvents() {
    //new get events query since I was given the wrong one...
    // return this.conn.query(`
    //   SELECT Attendee_Type__c, Master_Engagement__r.Engagement_Date__c,
    //    Master_Engagement__r.Start_Date_Time__c, Id, KOLAccount__r.Name,
    //     Master_Engagement__r.Id FROM KOL_Engagement__c WHERE Master_Engagement__r.KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
    //      AND (Master_Engagement__r.Type__c != '1:1 Meetings')
    //     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getPreviousEvents`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getPreviousEvents:", error);
      throw error;
    }    
  }

  //Engagement Topic Leaderboard Tile

  // public async fetchCountBrandFocus(
  //   isCurrent: boolean,
  //   UserOrGroupId?: boolean,
  //   FFS__cValue?:string
  // ) {
  //     console.log('from fetchCountBrandFocus');

  //     // if isCurrent is true  current quater else prev quarter    
  //     return this.conn.query(`
  //       Select 
  //         Brand_Master__r.Name Brand, 
  //         Focus_Master__r.Brand_Disease_Focus__c Focus, 
  //         Topic_Master__r.Name Topic, 
  //         Count(Id) 
  //       from 
  //         Master_Enagement__c 
  //       where 
  //         Topic_Master__r.Name != null 
  //         and KOL_Profile_Portal__r.Name = 'Janssen KOL Portal' 
  //         and Focus_Master__c != null
  //         ${FFS__cValue} 
  //       Group by 
  //         Brand_Master__r.Name, 
  //         Focus_Master__r.Brand_Disease_Focus__c, 
  //         Topic_Master__r.Name 
  //       Order by Count(Id) desc 
  //   `);
  // }

  public async fetchCountBrandFocus(
    isCurrent: boolean,
    UserOrGroupId?: boolean,
    FFS__cValue?: string
  ) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCountBrandFocus?KOL_Portal_Name=${this.KOL_Portal_Name}&FFS__cValue=${FFS__cValue ?? ''}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching CountBrandFocus:", error);
      throw error;
    }
  }

  public async fetchCountDiseaseFocus(
    isCurrent: boolean,
    UserOrGroupId?: boolean,
    FFS__cValue?: string
  ) {
    // if isCurrent is true  current quater else prev quarter
    // return this.conn.query(`
    //     Select Brand_Master__r.Name Brand, Focus_Master__r.Brand_Disease_Focus__c Focus, 
    //     Topic_Master__r.Name Topic, Count(Id) from Master_Enagement__c where Topic_Master__r.Name != null 
    //     and KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'  and Focus_Master__c != null
    //     ${FFS__cValue}
    //     Group by Brand_Master__r.Name, Focus_Master__r.Brand_Disease_Focus__c, 
    //     Topic_Master__r.Name Order by Count(Id) desc 
    // `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCountDiseaseFocus`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchCountDiseaseFocus:", error);
      throw error;
    } 
  }

  public async fetchAlignment() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchAlignment?profilePortal=${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching permissions:", error);
      throw error;
    }
  }

  // public async fetchCountDiseaseFocus(isCurrent: boolean) {
  //   // if isCurrent is true  current quater else prev quarter
  //   return this.conn.query(`
  //         SELECT
  //          Disease_Focus__c
  //         FROM Master_Enagement__c
  //         WHERE
  //           KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
  //           AND Disease_Focus__c != ''
  //           AND ${isCurrent
  //       ? "Engagement_Date__c > LAST_N_QUARTERS:1"
  //       : "Engagement_Date__c = LAST_N_QUARTERS:1"
  //     }
  //         `);
  // }

  // public async congressRelatedEvents() {
  //       return this.conn.query(`
  //             SELECT 
  //               Calendar_Default_Date__c,
  //               Label__c,
  //               Id,
  //               End_Date__c,
  //               Location__c,
  //               Name 
  //               FROM Congress__c 
  //               WHERE KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
  //               `);
  // }

  public async congressRelatedEvents() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/congressRelatedEvents/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching congress related events:", error);
      throw error;
    }
  }

  /*
  public async fetchKolAttendees(queryParams1: string) {
                return this.conn.query(` 
                  Select KOL__c, KOL__r.Name, KOL__r.PersonEmail, Brand_Master__r.Name, OverallScore__c, Impact_Score__c, KnowledgeIndex__c, EngagementIndex__c from KOL_Brand__c where 
                  ${
                    queryParams1 != ""
                      ? " (" + queryParams1 + ")"
                      : "(Brand_Master__r.Name = '')"
                  }
                  and KOL__c 
                  IN (SELECT Expert_Account__c FROM Expert__c WHERE KOL_Profile_Portal__c = 
                  '${this.KOL_Profile_Portal__pc}' and Active__c = true)
                    `);
  }
  */

  public async fetchKolAttendees(queryParams1: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKolAttendees?KOL_Profile_Portal_c=${this.KOL_Profile_Portal__pc}&brands=${queryParams1}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async fetchLeaderAttendee() {
    //   return this.conn.query(`
    //   Select Id, Employee_Full_Name__c, Employee__r.IsActive
    //   from Employee__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
    //  and Leadership_Attendee__c = true and Employee__r.Name != null and Active__c = true
    //  Order by Employee__r.Name asc 
    //                   `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchLeaderAttendee/${this.KOL_Profile_Portal__pc}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchLeaderAttendee", error);
      throw error;
    }
  }

  /*
  public async fetchClientrAttendee() {
        return this.conn.query(`
    Select Id, Employee_Full_Name__c, Employee__r.IsActive
 from Employee__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
and Leadership_Attendee__c = false and Employee__r.Name != null and Active__c = true
Order by Employee__r.Name asc 
                    `);
  }
  */

  public async fetchClientrAttendee() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchClientrAttendee/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchCommercialEngagementsHome", error);
      throw error;
    }
  }

  public async getSingleEngagement(id: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getSingleEngagement?id=${id}`,
      );
      return response.data;
    } catch (error) {
      console.error("Error getSingleEngagement: ", error);
      throw error;
    }
    // return this.conn.query(`
    // Select Id, 
    // Engagement_Date__c,
    // Start_Date_Time__c,
    // End_Date_Time__c,
    // Brand_Master__r.Name,
    // Name,
    // Brand_Focus__c,
    // Disease_Focus__c,
    // Type__c,
    // Topic_Master__r.Name ,
    // Meal__c,
    // Format__c ,
    // Location_Type__c,
    // Congress__c,
    // Location__c,
    // Notes_Details__c,
    // Congress__r.Label__c,
    // Congress__r.Calendar_Default_Date__c,
    // Congress__r.End_Date__c,
    // CreatedBy.Name,
    // CreatedById,
    // Time_Zone__c,
    // Status__c,
    // Engagement_Type__c,
    // (Select Id, Attendee_Role__c, KOLAccount__c, KOLAccount__r.Name, KOLAccount__r.Write_In_KOL__pc, Status__c, KOLAccount__r.Profile_Thumbnail__c from KOL_Engagements__r), (Select  Id, Employee__c,  Attendee_Name__c, Employee__r.Leadership_Attendee__c ,Attendee_Role__c from KOL_Attendees__r)
    // From Master_Enagement__c
    // Where ID  = '${id}'


    // `);
  }

  public async fetchEngagementsAll(
    queryParams: string,
    congressFilter: string,
    UserOrGroupId: any,
    queryParams1: string,
    FFS__cValue?: string
  ) {
    const body = {
      brandNames: queryParams,
      focusNames: queryParams1,
      KOL_Profile_Portal__c_Name: this.KOL_Portal_Name,
      congressId: Object.keys(congressFilter).length !== 0 ? congressFilter : '',
      pageSize: 100,
      pageNumber: 1,
      userId: this.KOL_UserID
    }
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/fetchEngagementsAll?KOL_Portal_Name=${this.KOL_Portal_Name}`,
        body
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchEngagementsAll: ", error);
      throw error;
    }
    // // and CALENDAR_MONTH(Engagement_Date__c) = 5
    // if (queryParams !== "") {
    //   if (Object.keys(congressFilter).length != 0) {
    //                         return this.conn.query(`
    //                Select Id, Engagement_Date__c,
    //                     Start_Date_Time__c,
    //                     End_Date_Time__c,
    //                     Brand_Master__r.Name,
    //                     Brand_Master__r.Color__c,
    //                     Name,
    //                     Type__c,
    //                     Congress__c,
    //                     Location__c,
    //                     Notes_Details__c,
    //                     Congress__r.Label__c,
    //                     Congress__r.Calendar_Default_Date__c,
    //                     Congress__r.End_Date__c,
    //                     CreatedBy.Name,
    //                     CreatedById,
    //                     Time_Zone__c,
    //                     Status__c,
    //                     Engagement_Type__c,
    //                    (Select KOLAccount__r.Name, KOLAccount__r.Profile_Thumbnail__c from KOL_Engagements__r), (Select Attendee_Name__c from KOL_Attendees__r where Employee__r.Leadership_Attendee__c = false)
    //                     From Master_Enagement__c
    //                     Where  KOL_Profile_Portal__r.Name = 'Janssen KOL Portal'
    //                     ${
    //                       queryParams != ""
    //                         ? "AND (" + queryParams + ")"
    //                         : "AND (Brand_Master__r.Name = '')"
    //                     }
    //                      ${
    //                        queryParams1 != ""
    //                          ? "and (" + queryParams1 + ")"
    //                          : ""
    //                      }
    //                     and Congress__c = '${congressFilter}' 
    //                     ${FFS__cValue}
    //                     ORDER BY Start_Date_Time__c desc`);
    //   } 
    //             return this.conn.query(`
    //                Select Id, Engagement_Date__c,
    //                     Start_Date_Time__c,
    //                     End_Date_Time__c,
    //                     Brand_Master__r.Name,
    //                     Brand_Master__r.Color__c,
    //                     Name,
    //                     Type__c,
    //                     Congress__c,
    //                     Location__c,
    //                     Notes_Details__c,
    //                     Congress__r.Label__c,
    //                     Congress__r.Calendar_Default_Date__c,
    //                     Congress__r.End_Date__c,
    //                     CreatedBy.Name,
    //                     CreatedById,
    //                     Time_Zone__c,
    //                     Status__c,
    //                     Engagement_Type__c,
    //                    (Select KOLAccount__r.Name, KOLAccount__r.Profile_Thumbnail__c from KOL_Engagements__r), (Select Attendee_Name__c from KOL_Attendees__r where Employee__r.Leadership_Attendee__c = false)
    //                     From Master_Enagement__c
    //                     Where  KOL_Profile_Portal__r.Name = 'Janssen KOL Portal'
    //                     ${
    //                       queryParams != ""
    //                         ? "AND (" + queryParams + ")"
    //                         : "AND (Brand_Master__r.Name = '')"
    //                     }
    //                     ${
    //                       queryParams1 != "" ? "and (" + queryParams1 + ")" : ""
    //                     }
    //                     ${FFS__cValue}
    //                     ORDER BY Start_Date_Time__c desc`);
    // }
  }

  public async fetchEngagements(profileId?: string, queryParams?: string, queryParams1?: string, FFS__cValue?: string) {
    //     return this.conn.query(`

    //                  Select Id, Engagement_Date__c,
    //                         Start_Date_Time__c,
    //                         End_Date_Time__c,
    //                         Brand_Master__r.Name,
    //                         Brand_Focus__c, 
    //                         Disease_Focus__c,
    //                         Topic__c,
    //                         Owner.FirstName,
    //                         Owner.LastName,
    //                         Brand_Master__r.Color__c,
    //                         Name,
    //                         Type__c,
    //                         Congress__c,
    //                         Location__c,
    //                         Notes_Details__c,
    //                         Congress__r.Label__c,
    //                         Congress__r.Calendar_Default_Date__c,
    //                         Congress__r.End_Date__c,
    //                         CreatedBy.Name,
    //                         CreatedById,
    //                         Time_Zone__c,
    //                         Status__c,
    //                         Engagement_Type__c,
    //                        (Select KOLAccount__r.Name, KOLAccount__r.Profile_Thumbnail__c from KOL_Engagements__r), 
    //                        (Select Attendee_Name__c from KOL_Attendees__r where Employee__r.Leadership_Attendee__c = false)
    // From Master_Enagement__c
    // Where ID IN (Select Master_Engagement__c from KOL_Engagement__c where KOLAccount__c = '${profileId}') and KOL_Profile_Portal__r.Name = 'Janssen KOL Portal'
    // ${
    //   queryParams != ""
    //     ? "AND (" + queryParams + ")"
    //     : "AND (Brand_Master__r.Name = '')"
    // }
    //  ${
    //    queryParams1 != ""
    //      ? "and (" + queryParams1 + ")"
    //      : ""
    //  }
    // ${FFS__cValue} ORDER BY Start_Date_Time__c desc`);

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEngagements?profileId=${profileId}&KOL_Portal_Name=${this.KOL_Portal_Name}&userId=${this.KOL_UserID}&brandsFilters=${queryParams}&brandsFocus=${queryParams1}&ffsc=${FFS__cValue ?? ''}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchEngagements: ", error);
      throw error;
    }
  }

  public async fetchGattexAttendees() {
    // return this.conn.query(`
    //                 SELECT 
    //                 Attendee__c,
    //                 Attendee__r.Name
    //                   FROM Client_Attendee__c
    //                   WHERE KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
    //   }'
    //                     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchGattexAttendees`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchGattexAttendees:", error);
      throw error;
    }     
  }

  public async fetchGattexAttendeesStage() {
    // return this.conn.query(`
    //                 SELECT 
    //                 Attendee__c,
    //                 Attendee__r.Name
    //                   FROM Client_Attendee__c
    //                   WHERE KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
    //                     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchGattexAttendeesStage`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchGattexAttendeesStage:", error);
      throw error;
    } 
  }

  public async fetchCalendarEvents(engagementId?: string) {
    // Start_Date, End_Date, Id, Location, Details all needed for update request
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCalendarEvents?KOL_Profile_Portal__pc=${this.KOL_Profile_Portal__pc}&engagementId=${engagementId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchCalendarEvents: ", error);
      throw error;
    }
    // return this.conn.query(`
    //                   SELECT 
    //                     Engagement_Date__c,
    //                     Start_Date_Time__c,
    //                     End_Date_Time__c,
    //                     Name,
    //                     Id, 
    //                     Type__c,
    //                     Congress__c,
    //                     Location__c,
    //                     Notes_Details__c,
    //                     Congress__r.Label__c,
    //                     Congress__r.Calendar_Default_Date__c,
    //                     Congress__r.End_Date__c,
    //                     CreatedBy.Name,
    //                     CreatedById
    //                   FROM Master_Enagement__c 
    //                     WHERE KOL_Profile_Portal__c = '${
    //                       this.KOL_Profile_Portal__pc
    //                     }' 
    //                     ${engagementId ? "AND Id ='" + engagementId + "'" : ""}
    //                     ORDER BY Engagement_Date__c ASC NULLS LAST
    //                     `);
  }

  public async fetchCreatedBy(engagementId?: string) {
    // // Start_Date, End_Date, Id, Location, Details all needed for update request
    // return this.conn.query(`
    // SELECT KOLAccount__r.Name,Master_Engagement__c FROM KOL_Engagement__c WHERE Attendee_Type__c = 'Employee'
    //                     AND KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc
    //   }'
    //                     ${engagementId
    //     ? "AND Master_Engagement__c ='" + engagementId + "'"
    //     : ""
    //   }
    //                     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCreatedBy`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchCreatedBy:", error);
      throw error;
    } 
  }

  public async fetchAlerts() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchAlerts/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchAlerts", error);
      throw error;
    }
    // return this.conn.query(`
    //               SELECT 
    //                 Id, Engagement_Date__c,Name,Type__c FROM Master_Enagement__c 
    //               WHERE KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
    //                 AND (Type__c = '1:1 Meeting' OR Type__c = '1:1 Engagement' OR Type__c = 'Advisory Board/Steering Committee' 
    //                 OR Type__c ='Ad Hoc Consulting' OR Type__c = 'Other' ) 
    //                 AND Engagement_Date__c = LAST_90_DAYS
    //                 AND No_Topics_to_Note__c = false
    //               ORDER BY Engagement_Date__c DESC
    //               `);
  }

  /*
  public async fetchTags(engagementId?: string) {
        return this.conn.query(`
                  SELECT 
                    Id,
                    Engagement_Date__c,
                    Name,
                    Type__c, 
                    Brand_Focus__c, 
                    Disease_Focus__c, 
                    Takeda_Insight__c, 
                    No_Topics_to_Note__c 
                  FROM Master_Enagement__c 
                  WHERE KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
                  ${engagementId ? "AND Id ='" + engagementId + "'" : ""}
                      `);
    // AND Engagement_Date__c = LAST_90_DAYS ---- removed 90 days- ask shilpa why it was there
  }
  */

  public async fetchTags(engagementId?: string) {
    try {
      var parameter = engagementId ?? '';
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTags?engagementId=${parameter}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async updateTopicsTags(tagging: ITag) {
    try {
      await axios.put(`${this.KOL_Api_Url}/updateTopicsTags`, {
        Id: tagging.Id,
        Brand_Focus__c: tagging.Brand_Focus__c,
        Disease_Focus__c: tagging.Disease_Focus__c,
        Takeda_Insight__c: tagging.Takeda_Insight__c,
        No_Topics_to_Note__c: tagging.No_Topics_to_Note__c
      });
      return "Success";
    } catch (error) {
      console.error("Error updating topics:", error);
      return "Error";
    }
    // try {
    //   await this.conn.sobject("Master_Enagement__c").update({
    //     Id: tagging.Id,
    //     Brand_Focus__c: tagging.Brand_Focus__c,
    //     Disease_Focus__c: tagging.Disease_Focus__c,
    //     Takeda_Insight__c: tagging.Takeda_Insight__c,
    //     No_Topics_to_Note__c: tagging.No_Topics_to_Note__c,
    //   });
    //   return "Success";
    // } catch {
    //   return "Error";
    // }
  }

  // KOL SEARCH PAGE START HERE --------------------------------------------------------------------------------------

  // public async fetchFavorites(userId?: string, formSelect?: any) {
  //   console.log('from fetchFavorites');

  //       return this.conn.query(`
  //                       SELECT 
  //                         Employee__c, Employee__r.Employee__c, Favorite_KOL__c, CreatedById
  //                       FROM Favorite__c 
  //                         ${
  //                           userId
  //                             ? "WHERE Employee__r.Employee__c ='" +
  //                               userId +
  //                               "'"
  //                             : ""
  //                         }
  //                         ${
  //                           formSelect
  //                             ? "AND Master_Engagement__r.Congress__c = '" +
  //                               formSelect.slice(0, -3) +
  //                               "'"
  //                             : ""
  //                         }
  //                       `);
  // }

  public async fetchFavorites(userId?: string, formSelect?: any) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchFavorites?userId=${userId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetch Favorites:", error);
      throw error;
    }
  }

  public async postFavorites(
    profileId: string,
    employeeId: string,
    isFav: boolean
  ) {

    console.log('public async postFavorites(', profileId, employeeId, isFav)
    try {
      const response = await axios.post(`${this.KOL_Api_Url}/postFavorites?isFav=${isFav}&profileId=${profileId}&ownerId=${this.KOL_UserID}&employeeId=${employeeId ?? ''}`
      );
      return response.data;
    } catch (error) {
      console.error("Error postFavorites", error);
      throw error;
    }
    // if (isFav) {
    //   await this.conn.sobject("Favorite__c").create(
    //     {
    //       Favorite_KOL__c: profileId,
    //       Employee__c: employeeId,
    //     },
    //     (err) => {
    //       console.log("DB Error While positing", err);
    //     }
    //   );
    // } else {
    //   await this.conn
    //     .sobject("Favorite__c")
    //     .find({
    //       Favorite_KOL__c: profileId,
    //       Employee__c: employeeId,
    //     })
    //     .destroy();
    // }
  }

  public async postFavoritesNew(
    profileId: string,
    employeeId: string,
    isFav: boolean
  ) {
    try {
      const response = await axios.post(`${this.KOL_Api_Url}/postFavoritesNew?isFav=${isFav}&profileId=${profileId}&ownerId=${this.KOL_UserID}&employeeId=${employeeId ?? ''}`
      );
      return response.data;
    } catch (error) {
      console.error("Error postFavorites", error);
      throw error;
    }
    // if (isFav) {
    //   await this.conn.sobject("Favorite__c").create(
    //     {
    //       Favorite_KOL__c: profileId,
    //       Employee__c: employeeId,
    //     },
    //     (err) => {
    //       console.log("DB Error While positing", err);
    //     }
    //   );
    // } else {
    //   await this.conn
    //     .sobject("Favorite__c")
    //     .find({
    //       Favorite_KOL__c: profileId,
    //       Employee__c: employeeId,
    //     })
    //     .destroy();
    // }
  }

  public async searchKOLProfiles(query: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/searchKOLProfiles?KOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}&search=${query}`);
      return response.data;
    } catch (error) {
      console.error("Error searchKOLProfiles:", error);
      throw error;
    }
    // const accountFields = [
    //   "Id",
    //   "Name",
    //   "Geolocation__c",
    //   "Profile_Image_Large__c",
    //   "Degree__pc",
    //   "Primary_Title_Affiliation__pc",
    //   "Primary_Title_City_State__pc",
    // ];

    // return (this.conn as any).search(
    //   `FIND {${query}} IN ALL FIELDS 
    //   RETURNING Account(
    //     ${accountFields.join(", ")}
    //     WHERE Id IN (
    //       SELECT Expert_Account__c 
    //       FROM Expert__c 
    //       WHERE KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'))`
    // );

  }

  // backup query

  // Expert_Account__r.KOL_Classification__c, Expert_Account__r.Primary_Title_Position__pc, Expert_Account__r.Id, Expert_Account__r.Profile_Image_Large__c,
  //   Expert_Account__r.Name, Expert_Account__r.Primary_Title_Affiliation__pc,
  //   Expert_Account__r.Primary_Title_City_State__pc, Expert_Account__r.Specialty__c,
  //   Expert_Account__r.PersonEmail, Expert_Account__r.Phone,
  //   Expert_Account__r.Region__c, Expert_Account__r.Name_of_RBM__pc,
  //   Expert_Account__r.LastName, Expert_Account__r.Degree__pc,
  //   Expert_Account__r.Name_of_RBD__pc, Expert_Account__r.Institutional_restriction_comments__pc,
  //   Expert_Account__r.AAL_Territory__c,
  //   Expert_Account__r.Clinical_Trial_Experience_Other__pc,
  //   Expert_Account__r.Clinical_Trial_Experience_Takeda__pc,
  //   Expert_Account__r.Speaking_Experience__pc, Expert_Account__r.Is_Shire_GATTEX_Speaker__c,
  //   Expert_Account__r.Clinical_Interests__pc, Expert_Account__r.Speaking_interests__pc,
  //   Expert_Account__r.Content_Interests__pc, Expert_Account__r.Consulting_Interests__pc,
  //   Expert_Account__r.Research_Interests__pc, Expert_Account__r.Other__c, Expert_Account__r.Name_of_FME__pc,
  //   Expert_Account__r.Name_of_KAM__pc, Expert_Account__r.Name_of_MSL__pc,
  //   Expert_Account__r.Current_Speaker_for_Biogen__c,
  //   Expert_Account__r.Name_of_RCD__pc,
  //   Name, Leader_Rank_Pediatric__c

  public async fetchKOLProfiles(
    filters?: { [key: string]: any },
    formSelect?: any
  );
  public async fetchKOLProfiles(filters?: string, formSelect?: any);
  public async fetchKOLProfiles(filters?: any, formSelect?: any) {
    console.log("from fetchKOLProfiles");
    console.log("filtersfilters", filters);

    // const fields = `Expert_Account__r.KOL_Classification__c, Expert_Account__r.Primary_Title_Position__pc, Expert_Account__r.Id, Expert_Account__r.Profile_Image_Large__c,
    // Expert_Account__r.Name, Expert_Account__r.Primary_Title_Affiliation__pc,
    // Expert_Account__r.NPI_Number__pc,
    // Expert_Account__r.Primary_Title_City_State__pc, Expert_Account__r.Specialty__c,
    // Expert_Account__r.PersonEmail, Expert_Account__r.Phone,
    // Expert_Account__r.Region__c, Expert_Account__r.Name_of_RBM__pc,
    // Expert_Account__r.LastName, Expert_Account__r.Degree__pc,
    // Expert_Account__r.Name_of_RBD__pc, Expert_Account__r.Institutional_restriction_comments__pc,
    //  Expert_Account__r.PersonMailingState, Expert_Account__r.TLL_Territory__pc,
    // Expert_Account__r.AAL_Territory__c,
    // Expert_Account__r.Clinical_Trial_Experience_Other__pc,
    // Expert_Account__r.Clinical_Trial_Experience_Takeda__pc,
    // Expert_Account__r.Geolocation__c,
    // Expert_Account__r.Speaking_Experience__pc, Expert_Account__r.Is_Shire_GATTEX_Speaker__c,
    // Expert_Account__r.Clinical_Interests__pc, Expert_Account__r.Speaking_interests__pc,
    // Expert_Account__r.Content_Interests__pc, Expert_Account__r.Consulting_Interests__pc,
    // Expert_Account__r.Research_Interests__pc, Expert_Account__r.Other__c, Expert_Account__r.Name_of_FME__pc,
    // Expert_Account__r.Name_of_KAM__pc, Expert_Account__r.Name_of_MSL__pc,
    // Expert_Account__r.Current_Speaker_for_Biogen__c,
    // Expert_Account__r.RCD_region__pc, Expert_Account__r.Name_of_RCD__pc,
    // Expert_Account__r.Bio_Download_URL__c,
    // Name, Leader_Rank_Pediatric__c, Clinical_Score__c, Congress_Score__c, Leadership_Score__c,
    // Overall_Score__c, Other_Score__c,Publications_Score__c, Tier__c, Leader_Rank__c,
    // Leader_Rank_Adult__c, Leader_Rank_AHP__c, Physician_Profile_Id__c, Congress_Portal_Related_Contact__c`;
    // if (filters && typeof filters === "object") {
    //   return this.conn.sobject("Expert__c").find(
    //     {
    //       KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
    //       ...filters,
    //     },
    //     `${fields}`
    //   );
    // }

    // console.log('checking query');
    // console.log(`
    //   SELECT
    //    ${fields}
    //   FROM
    //       Expert__c
    //   WHERE
    //     KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
    //     ${
    //       formSelect
    //         ? "AND Master_Engagement__r.Congress__c = '" +
    //           formSelect.slice(0, -3) +
    //           "'"
    //         : ""
    //     }
    //     ${filters ? "AND Expert_Account__r.Id ='" + filters + "'" : ""}
    // `);

    // return this.conn.query(`
    //   SELECT
    //    ${fields}
    //   FROM
    //       Expert__c
    //   WHERE
    //     KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
    //     ${
    //       formSelect
    //         ? "AND Master_Engagement__r.Congress__c = '" +
    //           formSelect.slice(0, -3) +
    //           "'"
    //         : ""
    //     }
    //     ${filters ? "AND Expert_Account__r.Id ='" + filters + "'" : ""}
    // `);

    // return this.conn
    //   .query(`SELECT Id, Primary_Title_Position__pc,JobTitle__c,  Profile_Image_Large__c,
    //       Name, Primary_Title_Affiliation__pc,
    //       Primary_Title_City_State__pc, BillingLatitude, BillingLongitude, Specialty__c,
    //       NPI_Number__pc,
    //       PersonEmail, Phone,
    //       Region__c, Name_of_RBM__pc,FirstName,
    //       LastName, Degree__pc,
    //       Name_of_RBD__pc, Institutional_restriction_comments__pc,
    //       PersonMailingState, TLL_Territory__pc,
    //       AAL_Territory__c,
    //       Clinical_Trial_Experience_Other__pc,
    //       Clinical_Trial_Experience_Takeda__pc,
    //       Geolocation__c,
    //       Speaking_Experience__pc, Is_Shire_GATTEX_Speaker__c,
    //       Clinical_Interests__pc, Speaking_interests__pc,
    //       Content_Interests__pc, Consulting_Interests__pc,
    //       Research_Interests__pc, Other__c, Name_of_FME__pc,
    //       Name_of_KAM__pc, Name_of_MSL__pc,
    //       Current_Speaker_for_Biogen__c,
    //       RCD_region__pc, Name_of_RCD__pc,
    //       Bio_Download_URL__c,
    //       (Select Name, Leader_Rank_Pediatric__c, Clinical_Score__c, Congress_Score__c, Leadership_Score__c,
    //       Overall_Score__c, Other_Score__c,Publications_Score__c, Tier__c, Leader_Rank__c,
    //       Leader_Rank_Adult__c, Leader_Rank_AHP__c, Physician_Profile_Id__c, Congress_Portal_Related_Contact__c From Experts__r), 
    //       (Select KOL__c, Brand_Master__r.Name,  Brand_Master__r.Color__c, Region__c, Clinical_Interests__c, Clinical_Trials_Experience__c, 
    //       Known_Engagement_Interests__c, Speaking_Experience__c, Strategic_Imperatives_Commercial__c, Strategic_Imperatives_Medical__c, 
    //       Advocacy_Score_1__c,Advocacy_Scores__c, TLL_Name__c, Classification__c, TLL2_Name__c, Advocacy_Label_1__c, Advocacy_Label_2__c, Advocacy_Score_2__c, Tier__c, MSL_Name__c ,MSL2_Name__c, OverallScore__c, Impact_Score__c,  KnowledgeIndex__c, SphereInfluence__c, EngagementIndex__c from KOL_Brands__r),
    //       (Select Association_Type__c, Speciality__c, Degree__c, Institution_Name__c, Institution__r.BillingLatitude, Institution__r.BillingLongitude from Institution__r 
    //       where KOL__r.KOL_Profile_Portal__pr.Name = 'Janssen KOL Portal' 
    //       and Association_Type__c IN ('Affiliations', 'Fellowships', 'Residency', 'Employment', 'Degrees', 'Organization & Society')) 
    //       from Account where ID = '${filters}'
    //     `);

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKOLProfiles?id=${filters}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchKOLProfiles", error);
      throw error;
    }
  }

  // public async fetchProfilesData(queryParams: string) {
  //   return this.conn.query(
  //     `SELECT Id, Profile_Image_Large__c,
  //       Name, Primary_Title_Affiliation__pc,
  //       Primary_Title_City_State__pc, Specialty__c,
  //       PersonEmail, Phone,
  //       Region__c, Name_of_RBM__pc,
  //       LastName, Degree__pc,
  //       Name_of_RBD__pc, Institutional_restriction_comments__pc,
  //       PersonMailingState, TLL_Territory__pc,
  //       AAL_Territory__c,
  //       Clinical_Trial_Experience_Other__pc,
  //       Clinical_Trial_Experience_Takeda__pc,
  //       Geolocation__c,
  //       Speaking_Experience__pc, Is_Shire_GATTEX_Speaker__c,
  //       Clinical_Interests__pc, Speaking_interests__pc,
  //       Content_Interests__pc, Consulting_Interests__pc,
  //       Research_Interests__pc, Other__c, Name_of_FME__pc,
  //       Name_of_KAM__pc, Name_of_MSL__pc,
  //       Current_Speaker_for_Biogen__c,
  //       RCD_region__pc, Name_of_RCD__pc,
  //       Bio_Download_URL__c,
  //       (Select Name, Leader_Rank_Pediatric__c, Clinical_Score__c, Congress_Score__c, Leadership_Score__c,
  //       Overall_Score__c, Other_Score__c,Publications_Score__c, Tier__c, Leader_Rank__c,
  //       Leader_Rank_Adult__c, Leader_Rank_AHP__c, Physician_Profile_Id__c, Congress_Portal_Related_Contact__c From Experts__r), (Select Brand__c from KOL_Brands__r)
  //       from Account where ID IN (Select Expert_Account__c from Expert__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}') AND ID IN (Select KOL__c from KOL_Brand__c where ${queryParams})
  //     `)
  // }

  public async fetchProfilesDataNetwork(brands?: any[]): Promise<any> {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchProfilesDataNetwork?KOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}&Brand_Master_Names=${brands}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchProfilesDatesNetwork:", error);
      throw error;
    }
  }

  // public async fetchProfilesDataNetwork(queryParams: string, companycheck: string) {
  //   console.log('fetchProfilesDataNetwork', 'here')
  //   // if (queryParams.length !== 0) {
  //     if (companycheck == "PCYC") {
  //       console.log('fetchProfilesDataNetwork 1')
  //       return this.conn.query(
  //         `SELECT Id, 
  //       Primary_Title_Position__pc, 
  //       JobTitle__c,
  //       Profile_Image_Large__c,
  //       Name, 
  //       Primary_Title_Affiliation__pc,
  //       Primary_Title_City_State__pc,
  //       BillingLatitude, 
  //       BillingLongitude, 
  //       Specialty__c,
  //       PersonEmail,
  //       Phone,
  //       Region__c,
  //       Name_of_RBM__pc,
  //       LastName, 
  //       Degree__pc,
  //       Name_of_RBD__pc, 
  //       Institutional_restriction_comments__pc,
  //       PersonMailingState, 
  //       TLL_Territory__pc,
  //       AAL_Territory__c,
  //       Clinical_Trial_Experience_Other__pc,
  //       Clinical_Trial_Experience_Takeda__pc,
  //       Geolocation__c,
  //       Speaking_Experience__pc, 
  //       Is_Shire_GATTEX_Speaker__c,
  //       Clinical_Interests__pc, 
  //       Speaking_interests__pc,
  //       Content_Interests__pc, 
  //       Consulting_Interests__pc,
  //       Research_Interests__pc, 
  //       Other__c, 
  //       Name_of_FME__pc,
  //       Name_of_KAM__pc, 
  //       Name_of_MSL__pc,
  //       Current_Speaker_for_Biogen__c,
  //       RCD_region__pc, 
  //       Name_of_RCD__pc,
  //       Bio_Download_URL__c,
  //         (Select
  //           Name, 
  //           Leader_Rank_Pediatric__c, 
  //           Clinical_Score__c, 
  //           Congress_Score__c, 
  //           Leadership_Score__c,
  //           Overall_Score__c,
  //           Other_Score__c,
  //           Publications_Score__c, 
  //           Tier__c, 
  //           Leader_Rank__c,
  //           Leader_Rank_Adult__c, 
  //           Leader_Rank_AHP__c, 
  //           Physician_Profile_Id__c, 
  //           Congress_Portal_Related_Contact__c 
  //             From Experts__r), 
  //         (Select KOL__c, 
  //           Brand_Master__r.Name, 
  //           Brand_Master__r.Color__c, 
  //           Region__c, 
  //           Clinical_Interests__c, 
  //           Clinical_Trials_Experience__c, 
  //           Known_Engagement_Interests__c, 
  //           Speaking_Experience__c, 
  //           Strategic_Imperatives_Commercial__c, 
  //           Strategic_Imperatives_Medical__c, 
  //           Advocacy_Scores__c, 
  //           Classification__c 
  //             from KOL_Brands__r where Brand_Master__r.Name = '${BRAND1}')
  //       from Account where ID IN (Select Expert_Account__c from Expert__c where
  //       KOL_Profile_Portal__r.Name = 'Janssen KOL Portal') 
  //       AND ID IN (Select KOL__c from KOL_Brand__c where Brand_Master__r.Name = '${BRAND1}') AND Write_In_KOL__pc = false
  //     `
  //       );
  //     } else {
  //       console.log('fetchProfilesDataNetwork 2')

  //       // Meetings Expert_Name
  //       return this.conn.query(
  //         `SELECT Id, Primary_Title_Position__pc, JobTitle__c,  Profile_Image_Large__c,
  //         Name, Primary_Title_Affiliation__pc,
  //         Primary_Title_City_State__pc, BillingLatitude, BillingLongitude, Specialty__c,
  //         PersonEmail, Phone,
  //         Region__c, Name_of_RBM__pc,
  //         LastName, Degree__pc,
  //         Name_of_RBD__pc, Institutional_restriction_comments__pc,
  //         PersonMailingState, TLL_Territory__pc,
  //         AAL_Territory__c,
  //         Clinical_Trial_Experience_Other__pc,
  //         Clinical_Trial_Experience_Takeda__pc,
  //         Geolocation__c,
  //         Speaking_Experience__pc, Is_Shire_GATTEX_Speaker__c,
  //         Clinical_Interests__pc, Speaking_interests__pc,
  //         Content_Interests__pc, Consulting_Interests__pc,
  //         Research_Interests__pc, Other__c, Name_of_FME__pc,
  //         Name_of_KAM__pc, Name_of_MSL__pc,
  //         Current_Speaker_for_Biogen__c,
  //         RCD_region__pc, Name_of_RCD__pc,
  //         Bio_Download_URL__c,
  //         (Select Name, Leader_Rank_Pediatric__c, Clinical_Score__c, Congress_Score__c, Leadership_Score__c,
  //         Overall_Score__c, Other_Score__c,Publications_Score__c, Tier__c, Leader_Rank__c,
  //         Leader_Rank_Adult__c, Leader_Rank_AHP__c, Physician_Profile_Id__c, Congress_Portal_Related_Contact__c From Experts__r), 
  //         (Select KOL__c, Brand_Master__r.Name,  Brand_Master__r.Color__c, Region__c, Clinical_Interests__c, Clinical_Trials_Experience__c, 
  //         Known_Engagement_Interests__c, Speaking_Experience__c, Strategic_Imperatives_Commercial__c, Strategic_Imperatives_Medical__c, 
  //         Advocacy_Scores__c, Classification__c from KOL_Brands__r)
  //         from Account where ID IN (Select Expert_Account__c from Expert__c where
  //         KOL_Profile_Portal__r.Name = 'Janssen KOL Portal') AND ID IN (Select KOL__c from KOL_Brand__c where ${queryParams}) AND Write_In_KOL__pc = false
  //         `
  //       );
  //     }
  //   // }
  // }

  public async fetchProfilesData(queryParams: string[], companycheck: string) {
    try {
      if (queryParams.length !== 0) {
        if (companycheck == "PCYC") {
          const response = await axios.put(
            `${this.KOL_Api_Url}/fetchProfilesData?KOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}`,
            [BRAND1]
          );
          return response.data;
        } else {
          const response = await axios.put(
            `${this.KOL_Api_Url}/fetchProfilesData?KOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}`,
            queryParams
          );
          return response.data;
        }
      }
    } catch (error) {
      console.error("Error fetching total engagements:", error);
      throw error;
    }
  }

  public async fetchEmployeeFavorites(userId?: string) {
    // return this.conn.query(`
    // Select Id, Favorite_KOL__r.Name from Favorite__c where
    //  Employee__r.Employee__c = '${userId}' and
    //  Employee__r.KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
    // `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEmployeeFavorites`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchEmployeeFavorites:", error);
      throw error;
    } 
  }

  public async fetchTotalEngagements(profileId?: string, formSelect?: string) {
    try {
      let url = `${this.KOL_Api_Url}/fetchTotalEngagements?profilePotal=${this.KOL_Profile_Portal__pc}`;

      if (profileId) url += `&profileId=${profileId}`;

      if (formSelect) url += `&formSelect=${formSelect}`;

      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error("Error fetching total engagements:", error);
      throw error;
    }
  }

  public async fetchTotalMeetings(profileId?: string, formSelect?: any) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTotalMeetings/${this.KOL_Profile_Portal__pc}?profileId=${profileId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchTotalMeetings", error);
      throw error;
    }
    // return this.conn.query(`
    //               SELECT 
    //                 KOLAccount__r.Id, count(id) Meetings 
    //               FROM 
    //                 KOL_Engagement__c 
    //               WHERE 
    //                 Master_Engagement__r.KOL_Profile_Portal__c = '${
    //                   this.KOL_Profile_Portal__pc
    //                 }' 
    //                 ${
    //                   profileId
    //                     ? "AND KOLAccount__r.Id ='" + profileId + "'"
    //                     : ""
    //                 }
    //                 ${
    //                   formSelect
    //                     ? "AND Master_Engagement__r.Congress__c = '" +
    //                       formSelect.slice(0, -3) +
    //                       "'"
    //                     : ""
    //                 }
    //                 AND Attendee_Type__c != 'Employee' 
    //                 AND Master_Engagement__r.Name like '%1:1%'
    //               Group by  
    //                 KOLAccount__r.Id
    //                 `);
  }

  /*
  public async fetchTotalEvents(profileId?: string, formSelect?: any) {
            return this.conn.query(`
                        SELECT 
                          KOLAccount__r.Id, count(id) Events 
                        FROM 
                          KOL_Engagement__c 
                        WHERE 
                          Master_Engagement__r.KOL_Profile_Portal__c = '${
                            this.KOL_Profile_Portal__pc
                          }' 
                          ${
                            profileId
                              ? "AND KOLAccount__r.Id ='" + profileId + "'"
                              : ""
                          }
                          ${
                            formSelect
                              ? "AND Master_Engagement__r.Congress__c = '" +
                                formSelect.slice(0, -3) +
                                "'"
                              : ""
                          }
                          AND Attendee_Type__c != 'Employee' 
                          AND Engagement_Type__c != '1:1 Meeting' 
                          AND Engagement_Type__c != '1:1 Engagement' 
                        Group by  
                          KOLAccount__r.Id
                        `);
  }
  */

  public async fetchTotalEvents(profileId?: string, formSelect?: any) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTotalEvents?profileId=${profileId}&formSelect=${formSelect}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async fetchMedia(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchMedia?profileId=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching media:", error);
      throw error;
    }
  }

  public async fetchCongressActivity(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCongressActivity?profileId=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchCongressActivity", error);
      throw error;
    }
    // return this.conn.query(`
    //       SELECT 
    //         Id, Meeting__c,
    //         Contact__r.Name, Contact__r.SFID_18_Digit__c, 
    //         Meeting__r.Cong_Id__c, Meeting__r.Start_Time_Full__c, Meeting__r.End_Time_Full__c,
    //         Meeting__r.timezone_short__c, Meeting__r.Location__c, Meeting__r.Type__c,Type__c 
    //       FROM Meeting_Attendee__c 
    //       Where Type__c = 'Key Opinion Leader' 
    //         ${
    //           profileId
    //             ? "AND Contact__r.SFID_18_Digit__c ='" + profileId + "'"
    //             : ""
    //         }
    //       `);
  }

  public async fetchBiography(profileId?: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchBiography/${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchBiography:", error);
      throw error;
    }
    // return this.conn.query(`
    //         Select Id, Bio__c from Account where ID = '${profileId}'
    //         `);
  }

  public async UploadCV(bodyString, fileName, RecordID) {
    try {
      var data = {
        body: bodyString,
        name: fileName,
        parentId: RecordID,
      };
      const response = await axios.post(`${this.KOL_Api_Url}/uploadCV`, data);
      return response.data;
    } catch (error) {
      console.error("Error uploading CV:", error);
      throw error;
    }
  }

  public async DownloadCV(RecordID) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/DownloadCV/${RecordID}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching DownloadCV:", error);
      throw error;
    }
    /*  
    return this.conn.query(`
    Select Name, Body, CreatedDate from Attachment Where ParentId = '${RecordID}' ORDER BY CreatedDate DESC
            `);
    */
  }

  // public async fetchAssociations(profileId?: string, associationType?: string) {
  //   return this.conn.query(`

  //     SELECT Association_End_Date__c, Association_Name__c, Association_Start_Date__c, Association_Type__c, Award_Name__c, Certification__c, Current__c, Degree__c, Department__c, Designation__c, Details__c, Employer__c, End_Date__c, End_Year__c,  Free_text__c, Institution_Name__c, Institution__c, Journal__c, KOL_Account__c, KOL__c, License__c, Position__c, Speciality__c, Start_Date__c, Start_Year__c, Year_began__c, Year_Completed__c, Year__c

  //     FROM Association__c

  //     Where KOL__c = '${profileId}'
  //                       `);
  // }

  public async fetchAssociations(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchAssociations?profileId=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching permissions:", error);
      throw error;
    }
  }

  // public async fetchSphereOfInfluence(profileId?: string,) {
  // console.log('from fetchSphereOfInfluence');

  // return this.conn.query(`
  //   SELECT  
  //     Association_Type__c, 
  //     SphereInfluence__c
  //   FROM Association__c 
  //   Where KOL__c = '${profileId}' and Association_Type__c = 'Sphere Influence'`);
  // }

  public async fetchSphereOfInfluence(profileId?: string,) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchSphereOfInfluence?profileId=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetch SphereOfInfluence:", error);
      throw error;
    }
  }

  public async fetchResearh(profileId?: string) {
    // return this.conn.query(`
    //                       SELECT 
    //                         Program__r.Name, Program__r.Slide_Deck_Title_Formula__c, Program__r.Program_Format__c, 
    //                         Program__r.Preferred_Confirmed_Start_Date__c, Program__r.Program_Venue_City_State__c, 
    //                         Program__r.Num_of_Attended_Members__c 
    //                       FROM Program_Speaker__c 
    //                       WHERE  
    //                         Contact__r.Account.Is_Shire_GATTEX_Speaker__c = True
    //                         AND Program__r.Preferred_Confirmed_Start_Date__c >= 2020-01-01
    //                         ${profileId
    //     ? "AND Contact__r.AccountId ='" +
    //     profileId +
    //     "'"
    //     : ""
    //   }
    //                     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchResearh`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchResearh:", error);
      throw error;
    } 
  }

  // public async fetchTopConnectionsInstitutions(profileId?: string) {    
  //   return this.conn.query(`
  //                           SELECT  
  //                             Institution__c, Institution__r.Institution_Geolocation__c
  //                           FROM Association__c 
  //                           Where
  //                          KOL__c = '${profileId}'
  //                         `);
  // }

  public async fetchTopConnectionsInstitutions(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTopConnectionsInstitutions/${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching TopConnectionsInstitutions", error);
      throw error;
    }
  }

  // public async getInstitutions(profileId: string, filters?: any) {
  //   return this.conn.sobject(`Association__c`).find(
  //     {
  //       $not: { Institution__c: null },
  //       "KOL__r.KOL_Profile_Portal__pc": this.KOL_Profile_Portal__pc,
  //       "KOL__r.Tier__pc": {
  //         $in: ["1", "2"],
  //       },
  //       KOL__c: profileId,
  //       ...filters,
  //     },
  //     [
  //       `Institution__c`,
  //       `Association_Type__c`,
  //       `Institution__r.Id`,
  //       `Institution__r.Name`,
  //       `Institution__r.Institution_Geolocation__c`,
  //     ]
  //   );
  // }

  public async getInstitutions(profileId: string, filters?: any) {
    console.log("filters>>>>", filters)
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getInstitutions/${profileId}/${this.KOL_Portal_Name}`,
        filters);
      return response.data;
    } catch (error) {
      console.error("Error getInstitutions:", error);
      throw error;
    }
  }

  public async getTop5Institutions(FFS__c?: boolean) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTop5Institutions?KOL_Portal_Name=${this.KOL_Portal_Name}&FFS__cValue=${FFS__c}`);
      return response.data;
    } catch (error) {
      console.error("Error getInstitutions:", error);
      throw error;
    }
  }

  public async getConnections(profileId: string, filters?: any) {
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getConnections/${profileId}/${this.KOL_Profile_Portal__pc}`,
        filters);
      return response.data;
    } catch (error) {
      console.error("Error getInstitutions:", error);
      throw error;
    }
    // return this.conn
    //   .sobject(`Association__c`)
    //   .find(
    //     {
    //       "KOL__r.KOL_Profile_Portal__pc": this.KOL_Profile_Portal__pc,
    //       $and: [
    //         { $not: { KOL__c: profileId } },
    //         { $not: { Institution__c: null } },
    //       ],
    //       ...filters,
    //     },
    //     [
    //       `Association_Type__c`,
    //       `KOL__r.Id`,
    //       `KOL__r.Profile_Image_Large__c`,
    //       `KOL__r.Name`,
    //       `KOL__r.Primary_Title_Affiliation__pc`,
    //       `KOL__r.Primary_Title_City_State__pc`,
    //       `KOL__r.Name_of_RBM__pc`,
    //       `KOL__r.Name_of_RBD__pc`,
    //       `KOL__r.BillingLatitude`, 
    //       `KOL__r.BillingLongitude`
    //     ]
    //   )
    //   .sort("KOL__r.Name");

  }

  public async fetchMeetings(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchMeetings?profileId=${profileId}&profilePortal=${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching meetings", error);
      throw error;
    }
  }


  public async fetchToolTipMeetings(MasterEngagementIds: string[]) {
    console.log('from fetchToolTipMeetings');

    // return this.conn.query(`
    //             SELECT 
    //               KOLAccount__r.Name, Master_Engagement__c 
    //             FROM KOL_Engagement__c 
    //             WHERE 
    //               KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
    //               AND Master_Engagement__c IN ('${MasterEngagementIds.join(
    //                 "','"
    //               )}') 
    //               AND Attendee_Type__c = 'Employee'
    //         `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchToolTipMeetings?KOL_Profile_Portal__pc=${this.KOL_Profile_Portal__pc}&MasterEngagementIds=${MasterEngagementIds}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching ToolTipMeetings", error);
      throw error;
    }
  }

  public async fetchEvents(profileId?: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEvents?profileId=${profileId}&KOL_Profile_Portal__pc=${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchEvents", error);
      throw error;
    }
    // return this.conn.query(`
    //                       SELECT 
    //                         Master_Engagement__r.Engagement_Date__c,Master_Engagement__r.Name,
    //                         Master_Engagement__r.Type__c, Master_Engagement__r.Id, 
    //                         Master_Engagement__r.Start_Date_Time__c,
    //                         Attendee_Type__c,
    //                         KOLAccount__r.Name
    //                       FROM KOL_Engagement__c 
    //                       WHERE 
    //                         KOL_Profile_Portal__c = '${
    //                           this.KOL_Profile_Portal__pc
    //                         }' 
    //                         AND Master_Engagement__r.Type__c != '1:1 Meeting' 
    //                         AND Master_Engagement__r.Type__c != '1:1 Engagement' 
    //                         AND (Master_Engagement__r.Engagement_Date__c = NEXT_N_DAYS:365 OR Master_Engagement__r.Engagement_Date__c = LAST_90_DAYS)
    //                         ${
    //                           profileId
    //                             ? "AND KOLAccount__c ='" + profileId + "'"
    //                             : ""
    //                         }
    //                         ORDER BY Master_Engagement__r.Engagement_Date__c
    //                       `);
  }

  public async fetchTopConnections(institutions: string[]) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTopConnections?institutions=${institutions}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchTopConnections:", error);
      throw error;
    }

    /*
    const institutionsStr = "'" + institutions.join("','") + "'";
    return this.conn.query(`
                        Select KOL__r.Id, KOL__r.Profile_Image_Large__c,KOL__r.Name, KOL__r.Primary_Title_Affiliation__pc,
                          KOL__r.Primary_Title_City_State__pc, KOL__r.Name_of_RBM__pc, KOL__r.Name_of_RBD__pc,

                          Count(Institution__c) ConnectionsCount FROM Association__c

                        WHERE Institution__c IN (${institutionsStr})  
                        GROUP BY

                          KOL__r.Id, KOL__r.Profile_Image_Large__c,KOL__r.Name,

                          KOL__r.Primary_Title_Affiliation__pc, KOL__r.Primary_Title_City_State__pc,

                          KOL__r.Name_of_RBM__pc, KOL__r.Name_of_RBD__pc

                        ORDER BY Count(Institution__c) desc
                        `);
    // ${profileId ? 'AND KOL__c =\'' + profileId + '\'' : ''}
    */
  }

  public async fetchNotes(profileId?: string, Division__c: string = '') {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchNotes?profileId=${profileId}&division=${Division__c}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching notes", error);
      throw error;
    }
  }

  public async postNotes(note: INote) {
    var postNoteData = {
      KOL__c: note.KOL__c,
      Title__c: note.Title__c,
      Body__c: note.Body__c,
      Division__c: note.Division__c,
      OwnerId: this.KOL_UserID
    };

    const postNoteDataResponse = await axios.post(`${this.KOL_Api_Url}/postNotes`, postNoteData);

    if (note.Division__c == "Admin") {
      return await this.fetchNotes(note.KOL__c);
    } else if (
      note.Division__c == "Commercial" ||
      note.Division__c == "Medical"
    ) {
      return await this.fetchNotes(note.KOL__c, note.Division__c);
    }
  }

  public async postNotesInsitution(note: INote) {
    console.log("profile", note.KOL__c);
    var postNoteData = {
      KOL__c: note.KOL__c,
      Title__c: note.Title__c,
      Body__c: note.Body__c,
      Division__c: note.Division__c,
      Institution_Note__c: true,
      Care_Team_Note__c: false,
      OwnerId: this.KOL_UserID
    };

    const postNoteDataResponse = await axios.post(`${this.KOL_Api_Url}/postNotesInsitution`, postNoteData);

    if (note.Division__c == "Admin") {
      return await this.fetchNotesInsitution(note.KOL__c);
    } else if (
      note.Division__c == "Commercial" ||
      note.Division__c == "Medical"
    ) {
      return await this.fetchNotesInsitution(note.KOL__c, note.Division__c);
    }
  }

  async fetchNotesCares(profileId: string, Division__c: string = '') {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchNotesCares?profileId=${profileId}&?division=${Division__c}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching notes", error);
      throw error;
    }
  }

  async fetchNotesInsitution(profileId: string, Division__c: string = '') {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchNotesInsitution?profileId=${profileId}&division=${Division__c}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching notes", error);
      throw error;
    }
  }

  public async postNotesCare(note: INote) {
    var noteData =
    {
      KOL__c: note.KOL__c,
      Title__c: note.Title__c,
      Body__c: note.Body__c,
      Division__c: note.Division__c,
      Institution_Note__c: false,
      Care_Team_Note__c: true,
      OwnerId: this.KOL_UserID
    };

    const response = await axios.post(`${this.KOL_Api_Url}/postNotesCare?profileId=${note.KOL__c}&?division=${note.Division__c}`, noteData);

    if (note.Division__c == "Admin") {
      return await this.fetchNotesCares(note.KOL__c);
    } else if (
      note.Division__c == "Commercial" ||
      note.Division__c == "Medical"
    ) {
      return await this.fetchNotesCares(note.KOL__c, note.Division__c);
    }
  }

  public async deleteNotes0(Id: string, profileId: string, division: string) {
    const response = await axios.post(`${this.KOL_Api_Url}/deleteNotes0?id=${Id}&profileId=${profileId}&division=${division}`);

    if (division == "Admin") {
      return await this.fetchNotes(profileId);
    } else if (division == "Commercial" || division == "Medical") {
      return await this.fetchNotes(profileId, division);
    }
  }

  public async deleteNotes(Id: string, profileId: string, division: string) {
    const response = await axios.post(`${this.KOL_Api_Url}/deleteNotes?id=${Id}&profileId=${profileId}&division=${division}`);


    if (division == "Admin") {
      return await this.fetchInstitutionNotes(profileId);
    } else if (division == "Commercial" || division == "Medical") {
      return await this.fetchInstitutionNotes(profileId, division);
    }
  }

  public async deleteNotes1(
    Id: string,
    profileId: string,
    Division__c: string
  ) {
    const response = await axios.post(`${this.KOL_Api_Url}/deleteNotes?id=${Id}&profileId=${profileId}&division=${Division__c}`);

    if (Division__c == "Admin") {
      return this.fetchCareNotes(profileId);
    } else if (Division__c == "Commercial" || Division__c == "Medical") {
      return this.fetchCareNotes(profileId, Division__c);
    }
  }

  //
  public async postExpereinceInterest(profile: IFlatProfile, flag: boolean) {

    try {
      const response = await axios.put(`${this.KOL_Api_Url}/postExpereinceInterest?flag=${flag}`,
        {
          flatProfile: profile,
          brands: [BRAND1]
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error postExpereinceInterest: ", error);
      throw error;
    }
  }

  public async postProfile(profile: IAddProfile) {
    console.log('from postProfile');

    try {
      let addProfileResult = await axios.post(`${this.KOL_Api_Url}/postProfile?
        firstName=${profile.fname}
        &lastName=${profile.lname}
        &affiliation__pc=${profile.affiliation}
        &personMailingCity=${profile.city}
        &personMailingState=${profile.state}
        &degree__pc=${profile.degree}
        &primary_Title_Position__pc=${profile.title}
        &specialty__c=${profile.speciality}
        &recordTypeId=${this.RecordTypeId}
        &kOL_Profile_Portal__pc=${this.KOL_Profile_Portal__pc}`);
    } catch (error) {
      console.error('error postProfile');
    }
  }

  // KOL SEARCH PAGE END HERE --------------------------------------------------------------------------------------

  // SUPPORT PAGE

  public async fetchSupportAccountManager() {
    // return this.conn.query(`
    //                   SELECT 
    //                     Account_Manager__r.Title, 
    //                      Account_Manager__c,
    //                      Account_Manager__r.Name,
    //                      Account_Manager__r.Email 
    //                     FROM 
    //                       KOL_Profile_Portal__c
    //                     Where
    //                       Name='GATTEX KOL Portal'
    //                     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchSupportAccountManager`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchSupportAccountManager:", error);
      throw error;
    }     
  }

  public async fetchSupportMeetingManager() {
    // return this.conn.query(`
    //                   SELECT 
    //                     Meetings_Manager__c,
    //                     Meetings_Manager__r.Title,
    //                     Meetings_Manager__r.Name,
    //                     Meetings_Manager__r.Email 
    //                   FROM 
    //                     KOL_Profile_Portal__c
    //                   Where
    //                     Name='GATTEX KOL Portal'
    //                     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchSupportMeetingManager`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchSupportMeetingManager:", error);
      throw error;
    } 
  }

  public async postSupportIssue(textAreaValue, user) {
    var todaysDate = new Date().toISOString().slice(0, 10);
    var queryString = ` 
        client_Email__c=${user.Email}
        &description__c=${textAreaValue}
        &eHG_Contact__c=${user.FirstName} ${user.LastName}
        &kOL_Profile_Portal__c=${this.KOL_Profile_Portal__pc}
        &date_of_request__c=${todaysDate}
        &clientEmail=${user.Email}
        &ownerId=${this.KOL_UserID}`;        
    try {
      const response = await axios.post(`${this.KOL_Api_Url}/postSupportIssue?${queryString}`);
      //return response.data;
      return "Success";
    } catch (error) {
      console.error("Error creating support ticket", error);
      throw error;
    }

    // await this.conn.sobject("Support_Requests__c").create({
    //   Client_Email__c: user.Email,
    //   Description__c: textAreaValue,
    //   EHG_Contact__c: "blulavasupport@blulava.com",
    //   KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
    //   Date_of_request__c: todaysDate,
    // });
  }

  public async fetchResourcesMaterial() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchResourcesMaterial`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchResourcesMaterial:", error);
      throw error;
    }
    /*
        return this.conn.query(`
        SELECT 
          Id, 
          Label__c, 
          URL__c, 
          Description__c, 
          Active__c, 
          Icon_Class__c, 
          Name, 
          Video_id__c  
          FROM Resource__c 
          WHERE KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}'
            `);
    */
  }

  // public async fetchKOLsFromMasterEngagement(masterEngagementId?: string) {
  //   //fetch all the kme attendees (hcp & employee) and make them editable
  //   return this.conn.query(`
  //         SELECT 
  //            Name, 
  //            Id,
  //            Attendee_Role__c,
  //            Attendee_Type__c,
  //            Engagement_Date__c,
  //            Engagement_Type__c,
  //            KOLAccount__c,
  //            KOL_Profile_Portal__c,
  //            Master_Engagement__c,
  //            Status__c,
  //            Title__c, 
  //            KOLAccount__r.FirstName,
  //            KOLAccount__r.LastName,
  //            CreatedById 
  //             FROM KOL_Engagement__c
  //           WHERE (Attendee_Type__c = 'Employee' OR Attendee_Type__c = 'HCP')
  //            ${
  //              masterEngagementId
  //                ? "AND Master_Engagement__c ='" + masterEngagementId + "'"
  //                : ""
  //            }
  //           `);
  // }

  public async fetchKOLsFromMasterEngagement(masterEngagementId?: string) {
    //fetch all the kme attendees (hcp & employee) and make them editable    
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKOLsFromMasterEngagement?masterEngagementId=${masterEngagementId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching KOLsFromMasterEngagement:", error);
      throw error;
    }
  }

  public async fetchEngagementsData0(masterEngagementId?: string) {
    // return this.conn.query(`
    // Select Engagement_Type__c,
    //  KOLAccount__c,
    //  KOLAccount__r.Name,
    //   Attendee_Role__c,
    //    Status__c,
    //     Master_Engagement__r.Leadership_Attendee__c,
    //      Master_Engagement__r.Client_Attendee__c,
    //       Master_Engagement__r.Start_Date_Time__c,
    //        Master_Engagement__r.End_Date_Time__c,
    //         Master_Engagement__r.Disease_Focus__c,
    //          Master_Engagement__r.Brand_Focus__c,
    //           Master_Engagement__r.Congress__c,
    //            Master_Engagement__r.Topic__c,
    //             Master_Engagement__r.Engagement_Type__c,
    //              Master_Engagement__r.Status__c,
    //               Master_Engagement__r.Format__c,
    //                Master_Engagement__r.Meal__c,
    //                 Master_Engagement__r.Location_Type__c,
    //                  Master_Engagement__r.Location__c,
    //                   Master_Engagement__r.Notes_Details__c from KOL_Engagement__c where Master_Engagement__c = '${masterEngagementId}'
    // `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEngagementsData0`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchEngagementsData0:", error);
      throw error;
    } 
  }

  public async fetchEngagementsData(masterEngagementId?: string) {
    // return this.conn.query(`
    // Select Id, 

    // Engagement_Date__c,
    // Start_Date_Time__c,
    // End_Date_Time__c,
    // Brand_Master__r.Name,
    // Name,
    // Brand_Focus__c,
    // Disease_Focus__c,
    // Type__c,
    // Topic__c ,
    // Meal__c,
    // Format__c ,
    // Location_Type__c,
    // Congress__c,
    // Location__c,
    // Notes_Details__c,
    // Congress__r.Label__c,
    // Congress__r.Calendar_Default_Date__c,
    // Congress__r.End_Date__c,
    // CreatedBy.Name,
    // CreatedById,
    // Time_Zone__c,
    // Status__c,
    // Engagement_Type__c,
    // (Select Id, Attendee_Role__c, KOLAccount__c, KOLAccount__r.Name, KOLAccount__r.Write_In_KOL__pc, Status__c, KOLAccount__r.Profile_Thumbnail__c from KOL_Engagements__r), (Select  Id, Employee__c,  Attendee_Name__c, Employee__r.Leadership_Attendee__c , Attendee_Role__c from KOL_Attendees__r)
    // From Master_Enagement__c
    // Where ID  = '${masterEngagementId}'
    // `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEngagementsData`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchEngagementsData:", error);
      throw error;
    } 
  }

  public async updateMasterEngagements(engage: IEngagement) {
    //update master engagements, not kols- that'll be seperate
    console.log("*******", engage);
    try {
      await axios.put(`${this.KOL_Api_Url}/updateMasterEngagements`, {
        Id: engage.Id,
        End_Date_Time__c: engage.End_Date_Time__c,
        Engagement_Date__c: engage.Start_Date_Time__c,
        KOL_Profile_Portal__c: `${this.KOL_Profile_Portal__pc}`,
        Location__c: engage.Location__c,
        Notes_Details__c: engage.Notes_Details__c,
        Start_Date_Time__c: engage.Start_Date_Time__c
      });
    } catch (error) {
      console.error("Error updating topics:", error);
      throw error;
    }

    // await this.conn
    //   .sobject("Master_Enagement__c")
    //   .update({
    //     Id: engage.Id,
    //     End_Date_Time__c: engage.End_Date_Time__c,
    //     Engagement_Date__c: engage.Start_Date_Time__c,
    //     KOL_Profile_Portal__c: `${this.KOL_Profile_Portal__pc}`,
    //     Location__c: engage.Location__c,
    //     Notes_Details__c: engage.Notes_Details__c,
    //     Start_Date_Time__c: engage.Start_Date_Time__c,
    //   })
    //   .then((x) => {
    //     console.log("Engagement Updated", x);
    //   });
  }

  public async updateTheKOLstatus(list: IMasterEngagement[]) {
    //update KME STATUS FOR EACH ADDED
    // list.forEach((master: IMasterEngagement) => {
    //   this.conn
    //     .sobject("KOL_Engagement__c")
    //     .update(this.preparePostObject(master))
    //     .then((x) => {
    //       console.log("Status Updated.", x);
    //     });
    // });
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/updateTheKOLstatus`);
      return response.data;
    } catch (error) {
      console.error("Error fetching updateTheKOLstatus:", error);
      throw error;
    } 
  }

  private preparePostObject = (master: IMasterEngagement): any => {
    const postObject = {
      Id: master.Id,
      Master_Engagement__c: master.Master_Engagement__c,
      Status__c: master.Status__c,
    };
    if (master?.Engagement_Type__c !== "1:1 Meeting") {
      postObject["Attendee_Role__c"] = master.Attendee_Role__c;
    }
    return postObject;
  };

  public async updateTheKOLstatusEach(data) {
    //update KME STATUS FOR EACH ADDED
    // const updateKolEngagementStatus =
    // data.forEach((x) => {
    //   this.conn
    //     .sobject("KOL_Engagement__c")
    //     .update({
    //       Id: x.id,
    //       Master_Engagement__c: x.Master_Engagement__c,
    //       Status__c: x.Status__c,
    //     })
    //     .then((x) => {
    //       console.log("Status Updated.", x);
    //     });
    // });
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/updateTheKOLstatusEach`);
      return response.data;
    } catch (error) {
      console.error("Error fetching updateTheKOLstatusEach:", error);
      throw error;
    } 
  }
  // Resources Page - get more resources data

  public async updateEngagements1(formData, gattexAttendees, id) {
    console.log("formDatamy", formData);

    const oldDateTime = new Date(formData.finalForm.subData.Start_Date_Time__c);
    const oldEndDateTime = new Date(
      formData.finalForm.subData.End_Date_Time__c
    );

    const fixGMTtimeIssue = (x) => {
      return x + 0;
    };
    const newDatetime =
      oldDateTime.getFullYear() +
      "-" +
      (oldDateTime.getMonth() + 1) +
      "-" +
      oldDateTime.getDate() +
      "T" +
      fixGMTtimeIssue(oldDateTime.getHours()) +
      ":" +
      oldDateTime.getMinutes() +
      ":" +
      oldDateTime.getSeconds() +
      ".000Z";
    const newEndDatetime =
      oldEndDateTime.getFullYear() +
      "-" +
      (oldEndDateTime.getMonth() + 1) +
      "-" +
      oldEndDateTime.getDate() +
      "T" +
      fixGMTtimeIssue(oldEndDateTime.getHours()) +
      ":" +
      oldEndDateTime.getMinutes() +
      ":" +
      oldEndDateTime.getSeconds() +
      ".000Z";

    console.log("newdate check", newDatetime);
  }

  public async deleteKolAttendees(masterEngagementId: string) {
    try {
      const response = await axios.delete(`${this.KOL_Api_Base_Url}/kolattendees/${masterEngagementId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting kol attendees:", error);
      throw error;
    }
  }

  public async deleteKolEngagements(masterEngagementId: string) {
    try {
      const response = await axios.delete(`${this.KOL_Api_Base_Url}/kolengagements/${masterEngagementId}`);
      return response.data;
    } catch (error) {
      console.error("Error deleting kol engagements:", error);
      throw error;
    }
  }

  public async updateMasterEngagementsAll(formData: any) {
    try {
      const response = await axios.put(`${this.KOL_Api_Base_Url}/engagements/${formData.Id}`, formData);
      return response.data;
    } catch (error) {
      console.error("Error updating master engagement:", error);
      throw error;
    }
  }

  public async updateEngagements(formData, gattexAttendees, id) {
    console.log("formDAta>>>>", formData);

    await this.deleteKolAttendees(id);
    await this.deleteKolEngagements(id);

    const newDatetime = moment(formData.finalForm.subData.Start_Date_Time__c).format("YYYY-MM-DDTHH:mm");
    const newEndDatetime = moment(formData.finalForm.subData.End_Date_Time__c).format("YYYY-MM-DDTHH:mm");

    const engToMeeting = (type) => {
      switch (type) {
        case "1:1 Engagement":
          return "1:1 Meeting";
        default:
          return type;
      }
    };
    console.log("🚀 ~ engagementResult formData-->", formData);
    console.log("🚀 ~ engagementResult ID-->", id);
    let engagementResult;

    let brand_name = "";
    if (
      formData.finalForm.subData.focus == "Imbruvica" ||
      formData.finalForm.subData.focus == "LL Unbranded" ||
      formData.finalForm.subData.focus == "Leukemia/Lymphoma Franchise" ||
      formData.finalForm.subData.focus == "Nipocalimab" ||
      formData.finalForm.subData.focus == "Pipeline"
    ) {
      brand_name = `${BRAND1}`;
    } else if (
      formData.finalForm.subData.focus == "Erleada" ||
      formData.finalForm.subData.focus == "PF Unbranded" ||
      formData.finalForm.subData.focus == "Prostate Franchise " ||
      formData.finalForm.subData.focus == "Niraparib"
    ) {
      brand_name = `${BRAND3}`;
    } else {
      brand_name = `${BRAND1}`;
    }

    let check_topic = 0;
    let check_topic_1 = false;

    if (
      formData.finalForm.subData.focus == "Imbruvica" ||
      formData.finalForm.subData.focus == "Leukemia/Lymphoma Franchise" ||
      formData.finalForm.subData.focus == "Bispecific" ||
      formData.finalForm.subData.focus == "Carvykti" ||
      formData.finalForm.subData.focus == "Darzalex" ||
      formData.finalForm.subData.focus == "Erleada" ||
      formData.finalForm.subData.focus == "Prostate Franchise" ||
      formData.finalForm.subData.focus == "Niraparib"
    ) {
      check_topic = 0;
    } else if (formData.finalForm.subData.focus == "MM Portfolio Brand") {
      check_topic = 0;
      check_topic_1 = true;
    } else {
      check_topic = 1;
    }

    let masterId;
    let focusId;
    let topicId;

    await this.getMasterId(formData.finalForm.subData.alignment).then(async (data: any) => {
      console.log("masterId>>>>>", data?.records[0]?.Id);
      masterId = data?.records[0]?.Id;
      await this.getFocusId(
        data?.records[0]?.Id,
        formData.finalForm.subData.focus,
        //check_topic == 1
        formData.finalForm.subData.focus_Type == "diseaseFocus"
          ? "Disease Focus" : "Brand Focus"
        //check_topic == 1 ? "Disease Focus" : "Brand Focus"
      ).then(async (data1: any) => {
        console.log("focusId>>>>>", data1?.records[0]?.Id);
        focusId = data1?.records[0]?.Id;
        await this.getTopicId(
          data1?.records[0]?.Id,
          formData.finalForm.subData.topic_title
        ).then((data2: any) => {
          console.log("topicId>>>>>", data2?.records[0]?.Id);
          topicId = data2?.records[0]?.Id;
        });
      });
    });

    console.log("masterId", masterId);
    console.log("focusId", focusId);
    console.log("topicId", topicId);

    if (formData.finalForm.subData.category === "Congress") {
      //congress

      if (
        //check_topic == 1
        formData.finalForm.subData.focus_Type == "diseaseFocus"
      ) {
        engagementResult = await this.updateMasterEngagementsAll
          ({
            Id: id,
            Name: formData.value,
            Engagement_Date__c: newDatetime,
            Start_Date_Time__c: newDatetime,
            End_Date_Time__c: newEndDatetime,
            KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
            Type__c: formData.value,
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
            Congress__c: formData.finalForm.subData.Congress__c,
            Location__c: formData.finalForm.subData.Location__c,
            Status__c: formData.finalForm.subData.status,
            Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
            Format__c: formData.finalForm.subData.format,
            Location_Type__c: formData.finalForm.subData.location_type,
            Meal__c: formData.finalForm.subData.meal,
            Topic__c: formData.finalForm.subData.topic_title,
            Brand__c: formData.finalForm.subData.alignment,
            Brand_Master__c: masterId,

            Focus_Master__c: focusId,
            Topic_Master__c: topicId,
            Disease_Focus__c: formData.finalForm.subData.focus,
            Brand_Focus__c: "",
          });
      } else {
        engagementResult = await this.updateMasterEngagementsAll({
          Id: id,
          Name: formData.value,
          Engagement_Date__c: newDatetime,
          Start_Date_Time__c: newDatetime,
          End_Date_Time__c: newEndDatetime,
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          Congress__c: formData.finalForm.subData.Congress__c,
          Location__c: formData.finalForm.subData.Location__c,
          Status__c: formData.finalForm.subData.status,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Format__c: formData.finalForm.subData.format,
          Location_Type__c: formData.finalForm.subData.location_type,
          Meal__c: formData.finalForm.subData.meal,
          Topic__c: formData.finalForm.subData.topic_title,
          Brand__c: formData.finalForm.subData.alignment,
          Brand_Master__c: masterId,

          Focus_Master__c: focusId,
          Topic_Master__c: topicId,
          Brand_Focus__c: formData.finalForm.subData.focus,
          Disease_Focus__c: "",
        });
      }
    } else {
      //stand-alone

      if (
        formData.finalForm.subData.focus_Type == "diseaseFocus"
        //check_topic == 1
      ) {
        engagementResult = await this.updateMasterEngagementsAll({
          Id: id,
          Name: formData.value,
          Engagement_Date__c: newDatetime,
          Start_Date_Time__c: newDatetime,
          End_Date_Time__c: newEndDatetime,
          Congress__c: "",
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          Location__c: formData.finalForm.subData.Location__c,
          Status__c: formData.finalForm.subData.status,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Format__c: formData.finalForm.subData.format,
          Location_Type__c: formData.finalForm.subData.location_type,
          Meal__c: formData.finalForm.subData.meal,
          Topic__c: formData.finalForm.subData.topic_title,
          Brand__c: formData.finalForm.subData.alignment,
          Brand_Master__c: masterId,

          Focus_Master__c: focusId,
          Topic_Master__c: topicId,
          Disease_Focus__c: formData.finalForm.subData.focus,
          Brand_Focus__c: "",
        });
      } else {
        engagementResult = await this.updateMasterEngagementsAll({
          Id: id,
          Name: formData.value,
          Engagement_Date__c: newDatetime,
          Start_Date_Time__c: newDatetime,
          End_Date_Time__c: newEndDatetime,
          Congress__c: "",
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          Location__c: formData.finalForm.subData.Location__c,
          Status__c: formData.finalForm.subData.status,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Format__c: formData.finalForm.subData.format,
          Location_Type__c: formData.finalForm.subData.location_type,
          Meal__c: formData.finalForm.subData.meal,
          Topic__c: formData.finalForm.subData.topic_title,
          Brand__c: formData.finalForm.subData.alignment,
          Brand_Master__c: masterId,

          Focus_Master__c: focusId,
          Topic_Master__c: topicId,
          Brand_Focus__c: formData.finalForm.subData.focus,
          Disease_Focus__c: "",
        });
      }
    }
    await Promise.all(
      formData.finalForm.kolnewData.map(async (x) => {
        if (x.KOLAccount__c === undefined){
          await  this.addNewKOL(
            x,
            id,
            formData.finalForm.subData.Engagement_Type__c,
            newDatetime,
            masterId,
            formData.value,
            formData.finalForm.subData.Notes_Details__c,
          );
        }
        else {
          await this.createKolEngagement({
            Master_Engagement__c: id,
            KOLAccount__c: x.KOLAccount__c,
            Attendee_Role__c: x.Attendee_Role__c,
            Engagement_Type__c:formData.finalForm.subData.Engagement_Type__c,
            Engagement_Date__c: newDatetime,
            KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
            Status__c: x.Status__c,
            Type__c: formData.value,
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,        
          });
        }
       })
    );


    if (formData.finalForm.subData.Attendee_Type__c != "") {
      const strArr = formData.finalForm.subData.Attendee_Type__c;
      await Promise.all(
        strArr.map(async (item) => {
          console.log("address--->", item);
  
          await this.createKolAttendee({
            Master_Engagement__c: id,
            Employee__c: item.id,
            Attendee_Role__c: item.Attendee_Role__c,
            Send_Invite_Email__c: item.email,
            Host_Email__c: formData.finalForm.subData.Host_Email__c,
            Host_Name__c: formData.Host_Name__c,
            Email_Subject_Line__c: formData.Email_Subject_Line__c,
            userId: this.KOL_UserID,
            Attendee_Name__c: item.name,
            Type__c: formData.value ?? '',
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          });
        })        
      );

    }

    if (formData.finalForm.subData.Attendee_Leadership__c.length != 0) {
      const strArr1 = formData.finalForm.subData.Attendee_Leadership__c
      if (strArr1.length == 1) {
        await this.createKolAttendee({
          Leadership_Attendee__c: true,
          Master_Engagement__c: id,
          Employee__c: formData.finalForm.subData.Attendee_Leadership__c[0].value,
          Send_Invite_Email__c: formData.finalForm.subData.Attendee_Leadership__c[0].email,
          Host_Email__c: formData.finalForm.subData.Host_Email__c,
          Host_Name__c: formData.Host_Name__c,
          Email_Subject_Line__c: formData.Email_Subject_Line__c,
          userId: this.KOL_UserID,
          Attendee_Name__c: formData.finalForm.subData.Attendee_Leadership__c[0].name,
          Type__c: formData.value ?? '',
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
        });
      } else {
        await Promise.all(
          strArr1.map(async (item) => {
            console.log("address1--->", item);
  
           await  this.createKolAttendee({
              Leadership_Attendee__c: true,
              Master_Engagement__c: id,
              Employee__c: item.value,
              Send_Invite_Email__c: item.email,
              Host_Email__c: formData.finalForm.subData.Host_Email__c,
              Host_Name__c: formData.Host_Name__c,
              Email_Subject_Line__c: formData.Email_Subject_Line__c,
              userId: this.KOL_UserID,
              Attendee_Name__c: item.name,
              Type__c: formData.value ?? '',
              Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
            });
          })
        );

      }
    }

    await Promise.all(
      formData.finalForm.kmeAryData.map(async (x) => {
        console.log("x---->", x);
        await this.createKolEngagement({
          Master_Engagement__c: id,
          KOLAccount__c: x.KOLAccount__c,
          Attendee_Role__c: x.Attendee_Role__c,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Engagement_Date__c: newDatetime,
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Status__c: x.Status__c,
          Send_Invite_Email__c: x.email,
          Host_Email__c: formData.finalForm.subData.Host_Email__c,
          Host_Name__c: formData.Host_Name__c,
          Email_Subject_Line__c: formData.Email_Subject_Line__c,
          userId: this.KOL_UserID,
          Type__c: formData.value ?? '',
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
        });
      })
    )

  }

  public async validateNewKol(lastName, firstName) {

    const params = new URLSearchParams();

    params.append('lastName', lastName)
    params.append('firstName', firstName);
    params.append('KOL_Profile_Portal__pc', this.KOL_Profile_Portal__pc);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/validateNewKOL`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error validateNewKol: ", error);
      throw error;
    }

  }

  public async addNewKOL(x, engagementId, type, date_add, masterId, Type__c, Notes_Details__c) {

    try {
      var data = {
        FirstName: x.firstName,
        LastName: x.lastName,
        RecordTypeId: this.RecordTypeId,
        Account__c: this.Account__c,
        Write_In_KOL__pc: true,
        Degree__pc: x.credentails,
        PersonTitle: x.title,
        Primary_Title_Affiliation__pc: x.institution,
        PersonMailingCity: x.city,
        PersonMailingState: x.state,
        KOL_Profile_Portal__pc: this.KOL_Profile_Portal__pc
      };

      const createKolResponse = await axios.post(`${this.KOL_Api_Url}/addNewKOL`, data);

      await axios.post(`${this.KOL_Api_Url}/createkolbrand`, {
        kOL__c: createKolResponse.data.Id,
        brand_Master__c: masterId,
        userId: this.KOL_UserID
      });

      await this.createKolEngagement({
        Master_Engagement__c: engagementId,
        KOLAccount__c: createKolResponse.data.Id,
        Attendee_Role__c: x.Attendee_Role__c,
        Engagement_Type__c: type,
        Engagement_Date__c: date_add,
        KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
        Status__c: x.Status__c,
        Type__c: Type__c,
        Notes_Details__c: Notes_Details__c,        
      });

      return createKolResponse.data;
    } catch (error) {
      console.error("Error addNewKOL:", error);
      throw error;
    }
  }

  public async createEngagement(formData: any) {
    try {
      const response = await axios.post(`${this.KOL_Api_Base_Url}/engagements`, formData);
      return response.data;
    } catch (error) {
      console.error("Error creating engagement:", error);
      throw error;
    }
  }

  public async createKolAttendee(formData: any) {
    try {
      const response = await axios.post(`${this.KOL_Api_Base_Url}/kolattendees`, formData);
      return response.data;
    } catch (error) {
      console.error("Error creating kol attendee:", error);
      throw error;
    }
  }

  public async createKolEngagement(formData: any) {
    try {
      const response = await axios.post(`${this.KOL_Api_Base_Url}/kolengagements`, formData);
      return response.data;
    } catch (error) {
      console.error("Error creating kol engagement:", error);
      throw error;
    }
  }

  public async addEngagements(formData, gattexAttendees) {
    console.log("formData>>>>>>", formData);
    console.log("addEngagement>>>>>", formData);
    const newDatetime = moment(formData.finalForm.subData.Start_Date_Time__c).format("YYYY-MM-DDTHH:mm");
    const newEndDatetime = moment(formData.finalForm.subData.End_Date_Time__c).format("YYYY-MM-DDTHH:mm");

    console.log("after date--->", newDatetime);

    const engToMeeting = (type) => {
      switch (type) {
        case "1:1 Engagement":
          return "1:1 Meeting";
        default:
          return type;
      }
    };
    console.log("🚀 ~ engagementResult formData-->", formData);
    console.log(
      "🚀  file: sfdcApi.tsx  line 1980  SfdcApi  ",
      formData.finalForm.subData.Attendee_Leadership__c
    );
    console.log(
      "🚀  file: sfdcApi.tsx  line 1980  SfdcApi  ",
      formData.finalForm.subData.Attendee_Type__c
    );
    console.log(
      "🚀  file: sfdcApi.tsx  line 1980  SfdcApi  ",
      formData.finalForm.subData.Congress__c
    );

    let engagementResult;

    let brand_name = "";
    let check_topic = 0;
    let check_topic_1 = false;
    if (
      formData.finalForm.subData.focus == "Imbruvica" ||
      formData.finalForm.subData.focus == "LL Unbranded" ||
      formData.finalForm.subData.focus == "Leukemia/Lymphoma Franchise" ||
      formData.finalForm.subData.focus == "Nipocalimab" ||
      formData.finalForm.subData.focus == "Pipeline"
    ) {
      brand_name = `${BRAND1}`;
    } else if (
      formData.finalForm.subData.focus == "Erleada" ||
      formData.finalForm.subData.focus == "PF Unbranded" ||
      formData.finalForm.subData.focus == "Prostate Franchise " ||
      formData.finalForm.subData.focus == "Niraparib"
    ) {
      brand_name = `${BRAND3}`;
    } else {
      brand_name = `${BRAND1}`;
    }

    if (
      formData.finalForm.subData.focus == "Imbruvica" ||
      formData.finalForm.subData.focus == "Leukemia/Lymphoma Franchise" ||
      formData.finalForm.subData.focus == "Bispecific" ||
      formData.finalForm.subData.focus == "Carvykti" ||
      formData.finalForm.subData.focus == "Darzalex" ||
      formData.finalForm.subData.focus == "Erleada" ||
      formData.finalForm.subData.focus == "Prostate Franchise" ||
      formData.finalForm.subData.focus == "Niraparib"
    ) {
      check_topic = 0;
    } else if (formData.finalForm.subData.focus == "MM Portfolio Brand") {
      check_topic = 0;
      check_topic_1 = true;
    } else {
      check_topic = 1;
    }

    let masterId;
    let focusId;
    let topicId;

    await this.getMasterId(formData.finalForm.subData.alignment).then(async (data: any) => {
      console.log("masterId>>>>>", data?.records[0]?.Id);
      masterId = data?.records[0]?.Id;
      await this.getFocusId(
        data?.records[0]?.Id,
        formData.finalForm.subData.focus,
        formData.finalForm.subData.focus_Type == "diseaseFocus"
          ? "Disease Focus" : "Brand Focus"

      ).then(async (data1: any) => {
        console.log("focusId>>>>>", data1?.records[0]?.Id);
        focusId = data1?.records[0]?.Id;
        await this.getTopicId(
          data1?.records[0]?.Id,
          formData.finalForm.subData.topic_title
        ).then((data2: any) => {
          console.log("topicId>>>>>", data2?.records[0]?.Id);
          topicId = data2?.records[0]?.Id;
        });
      });
    });

    console.log("masterId", masterId);
    console.log("focusId", focusId);
    console.log("topicId", topicId);

    if (formData.finalForm.subData.category === "Congress") {
      //congress

      console.log("newDatetime", newDatetime);

      if (
        //check_topic == 1
        formData.finalForm.subData.focus_Type == "diseaseFocus"
      ) {
        engagementResult = await this.createEngagement({
          Name: formData.value,
          Engagement_Date__c: newDatetime,
          Start_Date_Time__c: newDatetime,
          End_Date_Time__c: newEndDatetime,
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          Congress__c: formData.finalForm.subData.Congress__c,
          Location__c: formData.finalForm.subData.Location__c,
          Status__c: formData.finalForm.subData.status,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Format__c: formData.finalForm.subData.format,
          Location_Type__c: formData.finalForm.subData.location_type,
          Meal__c: formData.finalForm.subData.meal,
          Topic__c: formData.finalForm.subData.topic_title,
          Brand__c: formData.finalForm.subData.alignment,
          Brand_Master__c: masterId,

          Focus_Master__c: focusId,
          userId: this.KOL_UserID,
          Topic_Master__c: topicId,
          Disease_Focus__c: formData.finalForm.subData.focus,
        });
      } else {
        console.log("newDatetime", newDatetime);

        engagementResult = await this.createEngagement({
          Name: formData.value,
          Engagement_Date__c: newDatetime,
          Start_Date_Time__c: newDatetime,
          End_Date_Time__c: newEndDatetime,
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          Congress__c: formData.finalForm.subData.Congress__c,
          Location__c: formData.finalForm.subData.Location__c,
          Status__c: formData.finalForm.subData.status,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Format__c: formData.finalForm.subData.format,
          Location_Type__c: formData.finalForm.subData.location_type,
          Meal__c: formData.finalForm.subData.meal,
          Topic__c: formData.finalForm.subData.topic_title,
          Brand__c: formData.finalForm.subData.alignment,
          Brand_Master__c: masterId,

          Focus_Master__c: focusId,
          userId: this.KOL_UserID,
          Topic_Master__c: topicId,
          Brand_Focus__c: formData.finalForm.subData.focus,
        });
      }
    } else {
      //stand-alone
      console.log("newDatetime", newDatetime);
      if (
        formData.finalForm.subData.focus_Type == "diseaseFocus"
      ) {
        engagementResult = await this.createEngagement({
          Name: formData.value,
          Engagement_Date__c: newDatetime,
          Start_Date_Time__c: newDatetime,
          End_Date_Time__c: newEndDatetime,
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          Location__c: formData.finalForm.subData.Location__c,
          Status__c: formData.finalForm.subData.status,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Format__c: formData.finalForm.subData.format,
          Location_Type__c: formData.finalForm.subData.location_type,
          Meal__c: formData.finalForm.subData.meal,
          Topic__c: formData.finalForm.subData.topic_title,
          Brand__c: formData.finalForm.subData.alignment,
          Brand_Master__c: masterId,

          Focus_Master__c: focusId,
          userId: this.KOL_UserID,
          Topic_Master__c: topicId,
          Disease_Focus__c: formData.finalForm.subData.focus,
        });
      } else {
        console.log("newDatetime", newDatetime);
        engagementResult = await this.createEngagement({
          Name: formData.value,
          Engagement_Date__c: newDatetime,
          Start_Date_Time__c: newDatetime,
          End_Date_Time__c: newEndDatetime,
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          Location__c: formData.finalForm.subData.Location__c,
          Status__c: formData.finalForm.subData.status,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Format__c: formData.finalForm.subData.format,
          Location_Type__c: formData.finalForm.subData.location_type,
          Meal__c: formData.finalForm.subData.meal,
          Topic__c: formData.finalForm.subData.topic_title,
          Brand__c: formData.finalForm.subData.alignment,
          Brand_Master__c: masterId,

          Focus_Master__c: focusId,
          userId: this.KOL_UserID,
          Topic_Master__c: topicId,
          Brand_Focus__c: formData.finalForm.subData.focus,
        });
      }
    }

    const engagementId = engagementResult["id"];
    console.log(
      "🚀 ~ file: sfdcApi.tsx ~ line 1852-new ~ SfdcApi ~ awaitthis.conn.sobject ~ formData",
      formData
    );
    console.log("engagementId", engagementId);
    console.log("formData.finalform.kmeAryData", formData.finalForm.kmeAryData);
    console.log("engToMeeting(formData.value)", engToMeeting(formData.value));
    console.log("newDatetime", newDatetime);
    console.log("this.KOL_Profile_Portal__pc", this.KOL_Profile_Portal__pc);
    console.log("formData.selectedKolStatus", formData.selectedKolStatus);

    await Promise.all(
      formData.finalForm.kolnewData.map(async (x) => {
        await this.addNewKOL(
          x,
          engagementId,
          formData.finalForm.subData.Engagement_Type__c,
          newDatetime,
          masterId,
          formData.value,
          formData.finalForm.subData.Notes_Details__c,
        );
      })
    );

    if (formData.finalForm.subData.Attendee_Type__c != "") {
      const strArr = formData.finalForm.subData.Attendee_Type__c;
      console.log("strArr", strArr);
      await Promise.all(
        strArr.map(async (item) => {
          console.log("address--->", item.id);
          await this.createKolAttendee({
            Leadership_Attendee__c: false,
            Master_Engagement__c: engagementId,
            Employee__c: item.id,
            Attendee_Role__c: item.Attendee_Role__c,
            Send_Invite_Email__c: item.email,
            Host_Email__c: formData.finalForm.subData.Host_Email__c,
            Host_Name__c: formData.Host_Name__c,
            Email_Subject_Line__c: formData.Email_Subject_Line__c,
            userId: this.KOL_UserID,
            Attendee_Name__c: item.name,
            Type__c: formData.value,
            Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
          });
        })
      );

    }

    if (formData.finalForm.subData.Attendee_Leadership__c != "") {
      const strArr1 = formData.finalForm.subData.Attendee_Leadership__c
      if (strArr1.length == 1) {
        await this.createKolAttendee({
          Leadership_Attendee__c: true,
          Master_Engagement__c: engagementId,
          Employee__c: formData.finalForm.subData.Attendee_Leadership__c[0].value,
          Send_Invite_Email__c: formData.finalForm.subData.Attendee_Leadership__c[0].email,
          Host_Email__c: formData.finalForm.subData.Host_Email__c,
          Host_Name__c: formData.Host_Name__c,
          Email_Subject_Line__c: formData.Email_Subject_Line__c,
          userId: this.KOL_UserID,
          Attendee_Name__c: formData.finalForm.subData.Attendee_Leadership__c[0].name,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
        });
      } else {
        await Promise.all(
          strArr1.map(async (item) => {
            console.log("address1--->", item);
  
            await this.createKolAttendee({
              Leadership_Attendee__c: true,
              Master_Engagement__c: engagementId,
              Employee__c: item.value,
              Send_Invite_Email__c: item.email,
              Host_Email__c: formData.finalForm.subData.Host_Email__c,
              Host_Name__c: formData.Host_Name__c,
              Email_Subject_Line__c: formData.Email_Subject_Line__c,
              userId: this.KOL_UserID,
              Attendee_Name__c: item.name,
              Type__c: formData.value,
              Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
            });
          })
        );

      }
    }
    await Promise.all(
      formData.finalForm.kmeAryData.map(async (x) => {
        console.log("x---->", x);
        await this.createKolEngagement({
          Master_Engagement__c: engagementId,
          KOLAccount__c: x.KOLAccount__c,
          Attendee_Role__c: x.Attendee_Role__c,
          Engagement_Type__c: formData.finalForm.subData.Engagement_Type__c,
          Engagement_Date__c: newDatetime,
          KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
          Status__c: x.Status__c,
          Send_Invite_Email__c: x.email,
          Host_Email__c: formData.finalForm.subData.Host_Email__c,
          Host_Name__c: formData.Host_Name__c,
          Email_Subject_Line__c: formData.Email_Subject_Line__c,
          userId: this.KOL_UserID,
          Type__c: formData.value,
          Notes_Details__c: formData.finalForm.subData.Notes_Details__c,
        });
      })
    )

  }


  //below is the othe post maybe delete and move into other sfdc file //

  // public async addAdvisoryEngagements(
  //   formData,
  //   gattexAttendees,
  //   multipleAttendees
  // ) {
  //   // const oldDateTime = formData.eventDate;
  //   // const oldEndDateTime = formData.endDate;
  //   const oldDateTime = new Date(formData.eventDate);
  //   const oldEndDateTime = new Date(formData.endDate);

  //   const fixGMTtimeIssue = (x) => {
  //     return x + 5;
  //   };
  //   const newDatetime =
  //     oldDateTime.getFullYear() +
  //     "-" +
  //     (oldDateTime.getMonth() + 1) +
  //     "-" +
  //     oldDateTime.getDate() +
  //     "T" +
  //     fixGMTtimeIssue(oldDateTime.getHours()) +
  //     ":" +
  //     oldDateTime.getMinutes() +
  //     ":" +
  //     oldDateTime.getSeconds() +
  //     ".000Z";
  //   const newEndDatetime =
  //     oldEndDateTime.getFullYear() +
  //     "-" +
  //     (oldEndDateTime.getMonth() + 1) +
  //     "-" +
  //     oldEndDateTime.getDate() +
  //     "T" +
  //     fixGMTtimeIssue(oldEndDateTime.getHours()) +
  //     ":" +
  //     oldEndDateTime.getMinutes() +
  //     ":" +
  //     oldEndDateTime.getSeconds() +
  //     ".000Z";

  //   const engToMeeting = (type) => {
  //     switch (type) {
  //       case "1:1 Engagement":
  //         return "1:1 Meeting";
  //       default:
  //         return type;
  //     }
  //   };

  //   let engagementResult;
  //   if (formData.eventType === "Congress-Related") {
  //     //congress
  //     engagementResult = await this.conn.sobject("Master_Enagement__c").create({
  //       Name: engToMeeting(formData.value),
  //       Engagement_Date__c: newDatetime,
  //       Start_Date_Time__c: newDatetime,
  //       End_Date_Time__c: newEndDatetime,
  //       KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
  //       Type__c: engToMeeting(formData.value),
  //       Notes_Details__c: formData.notes,
  //       Congress__c: formData.eventName,
  //       Location__c: formData.location,
  //     });
  //   } else if (formData.eventType === "Stand-Alone") {
  //     //stand-alone
  //     engagementResult = await this.conn.sobject("Master_Enagement__c").create({
  //       Name: engToMeeting(formData.value),
  //       Engagement_Date__c: newDatetime,
  //       Start_Date_Time__c: newDatetime,
  //       End_Date_Time__c: newEndDatetime,
  //       KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
  //       Type__c: engToMeeting(formData.value),
  //       Notes_Details__c: formData.notes,
  //       Location__c: formData.location,
  //     });
  //   }

  //   const engagementId = engagementResult["id"];

  //   multipleAttendees.forEach((x) => {
  //     // console.log('ALL THE DATA FOR MAP OF MULTIPLE ATTENDEES KOL', x)
  //     this.conn.sobject("KOL_Engagement__c").create({
  //       Master_Engagement__c: engagementId,
  //       KOLAccount__c: x.id,
  //       Attendee_Type__c: `HCP`,
  //       Engagement_Type__c: engToMeeting(formData.value),
  //       Engagement_Date__c: newDatetime,
  //       KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
  //       Status__c: x.status,
  //       Attendee_Role__c: x.role,
  //       Title__c: formData.advisoryTitle,
  //     });
  //   });
  //   // await this.conn
  //   // .sobject("KOL_Engagement__c")
  //   // .create({
  //   //   Master_Engagement__c: engagementId,
  //   //   KOLAccount__c: formData.selectedKolAttendee,
  //   //   Attendee_Type__c: `HCP`,
  //   //   Engagement_Type__c: engToMeeting(formData.value),
  //   //   Engagement_Date__c: formData.eventDate,
  //   //   KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
  //   //   Status__c: formData.selectedKolStatus,
  //   //   Attendee_Role__c: formData.kolRole,
  //   //   Title__c: formData.advisoryTitle
  //   // })

  //   if (gattexAttendees[0] === "" || gattexAttendees === "") {
  //     return null;
  //   } else {
  //     const gattexAttendeesResult = gattexAttendees.forEach((x) => {
  //       this.conn.sobject("KOL_Engagement__c").create({
  //         Master_Engagement__c: engagementId,
  //         KOLAccount__c: x,
  //         Attendee_Type__c: `Employee`,
  //         Engagement_Type__c: engToMeeting(formData.value),
  //         Engagement_Date__c: newDatetime,
  //         KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
  //         Status__c: "Invited",
  //         // Status__c: formData.selectedKolStatus,
  //       });
  //     });
  //     console.log("Gattex Attendees Results", gattexAttendeesResult);
  //   }
  // }

  // public async returnfieldinsightsdoc(id) {
  //   return this.conn.query(`
  //       select 
  //       sfid_18_digit__c, 
  //       ambassador_report_name__c, 
  //       bio_download_url__c 
  //         from 
  //       account
  //         where 
  //         sfid_18_digit__c = '${id}'
  //       `);
  // }

  public async returnFieldInsightsDoc(id) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/returnFieldInsightsDoc/${id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetch FieldInsightsDoc:", error);
      throw error;
    }
  }

  public async returnTypesByProfile(profileId?: string) {
    // //later for hover on Pie.
    // return this.conn.query(`
    //         SELECT  
    //         Master_Engagement__r.Congress__c,
    //         Engagement_Type__c,
    //         KOLAccount__c,
    //         Id
    //           FROM 
    //         KOL_Engagement__c 
    //           WHERE 
    //         Master_Engagement__r.KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc
    //   }'  
    //           ${profileId ? "AND KOLAccount__c ='" + profileId + "'" : ""}
    //         `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/returnTypesByProfile`);
      return response.data;
    } catch (error) {
      console.error("Error fetching returnTypesByProfile:", error);
      throw error;
    } 
  }

  // public async addNewGattexAttendees(d) {
  //   const engToMeeting = (type) => {
  //     switch (type) {
  //       case "1:1 Engagement":
  //         return "1:1 Meeting";
  //       default:
  //         return type;
  //     }
  //   };

  //   const emptyArray = [] as any;

  //   d?.kolIds.forEach((perId) => {
  //     let iObj = {
  //       Master_Engagement__c: d?.id,
  //       KOLAccount__c: perId, //for each attendee id
  //       Attendee_Type__c: `Employee`,
  //       Engagement_Type__c: engToMeeting(d?.eng_type),
  //       Engagement_Date__c: d?.date,
  //       KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
  //       Status__c: d?.status,
  //     };
  //     emptyArray.push(iObj);
  //   });

  //   try {
  //     this.conn.sobject("KOL_Engagement__c").create(emptyArray);
  //     return "Success";
  //   } catch {
  //     return "Err";
  //   }
  // }

  // public async addNewKOLAttendees(d) {
  //   const engToMeeting = (type) => {
  //     switch (type) {
  //       case "1:1 Engagement":
  //         return "1:1 Meeting";
  //       default:
  //         return type;
  //     }
  //   };

  //   const emptyArray = [] as any;

  //   d?.kolIds.forEach((perId) => {
  //     let iObj = {
  //       Master_Engagement__c: d?.id,
  //       KOLAccount__c: perId, //for each attendee id
  //       Attendee_Type__c: `HCP`,
  //       Engagement_Type__c: engToMeeting(d?.eng_type),
  //       Engagement_Date__c: d?.date,
  //       KOL_Profile_Portal__c: this.KOL_Profile_Portal__pc,
  //       Status__c: d?.status,
  //       Attendee_Role__c: d?.role,
  //     };
  //     emptyArray.push(iObj);
  //   });

  //   try {
  //     this.conn.sobject("KOL_Engagement__c").create(emptyArray);
  //     return "Success";
  //   } catch {
  //     return "Err";
  //   }
  // }

  public async updateTheKOLstatusEachNEW(data) {
    const emptyUpdateArray = [] as any;
    data.forEach((x) => {
      let uObj = {
        Id: x.id,
        Master_Engagement__c: x.Master_Engagement__c,
        Status__c: x.Status__c,
      };
      emptyUpdateArray.push(uObj);
    });

    // try {
    //   this.conn.sobject("KOL_Engagement__c").update(emptyUpdateArray);
    //   return "Success";
    // } catch {
    //   return "Err";
    // }
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/updateTheKOLstatusEachNEW`);
      return response.data;
    } catch (error) {
      console.error("Error fetching updateTheKOLstatusEachNEW:", error);
      throw error;
    } 
  }

  // BIO GEN KOL CALL
  public async fetchCongressMeetings() {
    // return this.conn.query(`
    //     SELECT 
    //       Id,
    //       Name, 
    //       Meeting_Card_Title__c,
    //       Cong_Id__c, 
    //       Start_Time_Full__c, 
    //       Start_Date_Time__c,
    //       End_Time_Full__c, 
    //       End_Date_Time__c,
    //       Location__c, 
    //       Type__c, 
    //       Notes__c,
    //       CreatedBy.Name
    //     FROM Meeting__c 
    //       WHERE KOL_Portal__c = '${process.env.REACT_APP_KOL_CONGRESS_ID ?? ``
    //   }' 
    //       ORDER BY Start_Date_Time__c desc
    //       LIMIT 100
    //     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCongressMeetings`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchCongressMeetings:", error);
      throw error;
    } 
  }

  public async fetchCongressMeetingAttendees(Id) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCongressMeetingAttendees?congressId=${process.env.REACT_APP_KOL_CONGRESS_ID ?? ``}&id=${Id}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchCongressMeetingAttendees", error);
      throw error;
    }
    //   return this.conn.query(`
    //       SELECT 
    //         Meeting__r.Id,
    //         Type__c,
    //         Contact__r.Name, 
    //         Meeting__r.Name, 
    //         Meeting__r.Meeting_Card_Title__c, 
    //         Meeting__r.Cong_Id__c, 
    //         Meeting__r.Start_Time_Full__c, 
    //         Meeting__r.Start_Date_Time__c,
    //         Meeting__r.End_Time_Full__c, 
    //         Meeting__r.End_Date_Time__c,
    //         Meeting__r.Location__c, 
    //         Meeting__r.Type__c, 
    //         Meeting__r.Notes__c, 
    //         Meeting__r.CreatedBy.Name
    //       FROM Meeting_Attendee__c 
    //         WHERE Meeting__r.KOL_Portal__c = '${
    //           process.env.REACT_APP_KOL_CONGRESS_ID ?? ``
    //         }' 
    //         AND Meeting__r.Id = '${Id}'
    //         ORDER BY Meeting__r.Name 
    //       `);
  }

  // Reports Page
  public async getPersonContacts() {
    try {
      console.log('profilePortal', this.KOL_Profile_Portal__pc)
      const response = await axios.get(`${this.KOL_Api_Url}/getPersonContacts?kolPortal=${this.KOL_Portal__c}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchTwitterUsers:", error);
      throw error;
    }
  }


  public async getKolProfiles() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKolProfiles/${this.KOL_Portal__c}`);
      return response.data;
    } catch (error) {
      console.error("Error getKolProfiles", error);
      throw error;
    }
    // return this.conn.query(`
    //     SELECT Person__r.Long_PersonAccountContactID__c, Person__r.Name
    //     FROM Key_Opinion_Leader__c
    //     WHERE KOL_Portal__c = '${this.KOL_Portal__c}' 
    // `);
  }

  public async getMeetingsForPerson(personId: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getMeetingsForPerson?personId=${personId}`);
      return response.data;
    } catch (error) {
      console.error("Error getKolProfiles", error);
      throw error;
    }
  }


  public async getReports(meetingIdList: string[]) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getReports`, {
        params: {
          meetingIdList: meetingIdList
        }
      }
      );
      return response.data;
    } catch (error) {
      console.error("Error getReports", error);
      throw error;
    }

    // const meetingIdsStr = "'" + meetingIdList.join("','") + "'";
    //   return this.conn.query(`
    //   SELECT Name, Type__c, Meeting__c,
    //     Meeting__r.Name, Meeting__r.Cong_Id__c, Meeting__r.Type__c, Meeting__r.timezone_short__c,  
    //     Meeting__r.Engagement_Owner__c, Meeting__r.Start_Time_Full__c, Meeting__r.End_Time_Full__c,
    //     Contact__r.Name, Contact__r.Degree__c, Contact__r.Account.Affiliation__pc, 
    //     Contact__r.Primary_Title_City_State__c, Contact__r.SFID_18_Digit__c, 
    //     Contact__r.Account.Primary_Title_Position__pc
    //     FROM Meeting_Attendee__c 
    //   WHERE Meeting__c IN (${meetingIdsStr})
    //   ORDER By Meeting__c
    // `);
  }

  public async getExpertReports(territory?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getExpertReports/${this.KOL_Profile_Portal__pc}?territory=${territory}`);
      return response.data;
    } catch (error) {
      console.error("Error getExpertReports", error);
      throw error;
    }
    // return this.conn.query(`
    //   SELECT Expert_Account__r.Id, Expert_Account__r.Name, Expert_Account__r.Specialty__c, 
    //     Expert_Account__r.Primary_Title_Affiliation__pc, Expert_Account__r.AAL_Territory__c, 
    //     Expert_Account__r.Primary_Title_City_State__pc, Expert_Account__r.PersonEmail, 
    //     Expert_Account__r.Phone, Expert_Account__r.FirstName, Expert_Account__r.LastName
    //   FROM Expert__c 
    //   WHERE KOL_Profile_Portal__c  = '${this.KOL_Profile_Portal__pc}' 
    //   ${
    //     territory
    //       ? "AND Expert_Account__r.AAL_Territory__c ='" + territory + "'"
    //       : ""
    //   }
    // `);
  }

  // public async getKmeReportsNew(division: string, companycheck: string, FFS__cValue: string) {
  //   //Leadership_Attendee__c
  //   if (division == "Admin" || companycheck == "JBI") {
  //     return this.conn.query(`
  //       Select Id,
  //       Name,  
  //       Brand_Master__r.Name, 
  //       Engagement_Type__c, 
  //       Owner.FirstName,
  //       Owner.LastName, 
  //       Congress__c, 
  //       Congress__r.Label__c,

  //       (Select Attendee_Name__c, 
  //         Attendee_Role__c, 
  //         Leadership_Attendee__c 
  //           from KOL_Attendees__r),
  //       (Select KOLAccount__r.Name, 
  //         KOLAccount__r.Write_In_KOL__pc, 
  //         KOLAccount__r.Primary_Title_Affiliation__pc, 
  //         KOLAccount__r.Primary_Title_City_State__pc,
  //         KOL_Brand__r.TLL_Name__c,
  //         KOL_Brand__r.TLL2_Name__c, 
  //         KOL_Brand__r.MSL_Name__c, 
  //         KOL_Brand__r.MSL2_Name__c 
  //           from KOL_Engagements__r),
  //       Focus_Master__r.Name, 
  //       Topic_Master__r.Name, 
  //       Format__c, 
  //       Engagement_Date__c, 
  //       Start_Date_Time__c,
  //       Status__c, 
  //       Location_Type__c, 
  //       Location__c, 
  //       Notes_Details__c
  //       from Master_Enagement__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' ${FFS__cValue}
  //     `);
  //   } else if (companycheck == "PCYC") {
  //     return this.conn.query(`
  //       Select Id, Name,  Brand_Master__r.Name, Engagement_Type__c, Owner.FirstName,Owner.LastName, Congress__c, Congress__r.Label__c,
  //       (Select Attendee_Name__c, Attendee_Role__c, Leadership_Attendee__c from KOL_Attendees__r),
  //       (Select KOLAccount__r.Name, KOLAccount__r.Write_In_KOL__pc, KOLAccount__r.Primary_Title_Affiliation__pc, KOLAccount__r.Primary_Title_City_State__pc, 
  //       KOL_Brand__r.TLL_Name__c, KOL_Brand__r.TLL2_Name__c, KOL_Brand__r.MSL_Name__c, KOL_Brand__r.MSL2_Name__c from KOL_Engagements__r),
  //       Focus_Master__r.Name, Topic_Master__r.Name, Format__c, Engagement_Date__c, Start_Date_Time__c,
  //       Status__c, Location_Type__c, Location__c, Notes_Details__c
  //       from Master_Enagement__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
  //       and Engagement_Type__c = '${division}' ${FFS__cValue}
  //     `);
  //   }
  // }

  public async getKmeReportsNew(division: string, companycheck: string, FFS__cValue: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKmeReportsNew?profilePortal=${this.KOL_Profile_Portal__pc}&division=${division}&companycheck=${companycheck}&ffs=${FFS__cValue}`);
      return response.data;
    } catch (error) {
      console.error("Error getKmeReportsNew2", error);
      throw error;
    }
  }

  // public async getKmeReportsNewExcel(division: string, companycheck: string, FFS__cValue: string) {
  //   //Leadership_Attendee__c
  //   if (division == "Admin" || companycheck == "JBI") {
  //     console.log('getKmeReportsNewExcel admin and jbi', )
  //     return this.conn.query(
  //       `
  //       Select Id, 
  //       Name, 
  //       Congress__r.Label__c,
  //       Brand_Master__r.Name, 
  //       Focus_Master__r.Name,
  //       Topic_Master__r.Name,
  //       Engagement_Type__c, 
  //       Engagement_Date__c, 
  //       Start_Date_Time__c,
  //       End_Date_Time__c, 
  //       Location__c, 
  //       Status__c, 
  //       FFS__c, 
  //         (Select 
  //           KOLAccount__r.Name,
  //           KOLAccount__r.Specialty__c, 
  //           KOLAccount__r.Degree__pc, 
  //           KOLAccount__r.PersonEmail,
  //           KOLAccount__r.Primary_Title_Affiliation__pc,
  //           KOLAccount__r.Primary_Title_City_State__pc,
  //           KOLAccount__r.Primary_Title_Position__pc 
  //             from KOL_Engagements__r 
  //             order by KOLAccount__r.Name asc),

  //         (Select 
  //           Attendee_Name__c, 
  //           Leadership_Attendee__c, 
  //           Attendee_Role__c 
  //             from KOL_Attendees__r 
  //             order by Attendee_Name__c asc),
  //       Notes_Details__c 
  //       from Master_Enagement__c 
  //       where Congress__r.Label__c = 'ASCO 2023' ${FFS__cValue} 
  //       order by Start_Date_Time__c asc

  //       `
  //     );

  //   } else if (companycheck == "PCYC") {
  //     return this.conn.query(`
  //     Select Id, Name, Congress__r.Label__c, Brand_Master__r.Name, Focus_Master__r.Name,
  //     Topic_Master__r.Name, Engagement_Type__c, Engagement_Date__c, Start_Date_Time__c,
  //      End_Date_Time__c, Location__c, Status__c, FFS__c, (Select KOLAccount__r.Name,
  //         KOLAccount__r.Specialty__c, KOLAccount__r.Degree__pc, KOLAccount__r.PersonEmail,
  //          KOLAccount__r.Primary_Title_Affiliation__pc,
  //           KOLAccount__r.Primary_Title_City_State__pc,
  //            KOLAccount__r.Primary_Title_Position__pc from KOL_Engagements__r order by KOLAccount__r.Name asc),
  //             (Select Attendee_Name__c, Leadership_Attendee__c, Attendee_Role__c from KOL_Attendees__r order by Attendee_Name__c asc),
  //              Notes_Details__c from Master_Enagement__c where Congress__r.Label__c = 'ASCO 2023' ${FFS__cValue} order by Start_Date_Time__c asc
  //       and Engagement_Type__c = '${division}'
  //     `);
  //   }
  // }

  public async getKmeReportsNewExcel(division: string, companycheck: string, FFS__cValue: string) {

    const params = new URLSearchParams();

    params.append('division', division);
    params.append('companycheck', companycheck);
    params.append('congressLabel', 'ASCO 2023')

    if (FFS__cValue !== undefined && FFS__cValue !== "") {
      params.append('ffs', FFS__cValue);
    }

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKmeReportsNewExcel`, { params: params });
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async getAshReport(division: string, companycheck: string, FFS__cValue: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getAshReport?division=${division}&Congress_Label__c=${'ASH 2023'}&companycheck=${companycheck}`);
      return response.data;
    } catch (error) {
      console.error("Error getAshReport", error);
      throw error;
    }
    //Leadership_Attendee__c

    // if (division == "Admin" || companycheck == "JBI") {
    //   return this.conn.query(
    //     `
    //     Select Id, Name, Congress__r.Label__c, Brand_Master__r.Name, Focus_Master__r.Name,
    //      Topic_Master__r.Name, Engagement_Type__c, Engagement_Date__c, Start_Date_Time__c,
    //       End_Date_Time__c, Location__c, Status__c, FFS__c, (Select KOLAccount__r.Name,
    //          KOLAccount__r.Specialty__c, KOLAccount__r.Degree__pc, KOLAccount__r.PersonEmail,
    //           KOLAccount__r.Primary_Title_Affiliation__pc,
    //            KOLAccount__r.Primary_Title_City_State__pc,
    //             KOLAccount__r.Primary_Title_Position__pc from KOL_Engagements__r order by KOLAccount__r.Name asc),
    //              (Select Attendee_Name__c, Leadership_Attendee__c, Attendee_Role__c from KOL_Attendees__r order by Attendee_Name__c asc),
    //               Notes_Details__c from Master_Enagement__c where Congress__r.Label__c = 'ASH 2023' ${FFS__cValue} order by Start_Date_Time__c asc

    //     `
    //   );

    // } else if (companycheck == "PCYC") {
    //   return this.conn.query(`
    //   Select Id, Name, Congress__r.Label__c, Brand_Master__r.Name, Focus_Master__r.Name,
    //   Topic_Master__r.Name, Engagement_Type__c, Engagement_Date__c, Start_Date_Time__c,
    //    End_Date_Time__c, Location__c, Status__c, FFS__c, (Select KOLAccount__r.Name,
    //       KOLAccount__r.Specialty__c, KOLAccount__r.Degree__pc, KOLAccount__r.PersonEmail,
    //        KOLAccount__r.Primary_Title_Affiliation__pc,
    //         KOLAccount__r.Primary_Title_City_State__pc,
    //          KOLAccount__r.Primary_Title_Position__pc from KOL_Engagements__r order by KOLAccount__r.Name asc),
    //           (Select Attendee_Name__c, Leadership_Attendee__c, Attendee_Role__c from KOL_Attendees__r order by Attendee_Name__c asc),
    //            Notes_Details__c from Master_Enagement__c where Congress__r.Label__c = 'ASH 2023' ${FFS__cValue} order by Start_Date_Time__c asc
    //     and Engagement_Type__c = '${division}'
    //   `);
    // }
  }

  public async getKmeReportsPagination(
    instanceUrl: string,
    nextRecordsUrl: string,
    authToken: string
  ) {
    //Leadership_Attendee__c

    fetch(
      "https://janssensupport.360connex.com/services/data/v42.0/query/01g3Z0000EofJWEQQ2-238",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization:
            "Bearer 00Dj0000001ujh3!ARoAQG4tivynAQA.1STgy40D1_3QjERbAwsK9XxzzU0Uos0.eVODbwl3damFRxXPh42MP1LkXPJPFsDxA3h.S0nK7c5YVth5",
        },
        method: "GET",
      }
    );
  }

  public async getKmeReports() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKmeReports/${this.KOL_Profile_Portal__pc}/${'HCP'}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKmeReports:", error);
      throw error;
    }
    // return this.conn.query(`
    //   SELECT KOLAccount__r.Id,
    //     Master_Engagement__r.Name, Master_Engagement__r.Engagement_Date__c 
    //   FROM KOL_Engagement__c 
    //   WHERE KOL_Profile_Portal__c  = '${this.KOL_Profile_Portal__pc}' 
    //     AND Attendee_Type__c = 'HCP' AND Master_Engagement__c != null 
    // `);
  }

  public async getSpeakerReports() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getSpeakerReports/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getSpeakerReports:", error);
      throw error;
    }
  }

  public async getKOLReports(queryParams: string, FFS__cValue: string) {
    // return this.conn.query(`
    //   Select FirstName, LastName, Degree__pc, NPI_Number__pc, Primary_Title_City_State__pc, 
    //   PersonEmail, Phone, PersonMobilePhone, Twitter__c, Specialty__c, Primary_Title_Affiliation__pc, 
    //   Primary_Title_Position__pc, KOL_Classification__c, Write_In_KOL__pc, (Select Id, Master_Engagement__r.Engagement_Type__c 
    //   from KOL_Engagements__r ${FFS__cValue}), (Select Region__c, Tier__c, MSL_Name__c, MSL2_Name__c, TLL_Name__c, TLL2_Name__c, 
    //   Brand_Master__r.Name, Advocacy_Score_1__c, Advocacy_Score_2__c, Strategic_Imperatives_Commercial__c, 
    //   Strategic_Imperatives_Medical__c, Clinical_Trials_Experience__c, Speaking_Experience__c, 
    //   Clinical_Interests__c, Known_Engagement_Interests__c from KOL_Brands__r Where Brand_Master__r.Name = '${BRAND1}') 
    //   from Account Where RecordType.Name = 'Key Opinion Leader' and Id IN (Select Expert_Account__c from Expert__c 
    //   where Active__c = true and KOL_Profile_Portal__r.Name = '${this.KOL_Portal_Name}') and Id IN (Select KOL__c from KOL_Brand__c 
    //   Where Brand_Master__r.Name = '${BRAND1}') and Write_In_KOL__pc = false
    // `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLReports`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLReports:", error);
      throw error;
    } 
  }

  public async getKOLReports2(queryParams: string, FFS__cValue: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLReports2?KOL_Portal_Name=${this.KOL_Portal_Name}&brandsFilter=${BRAND1}&ffSc=${FFS__cValue}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLReports2:", error);
      throw error;
    }
  }
  // <=========================MM portfolio======================================>
  public async getTotalKOlEngagement(queryParams: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getTotalKOlEngagement?KOL_Portal_Name=${this.KOL_Portal_Name}&brand=${BRAND1}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getTotalKOlEngagement:", error);
      throw error;
    }
  }


  public async getTargetKOl(queryParams: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getTargetKOl?KOL_Portal_Name=${this.KOL_Portal_Name}&brand=${BRAND1}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching getTargetKOl:", error);
      throw error;
    }
  }

  public async getLeadershipEngagement() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getLeadershipEngagement?KOL_Portal_Name=${this.KOL_Portal_Name}&brand=${BRAND1}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchTwitterUsers:", error);
      throw error;
    }
  }


  public async getSummaryLevelNontargetKol(queryParams: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getSummaryLevelNontargetKol/${this.KOL_Portal_Name}/${BRAND1}`);
      return response.data;
    } catch (error) {
      console.error("Error getSummaryLevelNontargetKol:", error);
      throw error;
    }
  }
  // <==========================================leukemia reports api=============================>

  public async getKOLReportsDemo(FFS__cValue: any) {
    try {
      const params = new URLSearchParams();

      if (FFS__cValue !== undefined && FFS__cValue !== "") {
        params.append('ffs', FFS__cValue);
      }
      params.append('KOL_Portal_Name', this.KOL_Portal_Name);
      params.append('brandName', BRANDdemo);

      const response = await axios.get(`${this.KOL_Api_Url}/getKOLReportsDemo`, { params });
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLReportsDemo:", error);
      throw error;
    }
  }

  public async getKOLReports3(queryParams: string, FFS__cValue: string) {
    console.log('from getKOLReports3');

    try {
      const params = new URLSearchParams();

      if (FFS__cValue !== undefined && FFS__cValue !== "") {
        params.append('ffs', FFS__cValue);
      }

      params.append('BrandMasterName', BRAND3)
      params.append('KOL_Portal_Name', this.KOL_Portal_Name);
      const response = await axios.get(`${this.KOL_Api_Url}/getKOLReports3`, { params });
      return response.data;
    } catch (error) {
      console.error("Error fetching getKOLReportsDemo:", error);
      throw error;
    }

    //     return this.conn.query(`
    //   Select FirstName, LastName, Degree__pc, NPI_Number__pc, Primary_Title_City_State__pc, 
    //   PersonEmail, Phone, PersonMobilePhone, Twitter__c, Specialty__c, Primary_Title_Affiliation__pc, 
    //   Primary_Title_Position__pc, KOL_Classification__c, Write_In_KOL__pc, (Select Id, Master_Engagement__r.Engagement_Type__c 
    //   from KOL_Engagements__r ${FFS__cValue}), (Select Region__c, Tier__c, MSL_Name__c, MSL2_Name__c, TLL_Name__c, TLL2_Name__c, 
    //   Brand_Master__r.Name, Advocacy_Score_1__c, Advocacy_Score_2__c, Strategic_Imperatives_Commercial__c, 
    //   Strategic_Imperatives_Medical__c, Clinical_Trials_Experience__c, Speaking_Experience__c, 
    //   Clinical_Interests__c, Known_Engagement_Interests__c from KOL_Brands__r Where Brand_Master__r.Name = '${BRAND3}') 
    //   from Account Where RecordType.Name = 'Key Opinion Leader' and Id IN (Select Expert_Account__c from Expert__c 
    //   where Active__c = true and KOL_Profile_Portal__r.Name = '${this.KOL_Portal_Name}') and Id IN (Select KOL__c from KOL_Brand__c 
    //   Where Brand_Master__r.Name = '${BRAND3}') and Write_In_KOL__pc = false
    // `);
  }

  // public async getKmeWriteinReports(queryParams1:any, FFS__cValue: string) {
  //   return this.conn.query(`
  //   Select Id, Name,  Brand_Master__r.Name, Engagement_Type__c, Owner.Name, Congress__c, Congress__r.Label__c, 
  //   (Select Attendee_Name__c, Leadership_Attendee__c, Attendee_Role__c from KOL_Attendees__r),
  //   (Select KOLAccount__r.Name, KOLAccount__r.Primary_Title_Affiliation__pc, KOLAccount__r.Primary_Title_City_State__pc, KOLAccount__r.PersonMailingCity, KOLAccount__r.PersonMailingState, KOLAccount__r.Write_In_KOL__pc , KOLAccount__r.Degree__pc,
  //   KOL_Brand__r.TLL_Name__c, KOL_Brand__r.TLL2_Name__c, KOL_Brand__r.MSL_Name__c, KOL_Brand__r.MSL2_Name__c from KOL_Engagements__r),
  //   Focus_Master__r.Name, Topic_Master__r.Name, Format__c, Engagement_Date__c, Start_Date_Time__c,
  //   Status__c, Location_Type__c, Location__c, Notes_Details__c 
  //   from Master_Enagement__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' ${queryParams1 && 'and '+ '(' +queryParams1+ ')'} ${FFS__cValue}
  //   `);
  // }

  public async getKmeWriteinReports(queryParams1: any, FFS__cValue: string) {
    const body = {
      focusNames: queryParams1,
      KOL_Profile_Portal__Name: this.KOL_Portal_Name,
      pageSize: 100,
      pageNumber: 1
    }
    try {
      const response = await axios.put(`${this.KOL_Api_Url}/getKmeWriteinReports`,
        body
      );
      return response.data;
    } catch (error) {
      console.error("Error getKmeWriteinReports: ", error);
      throw error;
    }
    // return this.conn.query(`
    // Select FirstName, LastName, Degree__pc, Primary_Title_Affiliation__pc, PersonTitle, PersonMailingState, PersonMailingCity,
    //  (Select Brand_Master__r.Name from KOL_Brands__r), (Select Id,
    //    Master_Engagement__r.Engagement_Type__c from KOL_Engagements__r)
    //     from Account where KOL_Profile_Portal__pr.Name = 'Janssen KOL Portal'
    //      and Write_In_KOL__pc = true AND ID IN (Select KOL__c from KOL_Brand__c where ${queryParams1}) 
    // `);
  }

  /*
  public async fetchTwitterUsers() {
        return this.conn.query(`
      SELECT
        Expert_Account__r.Affiliation__c,
        Expert_Account__r.PersonTitle,
        Expert_Account__r.Degree__pc,
        Expert_Account__r.Specialty__c,
        Expert_Account__r.Name,
        Expert_Account__r.Profile_Thumbnail__c,
        Expert_Account__r.twitter__c 
        FROM Expert__c 
            WHERE
            KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
            AND Expert_Account__r.twitter__c != null
            ORDER BY Expert_Account__r.LastName ASC
        `);
  }
  */

  public async fetchTwitterUsers() {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchTwitterUsers`);
      return response.data;
    } catch (error) {
      console.error("Error fetchTwitterUsers:", error);
      throw error;
    }
  }

  public async fetchEventsForMostLeast(profileId?: string) {
    // return this.conn.query(`
    //   SELECT 
    //       Master_Engagement__r.Id,
    //       Master_Engagement__r.Engagement_Date__c,Master_Engagement__r.Name,
    //       Master_Engagement__r.Type__c 
    //       FROM KOL_Engagement__c 
    //         WHERE 
    //       KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' 
    //       ${profileId ? "AND KOLAccount__c ='" + profileId + "'" : ""}
    //     `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchEventsForMostLeast`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchEventsForMostLeast:", error);
      throw error;
    } 
  }

  public async fetchKolCheckboxIMBRUVICA(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKolCheckboxIMBRUVICA/${profileId}?brand=${BRAND1}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchKolCheckboxIMBRUVICA:", error);
      throw error;
    }
    /*
        return this.conn.query(`
      Select Id, KOL__c, Brand_Master__r.Name, Clinical_Interests__c, Clinical_Trials_Experience__c, 
      Known_Engagement_Interests__c, Speaking_Experience__c, Strategic_Imperatives_Commercial__c, Strategic_Imperatives_Medical__c, Advocacy_Scores__c, Advocacy_Label_1__c, Advocacy_Score_1__c, Advocacy_Label_2__c, Advocacy_Score_2__c 
      from KOL_Brand__c where KOL__c = '${profileId}' and Brand_Master__r.Name = '${BRAND1}'
        `);
    */
  }

  public async fetchKolCheckboxDARZALEX(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKolCheckboxDARZALEX/${profileId}?brand=${BRAND1}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchKolCheckboxDARZALEX:", error);
      throw error;
    }
  }

  public async fetchKolCheckboxERLEADA(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKolCheckboxERLEADA?profileId=${profileId}&brand=${BRAND3}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchInstitutionNotes", error);
      throw error;
    }
  }

  public async fetchKolCheckbox(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchKolCheckbox/${profileId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchKolCheckbox", error);
      throw error;
    }
    //   return this.conn.query(`
    // Select Name, Specialty__c, PersonMailingState, (Select Brand__c, Strategic_Imperatives_Commercial__c, Strategic_Imperatives_Medical__c, Advocacy_Scores__c, Advocacy_Label_1__c, Advocacy_Score_1__c, Advocacy_Label_2__c, Advocacy_Score_2__c,
    // Clinical_Interests__c, Clinical_Trials_Experience__c, Known_Engagement_Interests__c, 
    // Speaking_Experience__c from KOL_Brands__r) from Account Where Id = '${profileId}'

    //   `);
  }

  /*
  OBSOLETE
  public async fetchBrandName(profileId?: string) {
    return this.conn.query(`
     SELECT 
     Strategic_Imperatives_Commercial__c, Strategic_Imperatives_Medical__c, Advocacy_Scores__c from KOL_Brand__c 
     WHERE 
     KOL__c = '${profileId}'
        `);
  }
  */

  public async fetchProfileBrandName(
    profileId?: string,
    companycheck?: string
  ) {
    // if (companycheck == "PCYC") {
    //   return this.conn.query(`
    //     SELECT Brand_Master__r.Name FROM KOL_Brand__c WHERE KOL__c ='${profileId}' and Brand_Master__r.Name = '${BRAND1}'
    //   `);
    // } else {
    //   return this.conn.query(`
    //   SELECT Brand_Master__r.Name FROM KOL_Brand__c WHERE KOL__c ='${profileId}'
    //   `);
    // }

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchProfileBrandName/${companycheck}/${profileId}/${BRAND1}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchProfileBrandName", error);
      throw error;
    }
  }

  public async getProfileBrandData(profileId?: string, companycheck?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getProfileBrandData?profileId=${profileId}&brand=${BRAND1}&companycheck=${companycheck}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getProfileBrandData: ", error);
      throw error;
    }
    // if (companycheck == "PCYC") {
    //   return this.conn.query(`
    //   Select Id, KOL__c, Brand_Master__r.Name, MSL_Name__c, MSL_Email__c, 
    //   MSL_Phone__c, MSL2_Name__c, MSL2_Email__c, MSL2_Phone__c, TLL_Name__c, TLL_Email__c, TLL_Phone__c, TLL2_Name__c, TLL2_Email__c, TLL2_Phone__c, Strategic_Imperatives_Commercial__c, Strategic_Imperatives_Medical__c, Advocacy_Scores__c,
    //   Advocacy_Label_1__c, Advocacy_Score_1__c, 
    //   Advocacy_Label_2__c, Advocacy_Score_2__c, OverallScore__c,Impact_Score__c,
    //   Region__c, Tier__c from KOL_Brand__c where KOL__c = '${profileId}' and Brand_Master__r.Name = '${BRAND1}'
    // `);
    // } else {
    //   return this.conn.query(`
    //   Select Id, KOL__c, Brand_Master__r.Name, MSL_Name__c, MSL_Email__c, 
    //   MSL_Phone__c, MSL2_Name__c, MSL2_Email__c, MSL2_Phone__c, TLL_Name__c, TLL_Email__c, TLL_Phone__c, TLL2_Name__c, 
    //   TLL2_Email__c, TLL2_Phone__c, Strategic_Imperatives_Commercial__c, Strategic_Imperatives_Medical__c, Advocacy_Scores__c,
    //     Advocacy_Label_1__c, Advocacy_Score_1__c, 
    //     Advocacy_Label_2__c, Advocacy_Score_2__c, OverallScore__c,Impact_Score__c,
    //     Region__c, Tier__c from KOL_Brand__c where KOL__c =  '${profileId}' 
    // `);
    // }
  }
  // Select Admin_Contact__pc, Admin_Email__pc, Admin_Phone__pc from Account where Id = '${profileId}}'
  public async getContactDetail(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getContactDetail/${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error getContactDetail", error);
      throw error;
    }

    // return this.conn.query(`
    //   Select  Admin_Contact__pc, Admin_Email__pc, Admin_Phone__pc, PersonMobilePhone, PersonEmail from Account where Id = '${profileId}'
    // `);
  }

  public async fetchSpeakerActivity(profileId?: string, FFS__cValue?: string) {

    // return this.conn.query(`
    //   Select Id, Master_Engagement__r.Brand_Master__r.Name, Master_Engagement__r.Name, Engagement_Type__c, Master_Engagement__r.Start_Date_Time__c, Master_Engagement__r.Location__c from KOL_Engagement__c where KOLAccount__c = '${profileId}'
    //   ${FFS__cValue}
    //       `);

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchSpeakerActivity/${profileId}/${FFS__cValue}`);
      return response.data;
    } catch (error) {
      console.error("Error fetchSpeakerActivity", error);
      throw error;
    }
  }

  public async fetchInstitutionNotes(profileId?: string, Division__c: string = '') {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchInstitutionNotes/${profileId}?division=${Division__c}`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetchInstitutionNotes", error);
      throw error;
    }
    // if (Division__c == "Admin") {
    //   return this.conn.query(`
    //     Select  Id, Body__c, Care_Team_Note__c, Institution_Note__c, KOL__c, KOL_Profile_Portal_Name__c, Title__c, CreatedDate from Note__c 
    //     where KOL__c = '${profileId}'  and Institution_Note__c = true and Care_Team_Note__c = false
    //       `);
    // } else if (Division__c == "Commercial" || Division__c == "Medical") {
    //   return this.conn.query(`
    //     Select  Id, Body__c, Care_Team_Note__c, Institution_Note__c, KOL__c, KOL_Profile_Portal_Name__c, Title__c, CreatedDate from Note__c 
    //     where KOL__c = '${profileId}'  and Institution_Note__c = true and Care_Team_Note__c = false and Division__c = '${Division__c}'
    //       `);
    // }
  }

  public async fetchCareNotes(profileId?: string, Division__c: string = '') {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchCareNotes/${profileId}?division=${Division__c}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching fetchCareNotes:", error);
      throw error;
    }
    /*
    if (Division__c == "Admin") {
      return this.conn.query(`
      Select Id, Body__c, Care_Team_Note__c, Institution_Note__c, KOL__c, KOL_Profile_Portal_Name__c, Title__c, 
      CreatedDate from Note__c where KOL__c = '${profileId}' and Institution_Note__c = false and Care_Team_Note__c = true
        `);
    } else if (Division__c == "Commercial" || Division__c == "Medical") {
      return this.conn.query(`
      Select Id, Body__c, Care_Team_Note__c, Institution_Note__c, KOL__c, KOL_Profile_Portal_Name__c, Title__c, 
      CreatedDate from Note__c where KOL__c = '${profileId}' and Institution_Note__c = false and Care_Team_Note__c = true and Division__c = '${Division__c}'
        `);
    }
    */
  }

  public async fetchAll(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/fetchAll/${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching All:", error);
      throw error;
    }
    // return this.conn.query(`
    //   SELECT KOL__r.Name, Brand_Master__r.Name from KOL_Brand__c where KOL__c IN 
    //   (Select Expert_Account__c from Expert__c where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' )
    //     `); 
  }

  //TODO: This returns nothing, just as its salesforce counterpart. We don't know what's the shape of the returned data.
  public async getPermission(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getPermission?profileId=${profileId}&profilePortal=${this.KOL_Profile_Portal__pc}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching permissions:", error);
      throw error;
    }
  }

  public async getClassification(profileId?: string) {

    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getClassification/${this.KOL_Profile_Portal__pc}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getClassification", error);
      throw error;
    }
    //   return this.conn.query(`
    // SELECT Classification__c, count(Id) FROM KOL_Brand__c 
    // WHERE KOL__r.KOL_Profile_Portal__pc = '${this.KOL_Profile_Portal__pc}' 
    // GROUP BY Classification__c
    //   `);
  }

  // Select Active__c, (Select KOL_Brand_Master__r.Name, KOL_Brand_Master__r.Color__c
  // from KOL_Employee_Brands__r), Company__c,
  //  Division__c, Employee__c, Employee__r.Name,
  //  Employee__r.UserName, Employee__r.Email, Employee__r.CommunityNickName, KOL_Profile_Portal__c,
  //  Leadership_Attendee__c, Read_Only__c from Employee__c
  //  where KOL_Profile_Portal__c = '${this.KOL_Profile_Portal__pc}' and Employee__c = '${profileId}'

  public async getGlobal(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getGlobal/${this.KOL_Profile_Portal__pc}/employee/${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global:", error);
      throw error;
    }
  }

  public async getGlobalName(profileId?: string): Promise<string> {
    try {

      const response = await axios.get(`${this.KOL_Api_Url}/getGlobalName?profileId=${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global name:", error);
      throw error;
    }
  };

  public async getGlobalDivision(profileId?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getGlobalDivision/${profileId}`
      );
      return response.data;
    } catch (error) {
      console.error("Error getGlobalDivision", error);
      throw error;
    }
  }

  public async getGlobalFocus(profileId?: string): Promise<any> {
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getGlobalFocus/${profileId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  };

  public async getGlobalFocus2(profileId?: string) {
    let profileName = this.KOL_Portal_Name;
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getGlobalFocus2/${profileName}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async getMasterId(brandName?: string) {
    try {
      const response = await axios.get(`${this.KOL_Api_Base_Url}/brandmasters/${brandName}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching brand master:", error);
      throw error;
    }
  }

  public async getFocusId(
    focusId?: string,
    focusName?: string,
    focus?: string
  ) {
    // return this.conn.query(`
    //   Select Id, Name from KOL_Focus_Master__c where Name like '${focusName}' and Brand_Disease_Focus__c = '${focus}'
    //   and Brand_Master__c = '${focusId}'
    //    `);
    try {
      const response = await axios.get(`${this.KOL_Api_Url}/getFocusId/${focusName}/${focus}/${focusId}`);
      return response.data;
    } catch (error) {
      console.error("Error fetching global focus:", error);
      throw error;
    }
  }

  public async getTopicId(topicId?: string, topicName?: string) {
    const data = {
      topicId: topicId,
      topicName: topicName
    }
    try {
      const response = await axios.post(`${this.KOL_Api_Url}/getTopicId`,
        data
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching getTopicId:", error);
      throw error;
    }
    //   return this.conn.query(`
    //  Select Id, Name from KOL_Topic_Master__c where Name = '${topicName}' and Focus_Master__c = '${topicId}'
    //   `);
  }

  public async testingapi(profileId?: string) {
    // this.getMasterId("Leukemia-Lymphoma").then((data:any)=>{
    //   console.log("masterId>>>>>",data.records[0].Id);
    //   this.getFocusId("a8J19000000U4yrEAC").then((data1:any)=>{
    //     console.log("focusId>>>>>",data1.records[0].Id);
    //     this.getTopicId("a8L19000000A8Y4EAK").then((data2:any)=>{
    //       console.log("topicId>>>>>",data2.records[0].Id)
    //     })
    //   })
    // });
    // getFocusId("a8J19000000U4yrEAC").then(data=>{return data});
    // getTopicId("a8L19000000A8Y4EAK").then(data=>{return data});
    // console.log("masterId>>>>>",masterId);
    // console.log("focusId>>>>>",focusId);
    // console.log("topicId>>>>>",topicId);
  }

  public async getClassificationCounts() {
    try {
      const response = await axios.get(`${this.KOL_Api_Base_Url}/reports/classification-counts`);
      return response.data;
    } catch (error) {
      console.error("Error getClassificationCounts:", error);
      throw error;
    }
  }
}

export { SfdcApi };
