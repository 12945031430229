import React, { Suspense } from "react";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./shared/layouts/Dashboard";
import { ProvideSfdc } from "./lib/useSfdc";
import OAuth2Callback from "./pages/OAuth2Callback";
import { routes } from "./routes";
import axios from 'axios';
import { EnvironmentContextService } from "./services/environment-context.service";

const DynamicLayoutComponent = ({
  title,
  layout: Layout,
  layoutStyles = undefined,
  component: Component,
  ...props
}) => {
  return (
    <Suspense fallback={<Layout title={title} loading></Layout>}>
      <Layout title={title} layoutStyles={layoutStyles}>
        <Component {...props}></Component>
      </Layout>
    </Suspense>
  );
};

const App = () => {

  const environmentService: EnvironmentContextService = new EnvironmentContextService();

  axios.interceptors.request.use(function (config) {

    const token = environmentService.getAccessToken();

    if (token != undefined && token != null) {
      config.headers.Authorization = `Bearer ${token.token}`;
    }

    return config;
  });

  axios.interceptors.response.use((response) => response, (error) => {
    switch (error.response?.status) {
      case 401:

        if (!window.location.href.indexOf('Login')) {
          window.location.href = '/Login';
        }

        break;
      case 400:
        // Define how are we planning to show those erros.
        console.log('ERROR 200 -> ', error);
        break;
      case 500:
        // Define how are we planning to show those erros.
        console.log('ERROR 500 -> ', error);
        break;
    }

    return Promise.reject(error);
  })

  return (
    <ProvideSfdc>
      <BrowserRouter>
        <Switch>
          <Route exact path="/oauth2callback">
            <DynamicLayoutComponent
              component={OAuth2Callback}
              layout={Dashboard}
              title={`Logging in...`}
            />
          </Route>
          <Route>
            <HashRouter>
              <Switch>
                {routes.map(
                  (
                    {
                      path,
                      exact,
                      component: Component,
                      title,
                      layout: Layout,
                      layoutStyles,
                    },
                    key
                  ) => (
                    <Route
                      key={0}
                      path={path}
                      exact={exact}
                      render={(props: any) => (
                        <DynamicLayoutComponent
                          component={Component}
                          layout={Layout}
                          layoutStyles={layoutStyles}
                          title={title}
                          {...props}
                        />
                      )}
                    />
                  )
                )}
              </Switch>
            </HashRouter>
          </Route>
        </Switch>
      </BrowserRouter>
    </ProvideSfdc>
  );
};

export default App;
