import { red } from "@material-ui/core/colors";
import { createTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(28, 90, 125,1)", // Theme Color Blue
      light: "rgb(28, 90, 125,0.6)",
      dark: "rgb(28, 90, 125,0.9)"
    },
    secondary: {
      main: "rgb(28, 90, 125,1)",
      dark: "rgb(28, 90, 125,1)" // darkorange
    },
    text: {
      primary: '#737373',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "rgb(240, 240, 240) !important"
    },

  },
  typography: {
    fontFamily: 'Bilo'
  },
  overrides: {
    MuiTab: {
      wrapper: {
        flexDirection: 'row-reverse',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '*::-webkit-scrollbar': {
          width: '0.5em',
          height: '0.5em'
        },
        '*::-webkit-scrollbar-track': {
          '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: 'rgb(28, 90, 125,0.2)',
          borderRadius: '1em'
          // outline: '1px solid slategrey'
        },
        '*::-webkit-scrollbar-thumb:hover': {
          backgroundColor: 'rgb(28, 90, 125,1)'
        },
        body: {
          fontSize: '0.875rem !important'
        }
      }
    }
  },

});

export default theme;
