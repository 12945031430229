import React, { useState } from "react";
import { useSfdc } from "../../../lib/useSfdc";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  TextField, Grid, Box
} from "@material-ui/core";
import { LoadingIcon } from '../../../shared/components/LoadingIcon';
import { CustomDialog } from "../../../shared/components/CustomDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    sideItems: {
      borderBottom: '1px lightgray solid'
    },
    nested: {
      paddingLeft: theme.spacing(4),
      color: 'rgb(41,131,93)',
      backgroundColor: 'rgb(243,249,248)'
    },
    itemText: {
      fontSize: 14,
      fontWeight: 'bolder',
    },
    subItemText: {
      fontSize: 14
    },
    supportHeader: {
      color: theme.palette.primary.main,
      fontSize: '24px',
      fontWeight: 'lighter',
      paddingTop: '2px',
      paddingBottom: '8px'
    },
    supportForm: {
      minHeight: '220px'
    }
  })
);

function SupportModal(props,supportManagers) {

  const classes = useStyles();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [supportPerson, setSupportPerson] = useState<any>([]);
  const [emailValue, setEmailValue] = useState<string>("");
  const [textAreaValue, setTextAreaValue] = useState<string>("");
  const [supportResponse, setSupportResponse] = useState<string>("");
  const [loadingStatus, setLoadingStatus] = useState(0);

  function openModal(x) {
    setIsOpen(true);
    setSupportPerson(x);
  }

  const { api, user } = useSfdc();

  function closeModal() {
    setIsOpen(false);
    setSupportResponse('')
  }

  function postSupportIssue(e) {
    e.preventDefault();
    setLoadingStatus(1);
    api?.postSupportIssue(textAreaValue, user?.records[0]).then((d1) => {
      setSupportResponse(d1)
      setLoadingStatus(0);
    }).catch((e) =>
      console.log('ERROR', e)
      // payloadStatus('Error')
    );
  }

  function returnBodyComp() {
    if (supportResponse === '') {
      return (
        <form className={classes.supportForm} id="support_pop">
          { loadingStatus === 0 ?
            <>
              <div className='support-header'>
                <span>Please enter a brief description of the issue you are having. We will review your request and respond as soon as possible.</span>
              </div>
              {/* <LoadingIcon/> */}
              <Grid item xs={12}>
                <Box my={1} px={2}>
                  <TextField label="Description"
                    rows={6}
                    multiline
                    fullWidth variant="outlined"
                    onChange={(
                      ev: React.ChangeEvent<HTMLTextAreaElement>,
                    ): void => setTextAreaValue(ev.target.value)}
                  />
                </Box>
              </Grid>
              <div className='support-lower'>
                {textAreaValue ?
                  <button className='modal-btn' onClick={postSupportIssue}>Submit</button>
                  :
                  <button className='modal-btn-disabled'>Submit</button>
                }
              </div>
            </>
            : <LoadingIcon />}
        </form>
      )
    } else if (supportResponse === 'Success') {
      return (
        <div className='support_success'>
          <div>Thank you for your message!</div>
          <div>We will get back to you as soon as possible.</div>
        </div>
      )
    } else if (supportResponse === 'Error') {
      return (
        <form id="support_pop">
          <div className='support-header'>
            <span>Enter a brief description of your issue.</span>
            <span>We'll review your request and respond as soon as possible.</span>
          </div>

          <div className='support-email'>
            <label>Email address</label>
            <input type="text" name="email-support" placeholder='email address'
              onChange={(
                ev: React.ChangeEvent<HTMLInputElement>,
              ): void => setEmailValue(ev.target.value)}
            />
          </div>
          <div className='support-email'>
            <label className="">Description</label>
            <textarea placeholder='Message' name="Text1" cols={40} rows={7}
              onChange={(
                ev: React.ChangeEvent<HTMLTextAreaElement>,
              ): void => setTextAreaValue(ev.target.value)}
            />
          </div>

          <div className='support-lower'>
            {emailValue && textAreaValue ?
              <button className='modal-btn' onClick={postSupportIssue}>Submit</button>
              :
              <button className='modal-btn-disabled'>Submit</button>
            }
          </div>

          <div className='support_error'>
            <div>Error please check your info and try again.</div>
          </div>
        </form>
      )
    }
  }
  return (
    <div onClick={props.onNavBar}>
      <ListItem
        className={classes.sideItems}
        button
        onClick={(x) => openModal(x)}
      >
        <ListItemText
          classes={{
            primary: classes.itemText,
          }}
          className="f-color-g"
          primary="Support"
        />
      </ListItem>

      <CustomDialog
        dialogState={modalIsOpen}
        handleClose={closeModal}
        title={'Support'}
        maxWidth={'sm'}
      >
        {returnBodyComp()}
      </CustomDialog>
    </div>
  );
}

export default SupportModal;