import React from "react";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import moment from 'moment';

const Footer = () => {
    return (
        <Box py={2} mb={2} id="footer_outer">
            <Typography variant="body2" color="textSecondary" align="center">
                {`© ${moment().year()} Blulava | A Division of Evolution Health Group | CONNEX v2.1.2`}
            </Typography>
            <Typography variant="body2" color="textSecondary" align="center">
                {`MATERIAL IS BEING PROVIDED FOR INTERNAL EDUCATIONAL AND BACKGROUND PURPOSES ONLY AND IS NOT
                    FOR PROMOTIONAL USE. DO NOT COPY, DISTRIBUTE OR SHARE WITH PHYSICIANS, STAFF OR PATIENTS.`}
            </Typography>
        </Box>
    );
}

export default Footer;