import axios, { AxiosStatic } from "axios";
import { EnvironmentContextService } from "../environment-context.service";

export class BaseService {
    protected readonly contextService: EnvironmentContextService;
    protected axios: AxiosStatic;
    protected baseUrl: string;
    protected baseApiUrl: string;

    constructor(contextServie: EnvironmentContextService) {
        this.contextService = contextServie;
        this.axios = axios;
        this.baseUrl = process.env.REACT_APP_KOL_API_URL ?? '';
        this.baseApiUrl = this.baseUrl;
    }

    protected async handleResponse(callback: () => Promise<any>): Promise<any> {
        try {
            const response = await callback();
            return response.data;
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    }
}