import React, { useRef, useEffect } from "react";
import { makeStyles, Container } from "@material-ui/core";
import { useSfdc } from "../../lib/useSfdc";
import "../../styles/bootstrap.min.css";
import "../../styles/home.css";
import { useLocation } from "react-router-dom";
import Header from "./Header";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: (props: any) => {
    return {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(4.5),
      ...(props?.container ?? {}),
    };
  },
  paper: {
    textAlign: "center",
    padding: theme.spacing(5),
  },
}));

function WrapComponent({ title, children, loading, layoutStyles }): JSX.Element {
  const { api, user } = useSfdc();
  const mainRef = useRef<HTMLElement>(null);
  const classes = useStyles(layoutStyles);
  const { pathname } = useLocation();


  useEffect(() => {
    if (mainRef?.current) {
      mainRef.current.scrollTop = 0;
    }
  }, [api, user, pathname, title]);

  return (
    <div className={classes.root}>
      <Header layoutStyles={layoutStyles} />
      <Container maxWidth="lg" className={classes.container} id="custom_page">
        <main ref={mainRef} >
          {children}
        </main>
      </Container>
    </div>
  );
}

export default WrapComponent;
