import React, { useRef, useEffect, useState } from "react";
import {
  Box, Paper, makeStyles, Container, LinearProgress,
} from "@material-ui/core";
import { useSfdc } from "../../lib/useSfdc";
import "../../styles/bootstrap.min.css";
import "../../styles/home.css";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { CustomAlert } from "../components/CustomAlert";
import EngagementWidget from "./engagementWidget";
import LoginComponent from "../../pages/Login";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: (props: any) => {
    return {
      paddingTop: theme.spacing(12),
      paddingBottom: theme.spacing(4.5),
      ...(props?.container ?? {}),
    };
  },
  paper: {
    textAlign: "center",
    padding: theme.spacing(5),
  },
}));

function Dashboard({ title, children, loading, layoutStyles }): JSX.Element {
  const { api, user } = useSfdc();
  const mainRef = useRef<HTMLElement>(null);
  const classes = useStyles(layoutStyles);
  const [message, setMessage] = useState<string>('');
  const { pathname } = useLocation();


  useEffect(() => {
    if (mainRef?.current) {
      mainRef.current.scrollTop = 0;
    }

    setTimeout(() => {
      setMessage("You are logged out!!!");
    }, 700);
  }, [api, user, pathname, title]);

  return (
    <div className={classes.root}>
      <Header layoutStyles={layoutStyles} />
      <Container maxWidth="lg" className={classes.container} id="custom_page">
        <main ref={mainRef} >
          {user ? (
            <Box>
              {loading && <LinearProgress />}
              {children}
            </Box>
          ) : (
            // <Paper className={classes.paper}>
            //   {loading && <LinearProgress />}
            //   {!loading && message && <CustomAlert
            //     severity={"info"}
            //     message={message}
            //   />}
            // </Paper>
            <LoginComponent />
          )}
        </main>
      </Container>

      {user ? <Footer /> : ""}
    </div>
  );
}

export default Dashboard;
