import React from "react";
import { useSfdc } from "../../lib/useSfdc";
import {
  makeStyles,
  Drawer,
  List,
  Divider,
  IconButton,
  Avatar,
} from "@material-ui/core";
import LogoBar from "./LogoBar";
import NavigationMenu from "./NavigationMenu";
import { AccountCircle } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close';
import { useRef } from "react";
import useOutsideClick from "../../lib/useOutsideClick";

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    margin: "0px",
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    background: theme.palette.primary.main,
    ...theme.mixins.toolbar,
    height: 75,
  },
  drawerPaper: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: "0px",
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  container: (props: any) => {
    return {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      ...(props?.container ?? {}),
    };
  },
  image: {
    width: "80px",
    height: "80px",
    margin: "1rem auto",
  },
  paper: {
    textAlign: "center",
    padding: theme.spacing(5),
  },
  drawerHomeAccountBox: {
    padding: theme.spacing(2),
    textAlign: "center",
    backgroundColor: "#F4F5F7",
  },
  name: {
    color: theme.palette.primary.main,
  },
  closeIcon: {
    fill: '#FFF'
},
}));

const Header = ({ layoutStyles }) => {
  const { user } = useSfdc();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles(layoutStyles);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const ref = useRef();

  useOutsideClick(ref, () => {
    handleDrawerClose()
  });

  return (
    <React.Fragment>
      <LogoBar
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        title={"Gattex"}
      ></LogoBar>
      <Drawer
        ref={ref}
        variant="persistent"
        classes={{
          paper: classes.drawerPaper,
        }}
        open={open}
     id="side_bar_outer_sec">
        <div className={classes.toolbarIcon} id="side_bar_account">
          <IconButton onClick={handleDrawerClose}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <div className={classes.drawerHomeAccountBox} id="side_bar_account_detail">
          <Avatar id="profile_img"
            alt={user?.FirstName}
            src={user?.Profile_Thumbnail__c}
            className={classes.image}
          >
            <AccountCircle className={classes.image} />
          </Avatar>
          <div className={classes.name} id="name_title">
            {user ? user.FirstName + " " + user.LastName : "-"}
          </div>
          <div id="email_id">{user ? user.Email : "-"}</div>
        </div>
        <Divider />
        <List  id="nav_sec">
          <NavigationMenu handleNavigationMenu={handleDrawerClose} />
        </List>
        <Divider />
        {/* <List>{secondaryListItems}</List> */}
      </Drawer>
    </React.Fragment>
  );
};

export default Header;
