import Component from './App';
import { compose } from "redux";
import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { SetAge, IAction } from './actions';
import { IAppState } from './store';

const mapStateToProps = (state: IAppState) => {
    return {
        age: state.app.age,
        name: state.app.name,
        gattexAttendees: state.app.gattexAttendees,
        multipleAttendees: state.app.multipleAttendees,
        updatedKolStatus: state.app.updatedKolStatus,
        tags: state.app.tags,
        kolDropDowns: state.app.kolDropDowns,
        gattexDropDowns: state.app.gattexDropDowns,
        loadingStatus: state.app.loadingStatus
    }
}

const mapDispatchToProps = (dispatch: Dispatch<IAction>) => {
    return {
        onClick: () => dispatch(SetAge(Math.floor(Math.random() * 100)))
    }
}

export const App = compose(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )
)(Component);