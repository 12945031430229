import { useDispatch, useSelector, connect } from "react-redux";

import React, { Component, useEffect } from "react";
import { default as ReactSelect } from "react-select";

import { components } from "react-select";
import { IAppState } from "../../store";
import { SetBrandFilters,SetBrandFocus,SetBrandFocusAll,SetremovedItems } from "../../actions";
import { TransferWithinAStationOutlined } from "@material-ui/icons";
import { forEach } from "lodash";

// const colourOptions = [
//     { value: "Brand1", label: "Brand1" },
//     { value: "Brand1/Focus1", label: "Brand1/Focus1" },
//     { value: "Brand1/Focus2", label: "Brand1/Focus2" },
//     { value: "Brand2", label: "Brand2" },
//     { value: "Brand2/Focus1", label: "Brand2/Focus1" },
//     { value: "Brand2/Focus2", label: "Brand2/Focus2" },
//     { value: "Brand3", label: "Brand3" },
//     { value: "Brand3/Focus1", label: "Brand3/Focus1" },
//     { value: "Brand3/Focus2", label: "Brand3/Focus2" },
//     { value: "Brand3/Focus3", label: "Brand3/Focus3" }
//   ];

const MultiselectCheckbox = (props) => {
  const [checkedBrand, setCheckedBrand] = React.useState<any>([]);
  const [optionSelected, setOptionSelected] = React.useState<any>([]);
  const dispatch = useDispatch();
  const {headerValue,brandFiltersNew,brandFocus,brandFocusAll,removedItems,allBrandsFocus}= useSelector((state: any) => state.app);
  const [colourOptions,setColourOptions] = React.useState<any>([]);

  console.log("brandFocus",brandFocus)
  //console.log("removedItems",props.removedItems);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      //borderBottom: '1px dotted pink',
     marginLeft: state.data.color ? '20px' : '0px',
	   background: state.isSelected? '#fff' : '#fff',
     color:'#000'
      
    }),
   
 
  }
  
  useEffect(()=>{
    //if(removedItems?.length!=0){
      // let removedItemsArray:any=[];
      // console.log("props.removedItems",removedItems)
      // removedItems?.forEach(x=>removedItemsArray.push({value:x,label:x}));
      //console.log("consolelogfromremoved")
      setOptionSelected(removedItems);
  
  
    //}
  },[removedItems])
  

  useEffect(()=>{
   // console.log("headerValuefromCheckbox",brandFiltersNew)
    let colourOptions:any=[];
    brandFiltersNew?.records?.forEach((x)=>{
      if(headerValue?.records?.includes(x)){colourOptions.push({value:x,label:x})}
    else{colourOptions.push({value:x.split("*")[0],label:x.split("*")[0],name:x.split("*")[1],color:'red'})}});
    //console.log("colourOptions",colourOptions)
    setColourOptions(colourOptions);
    //console.log("headerValuefromprev",headerValue)
//     setOptionSelected((prev) => {
//       let prevData:any = [...prev];
//       //console.log("optionsselectedtrue",prev)
      
      
// //===========>>>>>>>>auto checked checkbox<<<<<<<<<<<=============
//   //  if(prevData.length==0){ prevData=colourOptions};
//   if(prevData.length==0){ 
//     //console.log("headerValue>>>>>",headerValue)
//     headerValue?.records?.map((item) =>
//            prevData?.push({ value: item, label: item })
//          )};
//       //console.log("prevData",prevData)\
//       // prevData.push({value: 'LIBTAYO-Skin', label:'LIBTAYO-Skin', name: 'skin', color: 'red'});
//       return prevData;
//     });
  
  }
    
,[brandFiltersNew?.records?.length,headerValue?.records?.length,]);

useEffect(() => {

  if (brandFiltersNew?.records?.length === 2){    
    setOptionSelected([
      {value: 'SKIN', label: 'SKIN'}, 
      {value: 'LIBTAYO-Skin', label: 'LIBTAYO-Skin', name: 'SKIN', color: 'red'}])
  }
  
},[brandFiltersNew?.records?.length])
  // useEffect(() => {
  //   console.log("useEffectcalledfrommultiselect",headerValue)

  //   setOptionSelected((prev) => {
  //     const prevData:any = [...prev];
  //     console.log("prevData>>>>>",prevData);
  //     console.log("removedItems",removedItems);
  //     console.log("headerValue",headerValue);

  //     console.log("brandFiltersNew>>>>>",brandFiltersNew)
  //  //if(prevData.length==0){  headerValue?.records?.map((item) =>
  //  if(prevData.length==0){  allBrandsFocus?.records?.map((item) =>
  //       prevData?.push({ value: item, label: item })
  //     )};
  //     console.log("prevData",prevData)
  //     return prevData;
  //   });
  // }, [headerValue,headerValue.length,removedItems]);

  const handleChange = (e: any) => {
    let e2=e;
    var removeByAttr = function(arr, attr, value){
      var i = arr.length;
      while(i--){
         if( arr[i] 
             && arr[i].hasOwnProperty(attr) 
             && (arguments.length > 2 && arr[i][attr] === value ) ){ 
  
             arr.splice(i,1);
  
         }
      }
      return arr;
  }

    //console.log("multiselectvalue", e2);
    headerValue.records.forEach(y=>{
      if(!(e2.find(z=>z.value==y))){
        //console.log("true>>>")
        removeByAttr(e2, 'name', y)
        
      }
      
    })

    //console.log("true>>>",e2)
    dispatch(SetremovedItems(e2));

   
  };
  //console.log("optionSelected",optionSelected)

  useEffect(() => {
    //@ts-ignore
    let uniqueFocus:any= [];
    let unique:any = [];
    let uniqueFocusAll:any=[];
    optionSelected?.forEach((x)=>{
       if(headerValue?.records?.includes(x.value)){unique.push(x.value)&& uniqueFocusAll.push(x)}
       else{uniqueFocus.push(x) && uniqueFocusAll.push(x)}
      });
    //console.log("optionsSelected>>>>",optionSelected)



    
      //console.log("unique***",unique)
      dispatch(SetBrandFilters(unique));
    

    dispatch(SetBrandFocus(uniqueFocus));
    dispatch(SetBrandFocusAll(uniqueFocusAll));


  }, [optionSelected]);

  const Option = (props) => {
    //console.log("multiselectprops", props);

 
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  return (
    <span
      className="d-inline-block header_select"
      data-toggle="popover"
      data-trigger="focus"
      data-content="Please selecet account(s)"
    >
      <ReactSelect
        options={colourOptions}
        isMulti
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        onChange={(e) => handleChange(e)}
        //allowSelectAll={true}
        //allowDiselectAll={false}
        isSearchable={true}
        value={optionSelected}
        styles={customStyles}
        isClearable={false}
        controlShouldRenderValue={false}
        backspaceRemovesValue={false}
      />
    </span>
  );
};


export default MultiselectCheckbox;
