import React from 'react';
import {
    makeStyles, IconButton, Button,
    Dialog, DialogActions, DialogTitle, DialogContent
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: '#FFF',
    },
    closeIcon: {
        fill: '#FFF'
    },
    dialogTitle: {
        textAlign: 'center',
        color: '#FFF',
        backgroundColor: `${theme.palette.primary.dark}`,
        fontWeight: 'bold'
    }
}));

export interface ICustomDialogProps {
    dialogState: boolean;
    title: string;
    titleStyle?: any;
    children: any;
    actionSave?: string;
    actionCancel?: string;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const CustomDialog = (props) => {
    const { dialogState, title, titleStyle, children, actionSave, actionCancel, maxWidth }: ICustomDialogProps = props;
    const classes = useStyles();

    const handleClose = () => {
        props.handleClose((prevOpen) => !prevOpen);
    };

    return (
        <Dialog fullWidth className={props.cvPopUP? 'custom_popup_outer cv_popup': 'custom_popup_outer'}
            maxWidth={maxWidth}
            aria-labelledby="customized-dialog-title"
            open={dialogState}>
            <DialogTitle style={titleStyle} className={classes.dialogTitle}>
                {title}
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                {children}
            </DialogContent>
            {(actionCancel || actionSave) &&
                <DialogActions>
                    {actionCancel && <Button autoFocus onClick={handleClose} color="primary">
                        {actionCancel}
                    </Button>
                    }
                    {actionSave && <Button onClick={() => { handleClose(); props.handleSave(); }} color="primary">
                        {actionSave}
                    </Button>
                    }
                </DialogActions>}
        </Dialog>
    )
}