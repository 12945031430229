import { AuthToken } from "../models/auth-token";
import { ProfileMessage } from "../models/messages/accounts/profile.message";

export class EnvironmentContextService {
  private profileKey: string = 'profile';
  private tokenKey: string = 'authToken';
  private userId: string = 'userId';
  constructor() {
  }

  getAccessToken(): AuthToken | null {
    let val = sessionStorage.getItem(this.tokenKey);

    if (val == null || val == undefined || val === "undefined") {
      return null;
    }

    let authToken: AuthToken = JSON.parse(val);
    return authToken;
  }

  setAccessToken(token: AuthToken) {
    sessionStorage.setItem(this.tokenKey, JSON.stringify(token));
  }

  removeAccesToken() {
    sessionStorage.removeItem(this.tokenKey);
    sessionStorage.removeItem(this.profileKey);
  }

  getProfile(): ProfileMessage {
    let profileData = sessionStorage.getItem(this.profileKey);

    if (profileData == null) {
      return null as any;
    }

    const profile: ProfileMessage = JSON.parse(profileData);

    return profile;
  }

  setProfile(profile: ProfileMessage) {
    sessionStorage.setItem(this.profileKey, JSON.stringify(profile));
  }
  setUserId(userId: string) {
    sessionStorage.setItem(this.userId, JSON.stringify(userId))
  }
  getUserId() {
    let val = sessionStorage.getItem(this.userId);

    if (val == null || val == undefined || val === "undefined") {
      return null;
    }

    let userId: string = JSON.parse(val);
    return userId;
  }
  isLogged(): boolean {
    return sessionStorage.getItem(this.profileKey) != null;
  }
}